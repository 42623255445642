<!-- 我的信息右下角我的轨迹界面 -->
<template>
    <div class="firstMenu" >
        <ul class="menu-one"  >
            <li  v-for="(p,index) in MyTrajectoryFirst" :key="p.id"  class="menu move">
                <span class="tit"  :class="[index==flag ?'titcolor':'']"  @click="ToUnfold(index)" >
                    <div class="yellowone" v-if="index==flag"></div>
                    {{p.title}}
                </span>
                <ul  class="menu-two" :class="[index==flag ?'fufuindex':'']" >
                    <li class="move" @click.stop="ToUnfold2(i)" :class="[i==flag2 ? 'bgblue': '']"  v-for="(item,i) in  p.children" :key="item.id"  style="display: flex;justify-content: space-between;padding: 0 36px 0 112px;">
                        {{item.title}}
                        <img class="topmid" v-if="i==flag2"  @click.stop="aa(item.id,item.parent)" src="../../assets/tiao2.png" alt="">
                        <!-- <span @click.stop="aa(item.id,item.parent)" class="iconfont icon-anniu-jiantouxiangyou_o">aa</span> -->
                        <img class="topmid" v-if="i!=flag2"  @click.stop="aa(item.id,item.parent)" src="../../assets/tiao.png" alt="">
                    </li>
                </ul>
            </li>
            
        </ul>
    </div>

</template>

<script>
import { useConfirmDialog } from '@vueuse/core'
import axios from '@/Api/axios'
export default {
        data()
        {
            return{
                MyTrajectoryFirst:[],

                flag:-1,
                flag2:0,
            
            }
        },
        methods:
        {
            ToUnfold2(e){
                if(e==this.flag2){
                    this.flag2=-1
                } else {
                    this.flag2=e;
                }
            },

            ToUnfold(e){
                if(e==this.flag){
                    this.flag=-1
                } else {
                    this.flag=e;
                }
            },
            // disfufu(){
            //     this.flag=-1;
            // }
            aa(p_id,parent){
     
  
                 if (parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:p_id,page:6}})

                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:p_id,page:6,}})
                }
            }
        },
        mounted(){
            axios.get("project/getproject/"+this.$cookies.get('selectValue').id).then(res=>{
         
                this.MyTrajectoryFirst=res.data.data;

               
            })
           
        }
}
</script>

<style scoped>
    .menu-two li:hover {
        background-color: rgb(236, 245, 255);
        transition: .3s;
    }
    .tit:hover {
        background-color: rgb(236, 245, 255);
        transition: .3s;
    }
.yellowone {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        border-radius: 12px;
        width: 6px;
        background-color: #fed71a;
}
.bgblue {
    background-color: #002580!important;
    color: #fff;
    transition: .3s;
}
.firstMenu {
    border-radius: 9px;
    background-color: #fff;
    overflow: hidden;
}
.firstMenu .menu-one .menu {
    position: relative;
    list-style: none;
    width: 100%;
    text-align:left; 
    color: #808080;
    /* background-color: #00b2a2; */
    
}

.fufuindex {
    width: 100%;
    display: block !important;
    background-color: #f7f7f7;
}
.menu {}
.menu-two
{
   position: relative;
   left: 0px;
   list-style: none;
   text-align: left;
   background: #f7f7f7;
   width: 100%;
   display: none;
   /* border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px; */
   
}
.menu-two img {
    width: 24px;
    height: 24px;
}
.menu-one li
{
}
.menu-two li
{
    height:64px;
    line-height:64px;

}
.tit {
    position: relative;
    /* margin-left: 40px; */
    padding-left: 40px;
    display: block;
    height: 64px;
    line-height: 64px;
}
.titcolor {
    font-weight: 700;
    color: #002580!important;
}
</style>
