import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import Forgotpassword from "../views/Forgotpassword.vue"
import Registered from "../views/Registered.vue"
import HomePage from "../views/Homepage.vue"
import Homepagefind from "../views/Threemenus/Homepagefind.vue"
import Firstpage from "../views/Threemenus/Homepagefirst.vue"
import Mypage from "../views/Threemenus/Homepagemy.vue"
import Laboratorypage from "../views/Threemenus/Homepagelaboratory.vue"
import Personalinformation from "../views/Personalinformation.vue"
import SubpersonalInformation from "../views/Personalinformationthreeinterface/Sub-personalInformation.vue"
import Submytrajectory from "../views/Personalinformationthreeinterface/Submytrajectory.vue"
import Subusagerecord from "../views/Personalinformationthreeinterface/Subusagerecord.vue"
import Activities from "../views/ActivitiesResources/Activities.vue"
import Resources from "../views/ActivitiesResources/Resources.vue"
import ActivitiesResourcesMain from "../views/ActivitiesResources/ActivitiesResourcesMain.vue"
import EventDetails from "../views/DetailsPage/EventDetails.vue"
import ResourcesForDetails from "../views/DetailsPage/ResourcesForDetails.vue"
import NewsPage from "../views/Threemenus/NewsPage.vue"
import Readmessage from "../views/Message/Readmessage.vue"
import Unreadmessages from "../views/Message/Unreadmessages.vue"
import ApplicationIsToUse from "../views/Threemenus/ApplicationIsToUse.vue"
import MyHomeScreen from "../views/Threemenus/MyHomeScreen.vue"
import MyChildInterface from "../views/Threemenus/MyChildInterface.vue"
import EventDetailsPreview from "../views/preview/EventDetailsPreview.vue"
import ResourceDetailsPreview from "../views/preview/ResourceDetailsPreview.vue"
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { isAuto: true }
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: Forgotpassword,
    meta: { isAuto: true }
  },
  {
    path: "/registered",
    name: "registered",
    component: Registered,

  },

  // 主页导航栏路由
  {
    path: "/homepage",
    name: "homepage",
    component: HomePage,
    //  meta:{isAuto:false},
    children: [
      {
        // 个人信息界面左下角三个按钮路由
        path: "personalinformation",
        component: Personalinformation,
        name: "personalinformation",
        keepAlive: false,
        children: [
          // 我的信息
          { path: "subpersonalInformati", component: SubpersonalInformation, name: "subpersonalInformati", meta: { isAuto: false, title: "个人信息" } },
          // 我的轨迹
          { path: "submytrajectory", component: Submytrajectory, name: "submytrajectory", meta: { isAuto: false, title: "我的轨迹" } },
          // 使用记录
          { path: "subusagerecord", component: Subusagerecord, name: "subusagerecord", meta: { isAuto: false, title: "使用记录" } }
        ]
      },
      {
        path: "eventdetails",
        component: EventDetails,
        name: "EventDetails",
        meta: {
          isAuto: true,
          keepAlive: false
        }
      },
      {
        path: "newspage",
        component: NewsPage,
        name: "NewsPage",

        children: [
          { path: "readmessage", component: Readmessage, name: "readmessage", meta: { isAuto: false } },
          { path: "unreadmessages", component: Unreadmessages, name: "unreadmessages", meta: { isAuto: false } }
        ]
      },
      {
        path: "resourcesfordetails",
        component: ResourcesForDetails,
        name: "resourcesfordetails",
        meta: { isAuto: true }
      },
      // 预览路由
      {
        path: "eventdetailspreview",
        component: EventDetailsPreview,
        name: "eventdetailspreview",
        meta: { isAuto: false }
      },
      {
        path: "resourcedetailspreview",
        component: ResourceDetailsPreview,
        name: "resourcedetailspreview",
        meta: { isAuto: false }
      },
      // {path:"homepagefirst",component:Firstpage,name:"firstpage",meta:{isAuto:true}},
      { path: "/", component: Firstpage, name: "firstpage", meta: { isAuto: true } },
      { path: "findpage", component: Homepagefind, name: "findpage", meta: { isAuto: false } },
      {
        path: "mypage",
        component: Mypage,
        meta: { isAuto: true },
        name: "mypage",
        children: [
          // {path:"mychildInterface",component:MyChildInterface,name:"mychildInterface",meta:{isAuto:false}},
          { path: "myhomescreen", component: MyHomeScreen, name: "myhomescreen", meta: { isAuto: false } }
        ]
      },
      { path: "laboratorypage", component: Laboratorypage, name: "laboratorypage", meta: { isAuto: false } },
      { path: "applicationIsToUse", component: ApplicationIsToUse, name: "applicationIsToUse", meta: { isAuto: false } },
      // 活动添加和资源添加页面的路由
      {
        path: "activitiesresourcesmain",
        component: ActivitiesResourcesMain,
        name: "activitiesresourcesmain",
        children: [
          { path: "activitiess", component: Activities, name: "activitiess", meta: { isAuto: false, } },
          { path: "resourcess", component: Resources, name: "resourcess", meta: { isAuto: false, } },
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.isAuto) {
    // 不需要token验证的页面直接放行
    next()
  }
  else {
    // 需要token登录的页面进行token校验
    if (!localStorage.getItem("isToken") == "" || !localStorage.getItem == null) {
      next();
    }
    else {
      next({ name: "login" })
    }
  }

  // next()  



})
export default router
