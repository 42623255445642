<template>
  <!-- 密码登入 -->
  <div class="bigbody">
    <div class="loginbox">
        <div class="logo">
            <img src="../assets/login/dllogo.png" alt="图片不见啦！">
        </div>
        <div  class="login" v-if="true">
          <h4>找回密码</h4>

          <el-form :model="queryForm" >
            <div style="border-bottom: 2px solid #f0f0f0;">
              <el-form-item >
                <el-input  v-model="queryForm.userName" placeholder="邀请账号" />
              </el-form-item>
            </div>
          </el-form>
          <div class="bottom">
            <button class="btn" @click="transmit()" >发送</button>
          </div>
      </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import router from '@/router'
import { ElMessage } from 'element-plus'
import axios from '@/Api/axios'
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data(){
    return{
      queryForm:{
        userName:"",
      },
   
      }
    },
    methods:{
      transmit(){
        axios.get("sys/findpass/"+this.queryForm.userName).then(res=>{
          if (res.data.msg=="已通知管理员申请!")
          {
            ElMessage({
            message: '已通知管理员申请!',
            type: 'success',
            })
          }
          else
          {
            ElMessage({
            message: res.data.msg,
            type: 'warning',
            })
          }
        })
      }
    },
    mounted() {
    },
  }

</script>

<style scoped lang="scss">
.impowerBox .title {
    text-align: center;
    font-size: 20px;
    display: none;
}
.impowerBox .info {
    width: 280px;
    margin: 0 auto;
    display: none;
}

.impowerBox .qrcode {
    width: 178px;
    height: 178px;
    position: relative;
    top: -14px;
}
.impowerBox .info {
    width: 280px;
    margin: 0 auto;
    display: none;
}


.erweima {

  position: relative;
  width: 360px;
  padding: 24px 36px;
  box-sizing: border-box;
}
.erweima h4 {
  width: 112px;
  display: inline-block;
  // background-color: pink;
  padding-bottom: 16px;
  border-bottom: 2px solid #000c1c;
  margin-bottom: 24px;
}
.erweima img {
  width: 17px;
  height: 15px;
  vertical-align: middle;
  margin-right: 12px;
}
.text {
  // background-color: pink;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 18px;
  z-index: 10086;
}
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color:#000c1c;
  }
  /deep/ .el-checkbox__label {
    padding-left: 12px;
  }
  /deep/ .el-checkbox {
    --el-checkbox-checked-icon-color: #002580;
    --el-checkbox-checked-bg-color: #fff;
    --el-checkbox-checked-input-border-color: #002580;
    --el-checkbox-input-border-color-hover: #002580;
  }
  .bigbody {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/login/bg.png);
    background-repeat: no-repeat;
    background-size:cover;
  }
  .loginbox {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
    /* width: 400px;
    height: 500px; */
    margin: 0 auto;
    /* background-color: red; */
  }
  .logo {

    margin: 0 auto;
    width: 84px;
    /* background-color: pink; */
    margin-bottom: 48px;
  }
  .logo img   {
    width: 84px;
  }
  .login {
      width: 360px;
      /* height: 300px; */
      padding: 24px 36px;
      box-sizing: border-box;
      /* 实现div块水平居中对齐 */
      position: relative;
      bottom: 0;
      left:0;
      border-radius: 8px;
      background-color: #fff;
  }
  h4 {
    width: 70px; 
    padding-bottom:16px ;
    text-align: left; 
    border-bottom: 2px solid #000c1c; 
    box-sizing: border-box; 
    /* background-color: #00b2a2; */
  }

  .el-form-item {
    height: 56px;
    /* background-color: #00b2a2; */
    margin:0;
    --el-border:none;
    --el-border-color-hover: none;
    --el-color-primary: none;
  }
  .el-input {
    height: 56px;
    --el-border-radius-base:0;
    --el-border-color: none;
  }
  .btn {
    display: block;
    margin-top: 18px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: none;
    letter-spacing: 1px;
    color: #fff;
    background-color: #002580;
  }


#code {
    border: none;
    letter-spacing:3px;
    color: black;
    background: white;
}
.verification1{
    vertical-align: middle;
    transform: translate(11px,0);
    width: 80px;
}
.yanzhengma_input{
  outline: none;
  width: 192px;
  height: 56px;
  
}

.el-input /deep/ .el-input__wrapper {
  padding: 0!important;
  border: none!important;;
  box-shadow: none!important;;
}
.el-input /deep/ .el-input__wrapper .is-focus {
  box-shadow: none!important;
}
.el-input /deep/ .el-input__wrapper:hover {
  box-shadow: none!important;
}
.yanzhengma_input {
  // background-color: pink;
}
p span {
  display: inline;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
  /* .bigbody {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/login/bg.png);
    background-repeat: no-repeat;
    background-size:cover;
  }
  .loginbox {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
    width: 400px;
    height: 500px;
    margin: 0 auto;
  }
  .logo {
    height: 100px;

  }
  .login {
      height: 220px;
      width: 100%;

      padding: 0 40px;
      box-sizing: border-box;

      position: relative;
      bottom: 0;
      left:0;
      border-radius: 10px;
      background-color: #fff;
  }
  h4 {
    width: 70px; 
    height: 70px;
    line-height: 80px;
    text-align: left; 
    border-bottom: 3px solid #000c1c; 
    box-sizing: border-box; 

  }

  .el-form-item {
    height: 50px;

    margin:0;
    --el-border:none;
    --el-border-color-hover: none;
    --el-color-primary: none;
  }
  .el-input {
    height: 50px;
    --el-border-radius-base:0;
    --el-border-color: none;
  }
  .btn {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    border: none;
    letter-spacing: 1px;
    color: #fff;
    background-color: #00257a;
  }
.bottom {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  padding: 0 35px 40px;
  box-sizing: border-box;

}

#code {
    font-size: 18px;
    letter-spacing:3px;
    color: #053d84;
    background: #f2f2f5;
}
.verification1{
    vertical-align: middle;
    transform: translate(-15px,0);
    width: 102px;
}
.yanzhengma_input{
  margin-top: 20px;
  width: 150px;
  height: 30px;
  position: relative;
  left: 8px;
} */

</style>
