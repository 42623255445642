<!-- 资源详情页 -->
<template>
<div class="body">
        <!-- 紫色区域 -->
    <div class="res-top">
        <p class="top-find main font14 font80" id="topupone">

            <span class="move"  v-if="this.$route.query.page==1" @click="$router.push({name:'findpage'})">发现</span>
            <span  class="move" v-else-if="this.$route.query.page==2" @click="$router.push({path:'/homepage/mypage/myhomescreen'})">我的</span>
            <span class="move" v-else-if="this.$route.query.page==3" @click="$router.push({name:'laboratorypage'})">实验室</span>
            <span  class="move" v-else-if="this.$route.query.page==5" ><span @click="$router.push({path:'/homepage/mypage/myhomescreen'})">我的</span><img src="../../assets/ResourceDetails/arrows.png" alt="图片不见啦"><span @click="$router.push({ name:'activitiess'})">新建</span></span>
            <span class="move" v-else-if="this.$route.query.page==6" @click="$router.push({name:'submytrajectory'})">我的轨迹</span>
            <span class="move"  v-else @click="$router.push({name:'firstpage'})">首页</span>
            <img src="../../assets/ResourceDetails/arrows.png" alt="图片不见啦">
            资源详情
        </p>
        <div class="main"  style="justify-content: center; box-sizing: border-box; padding: 0 96px;margin: 0 auto;display: flex;flex-wrap: wrap;align-items: center;">
            <h1 class="font30 font00 widthh1" style="font-weight: 700;"  >
                {{ReceiveData.title}}
            </h1>
            <button class="bt" style="background-color: #8bc34a;" @click="ApplyToUse()" v-if="devicestatus=='申请使用'">{{devicestatus}}</button>
            <button class="bt" style="background-color: #c70202;" @click="endofuse()" v-if="devicestatus=='结束使用'">{{devicestatus}}</button>
            <button class="bt" style="background-color: #c70202;" @click="jiueshuyuyue()" v-if="devicestatus=='结束预约'">{{devicestatus}}</button>
        </div>
       
        <ul  class="resources-firstul font14 font80" style="list-style:none;">
            <li>{{ReleaseTime}}</li>
            <span>|</span>
            <li>资源 <p>/</p> {{ReceiveData.t_type}}</li>
            <span>|</span>
            <li>
                <img src="../../assets/ResourceDetails/collect.png" alt="">
                {{ReceiveData.favocount}}
                <img src="../../assets/ResourceDetails/like.png" alt="">
                {{ReceiveData.lovenum}}
                <img src="../../assets/ResourceDetails/comment.png" alt="">
                {{ReceiveData.discount}}

            </li>
        </ul>  
    </div>
    
     <!-- 白色大块 -->
    <div  class="res-mid main">
    <!-- 资源信息 -->
        <div class="resmessgae">
                <div class="res-tit">
                    <img src="../../assets/ResourceDetails/message-a.png" alt="">
                    <span class="font28 font25">资源信息</span>
                </div>

        <el-carousel style="width: 844px;overflow: hidden; margin:0 auto;position: relative;" :interval="interval" arrow="always"    ref="carousel"
        v-if="img[0]" :height="485+'px'">
            <el-carousel-item v-for="(item,index) in img" :key="item" style="width: 100%;
            height: 100%;display: block;" >
            
              <video v-if=" item.type==1" :class="'video'+index" controls="true"    :key="item.url"     :src="getServerUrl()+'/video/'+ item.url"></video>
              
                <img v-else  :src="getServerUrl()+'/1image/'+ item.url" alt="" style="width: auto;height: auto;max-width:100%;position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);"  />
    
            </el-carousel-item>
        </el-carousel>

                <ul class="resources-secondul font20 font00" style="list-style:none; font-weight: 700;letter-spacing:1.5px!important;line-height: 32px!important;">
                    <li v-if="ReceiveData.space!='' && ReceiveData.space!=null">
                        <p></p><div class="lefttit">空间位置:</div>
                        <span class="font18 font00" style="font-weight: 400;">{{ReceiveData.space}}</span>
                    </li>
                    <li>
                        <p></p><div class="lefttit">使用状态:</div>
                        <span class="font18 font00" style="font-weight: 400;">{{TheCurrentState}}</span>
                    </li>
                    <li  v-if="ReceiveData.context!='' && ReceiveData.context!=null" style="height: auto;" >
                        <p></p><div class="lefttit">资源简介: </div>
                        <span class="font18 font00" style="font-weight: 400;">
                            {{ReceiveData.context}}</span>
                    </li>
                    <li v-for="(p,index) in RecentAnnouncement" :key="index" >
                        <p></p><div class="lefttit">{{p.title}}:</div>
                        <span  class="font18 font00" style="font-weight: 400;">{{p.context}}</span>
                    </li>
                </ul>       
        </div>
        <!-- 视频教程 -->
        <div style="background-color:#fff; margin-bottom: 36px;">
            <div class="res-tit">
                    <img src="../../assets/ResourceDetails/course-a.png" alt="">
                    <span class="font28 font25">视频教程</span>
            </div>
            <div class="vvv" v-if="video !=''">
               
                 <video v-for="(item) in video" :key="item.url"   controls="true"   :src="getServerUrl()+'/video/'+item.url"></video>
                
                <p></p>
            </div>
        </div>

        <div>
            
            <p style="display: flex; padding: 0 40px; position: relative;" v-if="ReceiveData.message!=null && ReceiveData.message!=''  " >
                <span class="font20 font00">
                    <div class="dian"></div>
                    <div class="lefttit fontweight">使用须知:</div>
                </span>
                    <span class="font18 font00" style="flex: 1;padding-left: 24px;font-size: 18px;white-space: pre-line;">{{ReceiveData.message}}</span>
            </p>
            <a class="font18 font00" style="position: relative ;padding-left: 40px; text-decoration: none;margin-top: 36px;display: block;" v-if="ReceiveData.filename!=null && ReceiveData.filename!=''" :href="ReceiveData.fileurl" target="_blank" :download="ReceiveData.fileurl" type="file">
                <div class="dian"></div>
                <div class="lefttit">
                <span  class="font20 font00 fontweight">附件下载：</span>
                </div>
                <span class="font18 font25 hhh" style="padding-left: 24px;font-size: 18px;">{{ReceiveData.filename}}</span>
            </a>    
        </div>

        <!-- 操作 -->
        <div style="margin-top: 96px;padding-bottom: 96px;">
            <div class="res-tit">
                    <img src="../../assets/ResourceDetails/operation-a.png" alt="">
                    <span class="font28 font25">操作</span>
            </div>
            <ul class="resources-third" style="list-style: none;">
                <li style="display: flex;">
                    <div class="dian"></div>
                    <span class="font20 font00 fontweight lefttit" >预约信息:</span>
                    <ul class="resources-third-one" style="flex:1;list-style: none;"  >
                        <li v-for="(p,index) in this.makeanappointment"  :key="index" class="font18 font00">
                            {{p.w_name}}<span>{{p.begin_time}}~{{p.end_time}}</span>
                        </li>
                    </ul>
                </li>
             
            </ul>
        </div>
    <!-- </div> -->
    <!-- 编辑 -->
   
    <div class="rightbox">
<!-- 右侧点赞按钮 -->
            <!-- <img class="mes" src="../../assets/abcd.png" alt=""> -->
            <div class="font12 font00"  :class="[hoverflag2==false?'mes ':'meshover']">收藏</div>
            <div @click="edit()" class="move" :class="[hoverflag==false?'chuangzuo':'chuangzuohover']" @mousemove="mumu" @mouseleave="mumu2" v-if="(this.$cookies.get('selectValue')!=null && ReceiveData.author==this.$cookies.get('selectValue').id)"></div>
            <img v-if="praise=='点赞成功'" class="move" @click="GiveALike()" src="../../assets/ResourceDetails/like-blue.png" alt="图片不见啦！">
            <img v-if="praise=='取消点赞'" class="move" @click="GiveALike()" src="../../assets/ResourceDetails/like-blue-l.png" alt="图片不见啦！">
<!-- 右侧收藏按钮 -->
<div style="margin-top:16px" v-if="this.$cookies.get('selectValue')!=null && sixnum!=0" class="shoucanglogo move shoucanglogohover" @mousemove="mumu3" @mouseleave="mumu4"  @click="centerDialogVisible = true">
            </div>
            <div style="margin-top:16px" v-if="this.$cookies.get('selectValue')!=null && sixnum==0" class="shoucanglogo move" :class="[hoverflag2==false?'shoucanglogo':'shoucanglogohover']" @mousemove="mumu3" @mouseleave="mumu4"  @click="centerDialogVisible = true">
            </div>

<!-- 点击收藏后的显示框 -->
            <el-dialog
            v-model="centerDialogVisible"
            title=""
            width="420px"
            align-center
            class="shoucang"
            show-close="true"
            style="border-radius: 12px;padding: 24px;box-sizing: border-box;"
            >
               <span class="font00 font18">收藏到文件夹</span>
                    
           
                    <div class="search">
                        <img src="../../assets/index/seach.png" alt="">
                        <input @input="screening()" type="text" placeholder="请输入关键字搜索" v-model="CollectionSearch">
                    </div>
                    <ul style="list-style: none;">
                        <li v-for="p in MyWorld" :key="p.id" >
                            <img src="../../assets/msa.png" v-if="p.space_name=='我的实验室'" alt="">
                                <img v-else :src="p.space_image" />
                            
                                <span class="font16 font00">{{p.space_name}}</span>
                                <button class="move" :class="[p.isfavorites==1?'btnwhite':'btnblue']" @click="collection(p.p_id,p.space_name)">{{p.isfavorites==1?"已收藏":"收藏"}}</button>

                              
                        </li>
                    </ul>

                     <div class="bbbtn" style="display:flex ;justify-content:center;">
                        <button class="btnblue move" style="margin-right: 48px;" @click="centerDialogVisible=false,dialogFormVisible=true">新建</button>
                        <button class="btnwhite move" @click="centerDialogVisible = false">确定</button>
                    </div>
            </el-dialog>
<!-- 右侧回到顶部按钮 -->
           <img class="move" @click="BackToTheTop('#topupone')" src="../../assets/ResourceDetails/arrows-blue.png" alt="图片不见啦！">
    </div>
    </div> 
    
    <div style="background-color: #f0f0f0;padding-top: 96px;padding-bottom: 96px;">
                <div class="res-bottom main bd">
                <div class="res-tit" style="padding: 24px 0 0 24px;">
                            <img  src="../../assets/ResourceDetails/discuss-a.png" alt="" style="margin-right: 0;">
                            <span class="font28 font25" style="color: #002580;">评论</span>
                </div>

                <div class="toppinglun">
                    <img v-if="this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl" alt="">
                    <img v-if="this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue').avatarUrl.length<=17 " :src="getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt="">
                    <!-- <input class="font14" v-model="input" type="text"  placeholder="说点什么吧..."> -->
                    <textarea class="font16" v-model="input" cols="30" rows="9" placeholder="说点什么吧...">

                    </textarea>

                </div>
                <div style="text-align: right;padding: 24px 36px 36px 0;">
                    <button class="move btnblue" @click="published()">发表</button>
                </div>

            <!-- 评论区已经发表的评论 -->
                <div class="read main">
                    <ul style="list-style: none;">
                        <li  v-for="(p,index) in this.comments" :key="index"> 
                            <img v-if="p.headImage.length>17 " :src="p.headImage">
                            <img v-if="p.headImage.length<=17 " :src="getServerUrl()+'/1image/'+ p.headImage" >
                            <div class="read-id">
                                <span class="font25 font16" style="margin-right: 12px;">{{p.w_name}}</span>
                                <span class="font80 font12" >{{p.puttime}}</span>
                                <span class="content font16 font00 huan" style="margin-top: 12px;">{{p.context}}</span>
                        </div>
                        </li>
                        
                    </ul>
                </div> 
            </div>

            <div class="Resources-Like main">
                        <div class="xiangguan">
                            <p>你可能喜欢</p>
                            <span @click="changeonechange()" class="hyh" >换一换</span>
                        </div>
                        <ul class="ccc" style="display: flex;list-style: none;">
                            <li class="myli move" v-for="(p,index) in RandomDisplay" :key="p.id" @click="MyContent(index)">
                               <div class="imgtop" style="overflow:hidden;position: relative;">
                                <img class="imgcss" :src="getServerUrl()+'/1image/'+ p.picurl" alt="" >
                               </div>
                                <span class="font16 text-ellipses tit">{{p.title}}</span>
                            </li>
                        </ul>
            </div>


    </div>
    <footers></footers>

</div>
    

<el-dialog
    v-model="dialogFormVisible"
    align-center
    :show-close="false"
    class="create"
    style="width: 420px;height: 196px;border-radius: 12px;position: relative;"
    >
       <p class="font18 font00">创建我的世界</p>
       <input class="inputbox leftmid2" v-model="Space.space_name" autocomplete="off" type="text" placeholder="实验室">
       <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button class="btnblue move" style="margin-right: 48px;" @click="save()">确认</button>
                <button class="btnwhite move" @click="dialogFormVisible = false">取消</button>
        </div>
</el-dialog>
</template>

<script >

  import { Search } from '@element-plus/icons-vue'
import footers from "@/components/footer.vue"
import axios from '@/Api/axios'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    watch:
        {
            $route(to,from)
            {
                if (this.$route.query.paramName)
                {
                    this.showshow()
                
                }
                this.BackToTheTop('#topupone')
            }
        },
    data(){
     
        return{
            abc:0,
            // 收藏搜索
            CollectionSearch:"",
            centerDialogVisible:false,
            Search,
            ReceiveData:{},
            // 发布时间
            ReleaseTime:"",
            // 最近公告里面的内容
            RecentAnnouncement:[],
            // 资源归属
            ResourcesBelongingTo:[],
            //使用须知
            UseTheInformation:[],
            // 当前状态
            TheCurrentState:"",
            // 查询是否点赞
            islike:{
                w_id:"",
                p_id:""
            },
            // 点赞成功说明当前可以点赞
            praise:"",
            // 喜欢
            RandomDisplay:[],
            // 发表的最新内容
            input:"",
            // 评论
            comments:[],
            // 评论总数
            commentsNums:[],
            // 发表评论
            Discuss:{
                w_id:"",
                p_id:"",
                context:""
            },
            // 来源页面
            SourcePage:"",
            // 收藏里面的项目
            MyWorld:[],
            MyWorld2:[],
            hoverflag:false,
            hoverflag2:false,
            // 图片
                img:[],
            // 视频
                video:[],
            // 视频封面
                videoCover:[],
                // 当前设备状态
                devicestatus:"",
                // 设备的消息id
                equipmentId:"",
                // 预约信息
                makeanappointment:[],
                dialogFormVisible:false,
                Space:{
                    space_name:"",
                    w_id:""
                },
                video2:[],
                sixnum:0,
                interval:5000,
        }
    },
    methods:{
   
       
                
    
        // 申请使用
        async ApplyToUse(){
        
        let a =0;
         await axios.get("appointment/"+this.$cookies.get("selectValue")
         .id+"/appointment/"+ this.$route.query.paramName).then(res=>{
          
            a=res.data.data
            
        })
         
            if (this.ReceiveData.applyuse==0)
            {
                ElMessage('该资源不能申请使用')
            }
            else if (a==1)
            {
                ElMessage('已申请，请等待管理员同意')
            }
            else{
                sessionStorage.setItem("deviceauthority",this.ReceiveData.deviceauthority);
                this.$router.push({path:'/homepage/applicationIsToUse',query:{paramName:this.$route.query.paramName,page:1,applyreason:this.ReceiveData.applyreason,deviceauthority:this.ReceiveData.deviceauthority}})            
            }
    },
        jiueshuyuyue(){
             axios.get("appointment/"+ this.$cookies.get("selectValue").id +"/cancelapp/"+this.$route.query.paramName).then(res=>{
                axios.get("appointment/"+this.$route.query.paramName+"/state/"+this.$cookies.get("selectValue").id).then(res=>{
            this.makeanappointment=res.data.data.appointments;
            if (res.data.data.Appointment!="" && res.data.data.Appointment!=null)
            {
                    this.equipmentId=res.data.data.Appointment.id;
            }
            
            if (res.data.data.state==0)
            {
                this.TheCurrentState="空闲"
                this.devicestatus="申请使用"
            }
            else if(res.data.data.state==1)
            {
                this.TheCurrentState="使用中"
                this.devicestatus="申请使用"
            
            }
            else if (res.data.data.state==2)
            {
                this.TheCurrentState="我的预约中"
                this.devicestatus="结束预约"
            }
            else if(res.data.data.state==3)
            {
                this.TheCurrentState="我在使用"
                this.devicestatus="结束使用"
            }
            else if (res.data.data.state==4)
            {
                this.TheCurrentState="禁用"
                this.devicestatus="申请使用"
            }
            else if (res.data.data.state==-2)
            {
                this.TheCurrentState="其他人预约"
                this.devicestatus="申请使用"
            }
        })
             })   
        },
        save(){
            this.centerDialogVisible=true
            this.Space.w_id=this.$cookies.get('selectValue').id;
            if (this.Space.space_name=="" || this.Space.space_name.trim()=="" )
            {
                ElMessage({
                message: '名字不能为空',
                type: 'warning',
                }) 
            }
            else
            {
                axios.post("PC/space/add",this.Space).then(res=>{ 
    
    // 创建成功
    if (res.data.code==20041)
    {
        
        ElMessage({
     type: 'success',
     message: "创建我的世界成功",
       })
       axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
  
                  this.$store.commit("show8",res.data.data.data);
                  
                  })
                  axios.post("PC/space/"+this.$cookies.get('selectValue').id+"/getfavorites/"+this.$route.query.paramName).then(res=>{
                       this.MyWorld=res.data.data;
                          this.MyWorld2=res.data.data;
                       })
    }
    else if (res.data.msg=="空间名不能重复")
    {
        ElMessage({
     type: 'info',
     message: `空间名不能重复`,
       })
    }
    else
    // 创建失败
    {
        ElMessage({
     type: 'info',
     message: `创建失败`,
       })
    }
})   
            }
            this.dialogFormVisible=false
        },
        // 结束使用
        endofuse(){
           
               axios.get("PC/appointment/"+  this.$route.query.paramName+ "/over/"+this.$cookies.get("selectValue").id).then(res=>{
                if (res.data.code==20031)
                {
                    axios.get("appointment/"+this.$route.query.paramName+"/state/"+this.$cookies.get("selectValue").id).then(res=>{
                
                this.makeanappointment=res.data.data.Appointments;
                if (res.data.data.Appointment!="" && res.data.data.Appointment!=null)
                {
                        this.equipmentId=res.data.data.Appointment.id;
                }
                
                if (res.data.data.state==0)
                {
                    this.TheCurrentState="空闲"
                    this.devicestatus="申请使用"
                }
                else if(res.data.data.state==1)
                {
                    this.TheCurrentState="使用中"
                    this.devicestatus="申请使用"
                
                }
                else if (res.data.data.state==2)
                {
                    this.TheCurrentState="我的预约中"
                    this.devicestatus="结束预约"
                }
                else if(res.data.data.state==3)
                {
                    this.TheCurrentState="我在使用"
                    this.devicestatus="结束使用"
                }
                else if (res.data.data.state==4)
                {
                    this.TheCurrentState="禁用"
                    this.devicestatus="申请使用"
                }
                else if (res.data.data.state==-2)
                {
                    this.TheCurrentState="其他人预约"
                    this.devicestatus="申请使用"
                }
            })
                }
                
               })
        },
        // 编辑
        edit(){
            this.$store.commit("resourcesruleForm",{});
            this.$router.push({
                name:"resourcess",
                query:{paramName:this.$route.query.paramName}
            })
        },
        // 获取封装的url
        getServerUrl(){
                return axios.getServerUrl();
        },
        mumu(){
            this.hoverflag=true
        },
        mumu2(){
            this.hoverflag=false
        },
        mumu3(){
            this.hoverflag2=true
        },
        mumu4(){
            this.hoverflag2=false
        },
        screening(){
            this.MyWorld=[];
                for (let i=0;i<this.MyWorld2.length;i++)
                {
                    if (this.MyWorld2[i].space_name.indexOf(this.CollectionSearch)!=-1)
                    {
                        this.MyWorld.push(this.MyWorld2[i])
                    }
                }
        },
        // 收藏/取消收藏
        collection(id,name){
            let space={w_id:"",p_id:"",space_name:""}
            space.w_id=this.$cookies.get("selectValue").id;
            space.p_id=id;
            space.space_name=name;
                axios.post("PC/space",space).then(res=>{
                    axios.post("PC/space/"+this.$cookies.get('selectValue').id+"/getfavorites/"+this.$route.query.paramName).then(res=>{
                                 this.MyWorld=res.data.data;
                                    this.MyWorld2=res.data.data;
                                    this.sixnum=0
                                    for (let i=0;i<this.MyWorld.length;i++)
            {
                if (this.MyWorld[i].isfavorites==1){
                    this.sixnum=this.sixnum+1;
                }
            }
                    })
                    axios.get("PC/project/"+this.$route.query.paramName).then(res=>{
                       
                        this.ReceiveData=res.data.data;
                    })
                                
                  
                })
        },
        MyContent(e){
            
            axios.get("PC/project/"+this.RandomDisplay[e].id).then(res=>{
                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:1}})
       
                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:1}})
        
                    
                }
                
          
            })
          this.showshow()
        },
        
        // 点赞事件
        GiveALike(){
          
                axios.post("PC/like/like",this.islike).then(res=>{
                  
                    if (res.data.data==1)
                    {
                        this.ReceiveData.lovenum=this.ReceiveData.lovenum+1;
                        this.praise="取消点赞";
                    }
                    else if(res.data.data==2)
                    {
                        this.ReceiveData.lovenum=this.ReceiveData.lovenum-1;
                        this.praise="点赞成功";
                    }
                    
                    
                })
             
        },
    
        // 回到顶部
        BackToTheTop(e){
            document.querySelector(e).scrollIntoView(true);
        },
        // 换一换
        changeonechange(){
            axios.get("PC/project/getRanFive").then(res=>{
                let a=res.data.data;
            if (a!=[] && a!=null && a!="")
            {
                for (let i=0;i<a.length;i++){
                    if (a[i].picurl=="" || a[i].picurl==null){
                        a[i].picurl='mr.png'
                    }
                }
            }    
            this.RandomDisplay=a;
           
               
          })
         
       

        //   this.BackToTheTop('#topupone');
        },
        // 发布评论
        published(){
            if (this.$cookies.get("selectValue")!=null  && this.input!="" && this.input.trim()!="")
            {
                this.Discuss.w_id=this.$cookies.get("selectValue").id;
            this.Discuss.p_id=this.$route.query.paramName;
            this.Discuss.context=this.input;
            this.input=""
            axios.post("PC/discuss",this.Discuss).then(res=>{
                axios.get("PC/discuss/"+this.$route.query.paramName).then(res=>{
            this.comments=res.data.data.message;
            this.commentsNums=this.comments.length;
          }) 
            })
        }
        else if ( this.input=="" || this.input.trim()=="" )
        {
            this.$message({
          message: '发表内容不能为空',
          type: 'warning'
        });
        }
        else
        {
            this.$message({
          message: '请先登入',
          type: 'warning'
        });
        }
     },
         showshow(){
            this.img=[]
            this.video=[]
             //   图片添加
        axios.get("PC/image/"+this.$route.query.paramName).then(res=>{
        let a=res.data.data
       
            for (let i=0;i<a.length;i++)
        {
            if (a[i].type==7)
            {
                this.img.push({url:a[i].url,type:1})
            }
        }
        // for (let i=0;i<a.length;i++)
        // {
        //     if (a[i].type==1)
        //     {
        //         this.img.push({url:a[i].url,type:0})
        //     }
        // }
    
        for (let i=0;i<a.length;i++)
        {
            if (a[i].type==2)
            {
                this.img.push({url:a[i].url,type:0})
            }
            else if (a[i].type==4)
            {
          
                this.videoCover.push({url:a[i].url})
            }
            else if (a[i].type==5)
            {
            
                this.video.push({url:a[i].url})
            }
           
            
        }
       
        //    console.log(this.img[0])
        //     if (this.img[0].type==1){
        
        //         let a=document.querySelector(".video"+0)
        //         console.log(a)
        //         console.log(a.duration)
        //     }
                
        
    })
    // 判断是否收藏
  
    if (this.$cookies.get('selectValue')!=null )
    {
        axios.post("PC/space/"+this.$cookies.get('selectValue').id+"/getfavorites/"+this.$route.query.paramName).then(res=>{
       
            this.MyWorld=res.data.data;
        this.MyWorld2=res.data.data;
        for (let i=0;i<this.MyWorld.length;i++)
            {
                if (this.MyWorld[i].isfavorites==1){
                    this.sixnum=this.sixnum+1;
                }
            }
    })
    }

  
    if (this.$route.query.page==0)
    {
        this.SourcePage="发现"
    }
    else if (this.$route.query.page==1)
    {
        this.SourcePage="我的"
    }
    else if (this.$route.query.page==2)
    {
        this.SourcePage="实验室"
    }
   axios.get("PC/project/"+this.$route.query.paramName).then(res=>{
    
                this.ReceiveData=res.data.data;
                this.ReleaseTime=res.data.data.puttime.substr(0,10)

        })    
   axios.get("PC/other/"+this.$route.query.paramName).then(res=>{
                this.RecentAnnouncement=res.data.data;
    })
   axios.get("PC/other/msag/"+this.$route.query.paramName).then(res=>{
        this.UseTheInformation=res.data.data;
    })
    if (this.$cookies.get("selectValue")!="" && this.$cookies.get("selectValue")!=null )
    {
     
        axios.get("appointment/"+this.$route.query.paramName+"/state/"+this.$cookies.get("selectValue").id).then(res=>{
          
        this.makeanappointment=res.data.data.appointments;
        if (res.data.data.Appointment!="" && res.data.data.Appointment!=null)
        {
                this.equipmentId=res.data.data.Appointment.id;
        }
        
        if (res.data.data.state==0)
        {
            this.TheCurrentState="空闲"
            this.devicestatus="申请使用"
        }
        else if(res.data.data.state==1)
        {
            let a=res.data.data.use[0];
            this.TheCurrentState=a.w_name+"正在使用中 "+a.end_time +" 结束"
            this.devicestatus="申请使用"
        
        }
        else if (res.data.data.state==2)
        {
            this.TheCurrentState="我的预约中"
            this.devicestatus="结束预约"
        }
        else if(res.data.data.state==3)
        {
            let a=res.data.data.use[0];
            this.TheCurrentState=a.w_name+"正在使用中 "+a.end_time +" 结束"
    
            this.devicestatus="结束使用"
        }
        else if (res.data.data.state==4)
        {
            this.TheCurrentState="禁用"
            this.devicestatus="申请使用"
        }
        else if (res.data.data.state==-2)
        {
            this.TheCurrentState="其他人预约"
            this.devicestatus="申请使用"
        }
    })
    
    }
    
    // 点赞
    if (this.$cookies.get("selectValue")!=null)
    {
        this.islike.w_id=this.$cookies.get("selectValue").id;
    this.islike.p_id=this.$route.query.paramName;
    //判断当前是否点赞
    axios.post("PC/like/islike",this.islike).then(res=>{

        this.praise=res.data.data;
      
    })
    }
  
    // // 喜欢接口
    // axios.get("PC/project/getRanFive").then(res=>{
    //     this.RandomDisplay=res.data.data;
    
    //   })
    this.changeonechange()

    //  显示评论
      axios.get("PC/discuss/"+this.$route.query.paramName).then(res=>{

        this.comments=res.data.data.message;
      
        this.commentsNums=this.comments.length;
      })
         }
    },
    components:{
      footers
    },
    mounted(){
        this.BackToTheTop('#topupone')
      this.showshow()
    

    }
}
</script>

<style scoped lang="scss">
.create p {
    position: absolute;
    top: 24px;
    left: 24px;
}
.create .bbbtn {
    bottom: 24px;
}
.create .inputbox {
    outline: none;
    padding-left: 12px;
    top: 71px;
    width: 372px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    border: 1.5px solid #808080;
}
.create .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.hhh:hover {
    font-weight: 700;
}
.content {
    width:100%;
}
.read-id {
    width: 90%;
}
.tit {
    padding: 0 24px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
}

.rightbox {
    z-index: 10086;
    position:fixed;
    bottom:90px;
    right: 60px;
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
}
.mes {
    // display: none;
    opacity: 0;
    transition: .3s;
    position: absolute;
    bottom:80px;
    right: 60px;
    width: 47px!important;
    height: 21px!important;
    padding-left: 10px;
    box-sizing: border-box;
    /* text-align: center; */
    line-height: 22px;
    /* background-color: pink; */
    background-image: url(../../assets/abcd.png) ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
}
.meshover {
    // display: block;
    opacity: 1;
    transition: .3s;
    position: absolute;
    bottom:80px;
    right: 60px;
    width: 47px!important;
    height: 21px!important;
    padding-left: 10px;
    box-sizing: border-box;
    /* text-align: center; */
    line-height: 22px;
    /* background-color: pink; */
    background-image: url(../../assets/abcd.png) ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
}
.rightbox img {
    width: 48px;
    height: 48px;
    margin-top: 16px;
}
/* PC */
@media screen and (min-width:1201px) {
    .main {
        width: 1366px;
        /* background-color: #00b2a2; */
    }
    .widthh1 {
        max-width: 1032px;
    }
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align:middle;
    }
    .myli:nth-child(5n){
        margin-right: 0;
    }
    .myli:nth-child(n+6){
        margin-top: 31.5px;
    }
}
/* 平板 */
@media screen and (max-width:1200px) {
    .main {
        width: 980px;
        /* background-color: pink; */
    }
    .widthh1 {
        max-width: 646px;
    }
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        background-color: #fff;
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
    }
    .myli:nth-child(n+5){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
    .ccc .myli:nth-child(n+5) {
        display: none;
    }
}
.box {
    padding-top: 48px;
}
.body {
    background-color: #fff;
}
.main {
    margin: 0 auto;
}
.res-top {
    /* height: 194px; */
    background-color: #eef3ff;
}
.res-top .top-find {
    padding-top:24px;
    padding-left: 48px;
    box-sizing: border-box;
}

.res-top .top-find img {
    margin: 0 13px;
}
.res-top h1 {
    /* flex: 1; */
    box-sizing: border-box;
    font-weight: 400;
    text-align: center;
    /* background-color: pink; */
    word-wrap: break-word;
}
.res-top .resources-firstul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 36px 0 48px 0; */
    padding: 36px 0 48px 0;
    /* background-color: red; */
}
.res-top .resources-firstul span {
    margin: 0 16px;
}
.res-top .resources-firstul p {
    display: inline-block;
    margin: 0 6px;
}
.res-top .resources-firstul img {
    vertical-align: top;
    width: 14px;
    height: 13px;
}
.resmessgae {
    padding-top: 48px;
}
.res-tit {
    display: flex;
    margin-bottom: 36px;
    
}
.res-tit span {
    margin-left: 12px;
    line-height: 35px;
}
.res-tit img{
    vertical-align: bottom;
    width: 28px;
    height: 30px;
}
.resources-secondul {
    /* margin-top: 48px;
    margin-bottom: 96px; */
    padding: 48px 0 96px 0;
    box-sizing: border-box;
    /* background-color: red; */
}
.resources-secondul p {
    position: absolute;
    top: 14px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    /* margin-right: 24px; */

}
.resources-secondul li {
    position: relative;
    padding: 0 40px;
    box-sizing:border-box;
    /* background-color: pink; */
    /* background: red; */
    display: flex;
}
.resources-secondul li:nth-child(n+1) {
    margin-top:36px ;
}

.resources-secondul li span {
    /* margin-left: 24px; */
    display: inline-block;
    background-color: #fff;
    padding: 0 0 0 24px;
    box-sizing: border-box;
    flex: 1;
    word-break: normal;
    width: auto;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}
.lefttit {
    display: inline-block;
    width: 164px;
    /* background-color: pink; */
    padding-left: 32px;
}
.vvv {
    width: 844px;
    // height: 485px;
    margin: 0 auto;
    overflow: hidden;
}
video {
    width: 844px;
    height: 485px;
    margin-top: 28px;
}
video:nth-child(1){
    margin-top: 0;
}
.dian {
    position: absolute;
    top: 14px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    margin-right: 24px;
}
.resources-third {
    padding: 0 40px;
    position: relative;
}
.resources-third span {
    padding-left: 24px;

    /* width: 196px;
    display: inline-block;
    padding-left: 32px;
    box-sizing: border-box;
    background-color: pink; */
}
.bt {
    width: 118px;
    height: 40px;
    border-radius: 20px;
    /* display: block; */
    color: #fff;
    outline: none;
    border: none;
    font-size: 17px;
    margin-left: 24px;
    cursor: pointer;
}
.resources-third-one li {
    padding-left: 24px;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;

    /* background-color: red; */

}
.resources-third-one li:nth-child(n+3) {
    margin-top: 12px;
}
.res-bottom {
    background-color: #fff;
}
.toppinglun {
    position: relative;
    display: flex;
    padding: 0 36px 0 36px;
    /* background-color: #00b2a2; */
}
.toppinglun textarea {
    /* padding-left: 12px; */
    padding: 12px;
    box-sizing: border-box;
    flex: 1;
    border-radius: 9px;
    outline: none;
    border: 2px solid #d9d9d9;
    background-color: #f2f2f2;
    resize: none;
}
.res-bottom button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    border: none;
    background-color: #002580;
    color: #fff;
}
.res-bottom img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 28px;
}
.read {
    /* background-color: red; */
    padding: 0 36px;
    box-sizing: border-box;
    border-radius: 9px;
}
.read li {
    display: flex;
    padding: 24px 0;
    box-sizing: border-box;
    border-bottom: 2px solid #d9d9d9;
    /* background-color: red; */
}
.read ul li:nth-last-child(1){
    border: none!important;
    /* background-color: pink; */
}
.xiangguan {
    margin-top: 96px;
    margin-bottom: 24px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
}
.hyh:hover{
    color: #002580;
    cursor: pointer;

}
/deep/.shoucang .el-dialog__headerbtn {
    // display: none;
}
/deep/ .shoucang .el-dialog__header {
    // display: none;
    padding: 0;
}
/deep/ .shoucang .el-dialog__body {
    // display: none;
    padding: 0 !important;
}
.shoucang .bbbtn {
    margin-top: 24px;
}
.shoucang .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.shoucang .search {
    position: relative;
    margin: 16px 0;
    // background: pink;

}
.shoucang .search input {
    padding-left: 44px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    border: none;
    background-color: #f2f2f2;

}
.shoucang .search img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    left: 12px;
    z-index: 2;
    margin-top: 0;

}
.shoucang ul {
    height: 320px;
    overflow-y: auto;
}
.shoucang li {
    position: relative;
    height:64px;
    line-height:64px;
    // background: #00b2a2;

}
.shoucang li img {
    vertical-align: middle;
    margin-top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;
    // background-color: pink;
}
.shoucang li button{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    border: none;
    width: 72px;
    height: 36px;
    border-radius: 18px;
    // font-size: 15px;
}

.shoucanglogo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue.png); 
    background-size: contain;
}
.shoucanglogohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue-l.png); 
    background-size: contain;
}
.chuangzuo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue.png); 
    background-size: contain;
}
.chuangzuohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue-l.png); 
    background-size: contain;
}
/* .res-top {
    position: relative;
    width: 100%;
    height: 200px;
    background-color:#eef3ff;

}
.res-top .top-find {
    position: absolute;
    top: 25px;
    left: 100px;
    color: #94979f;
} */
/* .res-top .top-find img {
    margin: 0 10px;
}
.res-top h1 {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 45px;
}
.res-top button {
    position: relative;
    left: 20px;
    bottom: 5px;
    width: 75px;
    height: 25px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 13px;
    border: none;
    background-color: #bac6e3;
}
 .resources-firstul
 {
        list-style: none;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        color: #94979f;
 }
 
 .resources-firstul span {
    margin: 0 10px;
 } */

/* ---------------------------------------------- */
/* .res-mid {
    position: relative;
    background-color:#fff ;
    width: 100%;
    min-height: 120px;
    padding-bottom:80px ;
}
.res-tit {
    display: flex;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
} */
/* .res-tit img {
    width:40px;
    height: 40px;
    margin-right: 10px;
}
.resources-secondul
{
    list-style-type: disc;
    width: 80%;
    text-align: left;
    margin-top: 50px;
    margin-left:100px ;
    text-indent: 20px;
}

.resources-secondul li
{
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
}
ul li::marker {
    color: #002580;
}
.resources-secondul span {
    margin-left:20px ;
}





.resources-third
{
    list-style-type: disc;
    text-align: left;
    width: 80%;
    margin-top: 50px;
    margin-left:100px ;
    text-indent: 20px;
    font-size: 20px;

}
.resources-third-one {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-left: 20px;
}
.resources-third-one li
{

   display: inline;
   margin-top:10px ;
    margin-right: 50px;
}
.resources-third-one span
{

    margin-left: 20px;
}
.resources-third-one
{
    position: relative;
    left: 70px;
    top: -20px;
}
.Resources-Like
{
        padding: 50px 0;
        width:1200px;
        min-height: 250px;
        position: relative;
        left: 160px;
        top: 20px;
}
.Resources-Like .maybelike {
    display: flex;
    justify-content: space-between;
}
.Resources-Like .maybelike img {
    margin-left:5px ;
    margin-top:5px ;
}
.Resources-Like .content {
    list-style: none;
    text-align: left; 
    position:absolute;
}
.resources-myli
{
        display: inline-block;
        background:#fff;
        width: 213px;
        height: 150px;
        margin-right: 35px;
        border-radius:20px ;

}
.Resources-Like span {
    margin-top:20px ;
    margin-left:20px ;
}
.yuan{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: black;
    display: block;
}
.res-bottom {
    width: 1200px;
    min-height: 200px;
    background-color: white;
    margin: auto;
    margin-top: 100px;
    border-radius: 20px;
    position: relative;
    padding: 20px 30px;
    box-sizing: border-box;
}
.res-bottom input {
    margin-left: 40px; 
    width:90%;
    height: 70px;
    border: 2px solid #d7d4d4;
    border-radius: 5px;
    outline: none;
    background-color: #f2f2f2;
    padding: 0 10px;
    box-sizing: border-box;
}
.res-bottom button {
    width:150px ;
    height: 40px;
    background-color: #002580;
    color: #fff;
    border-radius: 20px;
    letter-spacing: 2px;
    border:none;
    margin-top: 10px;
    margin-left: 950px;
}
.res-bottom .read {
    min-height: 100px; 
}
.res-bottom .read li {
    position: relative;
    min-height: 100px;
    margin-top: 10px;
    border-bottom: 2px solid #c2c2c2;
}
.res-bottom .read li:last-child {
    border:none;
}
.read .el-avatar {
    position: absolute;
    top: 25px;
    left: 0;
}
.read-id {
    display: flex;
    position: absolute;
    top: 25px;
    left: 50px;

}
.read .content {
    position: absolute;
    left: 50px;
    top: 50px;
    width: 90%;
    min-height: 30px;
    text-align: left;
}
.rightbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 1420px;
    top: 430px;
    height: 180px;
}
.rightbox img {
    width: 50px;
    height: 50px;
}

.shoucang div {
    height: 50px;
    line-height: 50px;
    text-align: left;
    color: black;
    font-size: 18px;
}
.shoucang ul {
    list-style: none;
    height: 300px;
    overflow: auto; 
}
.shoucang li {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-top:10px ;
    text-align: left;
}
.shoucang li span {
    display: block;
    flex: 1;
    padding: 0 15px;
    color: black;
    font-size: 18px;
}

.bububu {
    width: 70px;
    height:36px;
    border-radius: 18px;
    border: none;
    letter-spacing: 1px;
    background-color: #002680;
    color: #fff;
}
.bububued {
    width: 70px;
    height:36px;
    border-radius: 18px;
    border: none;
    letter-spacing: 1px;
    background-color: #fff;
    color: #808080;
    border: 2px solid #808080;
}
.chuangzuo {
    position: fixed;
    left: 1420px; 
    top: 365px; 
    width: 50px;
    height: 50px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue.png); 
    background-size: contain;
}
.chuangzuohover {
    position: fixed;
    left: 1420px; 
    top: 365px; 
    width: 50px;
    height: 50px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue-l.png); 
    background-size: contain;
}
.shoucanglogo {
    width: 50px;
    height: 50px;
    background-image: url(../../assets/ResourceDetails/collect-blue.png); 
    background-size: contain;
}
.shoucanglogohover {
    width: 50px;
    height: 50px;
    background-image: url(../../assets/ResourceDetails/collect-blue-l.png); 
    background-size: contain;
}
.shoucang .search {
    
    position: relative;
    height: 50px;
}
.search input {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    outline: none;
    width: 90%;
    height:40px;
    border-radius: 20px;
    border: none;
    background-color: #f0f0f0;
    padding: 0 40px;
    box-sizing: border-box;
}
.search img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:30px;
    width: 20px;
    height: 20px;
    z-index: 3;
} */

</style>