<template>
  <!-- 密码登入 -->
  <div class="bigbody">
    
    <div class="loginbox">
        <div class="logo">
            <img src="../assets/login/dllogo.png" alt="图片不见啦！">
        </div>
        <div  class="login" v-if="!this.weixingtrue" >
          <h4>密码登录</h4>
          <el-form :model="queryForm" >
            <div>
              <el-form-item >
                <el-input  v-model="queryForm.userName" class="font14" placeholder="邀请账号" />
              </el-form-item>
            </div>

            <div style="border-top: 2px solid #f0f0f0;border-bottom: 2px solid #f0f0f0;">
              <el-form-item >
                <el-input  type="password" class="font14"  v-model="queryForm.passWord" placeholder="密码"/>
              </el-form-item>
            </div>
          </el-form>

          <el-input class="yanzhengma_input" v-model="picLyanzhengma" style="position: relative; outline: none!important;border-bottom: 2px solid #f0f0f0;" placeholder="请输入验证码" />
              <input  type="button" id="code" @click="createCode"  class="verification1 font15" v-model="checkCode"/>
          <br/>

          <div class="bottom">
            <button class="btn font15 btnblue" @click="login" >登录</button>
            <button class="btn font15" style="background-color: #58aa48;margin-bottom: 18px;" @click="weixing()">微信扫码登录</button>
            <p style="display: flex;justify-content: space-between;align-self: center;position: relative;">  
              <el-checkbox class="font15 font00" v-model="checked">下次自动登入</el-checkbox> 
              <span class="font14 font80" @click="back()">找回密码</span>
            </p>
          </div>
         
      </div>
        <div v-if="this.weixingtrue" class="erweima bd">
            <h4>微信二维码登录</h4>
            <div style="height: 178px;position: relative;">
              <wxlogin :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIHRleHQtYWxpZ246IGNlbnRlcjsKICAgIGZvbnQtc2l6ZTogMjBweDsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLmluZm8gewogICAgd2lkdGg6IDI4MHB4OwogICAgbWFyZ2luOiAwIGF1dG87CiAgICBkaXNwbGF5OiBub25lOwp9CgouaW1wb3dlckJveCAucXJjb2RlIHsKICAgIHdpZHRoOiAxNzhweDsKICAgIGhlaWdodDogMTc4cHg7CiAgICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgICB0b3A6IC0xNHB4Owp9Ci5pbXBvd2VyQm94IC5pbmZvIHsKICAgIHdpZHRoOiAyODBweDsKICAgIG1hcmdpbjogMCBhdXRvOwogICAgZGlzcGxheTogbm9uZTsKfQ=='" 
              style="height:178px; position: absolute;top:0;left: 50%;transform: translateX(-50%);overflow:hidden"  
              v-if="this.weixingtrue" appid="wxaca70d555f9cfb83" scope="snsapi_login" :theme="'black'"   :redirect_uri="redirect_uri">
              </wxlogin>
            </div>
            <div class="text">
              <img src="../assets/AddinganActivity/greenweixin.png" alt="">
              <span class="font00 font14">使用微信扫一扫登录</span>
            </div>
            <div style="text-align:center;z-index: 10086;" class="font80 font16 move" >
              <span style="cursor: pointer;" @click.stop="asdk()">密码登录</span>
            </div>
        </div>
        



       </div>
      </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import router from '@/router'
import { ElMessage } from 'element-plus'
import axios from '@/Api/axios'
import wxlogin from "vue-wxlogin"
var code;
export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    wxlogin,
  },

  data(){
    return{
      queryForm:{
        userName:"",
        passWord:"",
      },
      // 验证码
      checkCode:"",
      // 判断输入的验证码
      picLyanzhengma:"",
      TheUserName:"",
      checked:false,
      redirect_uri:"http://www.wulcl.cn",
      weixingtrue:false
      }
    },
    methods:{
      asdk(){
          this.weixingtrue=false
         
      },
      weixing(){
            this.weixingtrue=true;

      },
      back(){
   
        router.push({
          name:"forgotpassword",
              }); 
      },
      createCode(){
          code = "";    
          var codeLength = 4;//验证码的长度   
          var random = new Array(0,1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R',   
           'S','T','U','V','W','X','Y','Z');//随机数   
          for(var i = 0; i < codeLength; i++) {//循环操作   
              var index = Math.floor(Math.random()*36);//取得随机数的索引（0~35）   
              code += random[index];//根据索引取得随机数加到code上   
          }   
              this.checkCode = code;//把code值赋给验证码   
      },
      login(){
        this.picLyanzhengma=this.picLyanzhengma.toLocaleUpperCase()
        if (this.queryForm.userName=="" || this.queryForm.passWord=="")
        {
          ElMessage({
             message: '邀请账号或密码不能为空',
             type: 'warning',
             
             })
             this.createCode();
        }
        else if (this.picLyanzhengma!=this.checkCode)
        {
          ElMessage({
             message: '验证码不正确',
             type: 'warning',
             
             })
             this.createCode();
        }
        else
        { 
          axios.get("PC/sys/"+this.queryForm.userName+"/yz/"+this.queryForm.passWord).then(res=>{
         
            if(res.data.code==20041){
              this.$cookies.set('selectValue', res.data.data,{ expires: 0 })
          
              this.$store.commit("zidonga",res.data.data.openid)
              if (this.checked==true)
              {
               this.$cookies.set('selectValue', res.data.data,{ expires: 0 }),
                localStorage.setItem("zidong",res.data.data.openid)
                localStorage.setItem("isToken","aaa")
              }
              else
              {
                localStorage.setItem("zidong","")
              }
              localStorage.setItem("isToken","aaa")
              ElMessage.success("登录成功！"),
              router.push({
                name:"myhomescreen",
              });
              this.$store.commit("isshow",false);
            }else{
              ElMessage.error("邀请账号或密码不正确！")
              this.createCode();
            }
          })
        }
        
      },
      Forgotpassword()
      {
           router.push({
            path:"/forgotpassword"
            
           })
      },
      registered(){
        router.push({
          path:"/registered"
        })
      },
      // getcookies(){
       
      //     if (  this.$cookies.get("selectValue")!='' &&   this.$cookies.get("selectValue")!=null)
      //     {
      //         this.$cookies.set('selectValue', res.data.data,7),
      //         localStorage.setItem("isToken","aaa")
      //         ElMessage.success("登录成功！"),
      //         router.push({
      //           name:"firstpage",
      //         });
      //     }
      // }
    
    },
    mounted() {
      this.createCode();
      this.$store.commit("isshow2");
      this.$store.commit("abcdefg",true)

      // this.getcookies();
      // if (localStorage.getItem("zidong")!="" && localStorage.getItem("zidong")!=null)
      // {
      //   this.$cookies.set('selectValue', localStorage.getItem("zidong"),{ expires: 0 }),
            
      //       localStorage.setItem("isToken","aaa")
      //       ElMessage.success("登录成功！"),
      //       router.push({
      //         name:"firstpage",
      //       });
      // } 
      // code=091kSFFa1RDjnF00cZFa1DUITx2kSFFp&state=
    //  axios.post('wechat_user/wxlogin',{code:'031yrEFa18vqnF05yOFa15X2fi0yrEFE'}).then(res=>{
    //   console.log(res)
    //  })
    },
  }

</script>

<style scoped lang="scss">

.impowerBox .title {
    text-align: center;
    font-size: 20px;
    display: none;
}
.impowerBox .info {
    width: 280px;
    margin: 0 auto;
    display: none;
}

.impowerBox .qrcode {
    width: 178px;
    height: 178px;
    position: relative;
    top: -14px;
}
.impowerBox .info {
    width: 280px;
    margin: 0 auto;
    display: none;
}


.erweima {

  position: relative;
  width: 360px;
  padding: 24px 36px;
  box-sizing: border-box;
}
.erweima h4 {
  width: 112px;
  display: inline-block;
  // background-color: pink;
  padding-bottom: 16px;
  border-bottom: 2px solid #000c1c;
  margin-bottom: 24px;
}
.erweima img {
  width: 17px;
  height: 15px;
  vertical-align: middle;
  margin-right: 12px;
}
.text {
  // background-color: pink;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 18px;
  z-index: 10086;
}
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color:#000c1c;
  }
  /deep/ .el-checkbox__label {
    padding-left: 12px;
  }
  /deep/ .el-checkbox {
    --el-checkbox-checked-icon-color: #002580;
    --el-checkbox-checked-bg-color: #fff;
    --el-checkbox-checked-input-border-color: #002580;
    --el-checkbox-input-border-color-hover: #002580;
  }
  .bigbody {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/login/bg.png);
    background-repeat: no-repeat;
    background-size:cover;
  }
  .loginbox {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
    /* width: 400px;
    height: 500px; */
    margin: 0 auto;
    /* background-color: red; */
  }
  .logo {

    margin: 0 auto;
    width: 84px;
    /* background-color: pink; */
    margin-bottom: 48px;
  }
  .logo img   {
    width: 84px;
  }
  .login {
      width: 360px;
      /* height: 300px; */
      padding: 24px 36px;
      box-sizing: border-box;
      /* 实现div块水平居中对齐 */
      position: relative;
      bottom: 0;
      left:0;
      border-radius: 8px;
      background-color: #fff;
  }
  h4 {
    width: 70px; 
    padding-bottom:16px ;
    text-align: left; 
    border-bottom: 2px solid #000c1c; 
    box-sizing: border-box; 
    /* background-color: #00b2a2; */
  }

  .el-form-item {
    height: 56px;
    /* background-color: #00b2a2; */
    margin:0;
    --el-border:none;
    --el-border-color-hover: none;
    --el-color-primary: none;
  }
  .el-input {
    height: 56px;
    --el-border-radius-base:0;
    --el-border-color: none;
  }
  .btn {
    display: block;
    margin-top: 18px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: none;
    letter-spacing: 1px;
    color: #fff;
    background-color: #002580;
  }


#code {
    border: none;
    letter-spacing:3px;
    color: black;
    background: white;
}
.verification1{
    vertical-align: middle;
    transform: translate(11px,0);
    width: 80px;
}
.yanzhengma_input{
  outline: none;
  width: 192px;
  height: 56px;
  
}

.el-input /deep/ .el-input__wrapper {
  padding: 0!important;
  border: none!important;;
  box-shadow: none!important;;
}
.el-input /deep/ .el-input__wrapper .is-focus {
  box-shadow: none!important;
}
.el-input /deep/ .el-input__wrapper:hover {
  box-shadow: none!important;
}
.yanzhengma_input {
  // background-color: pink;
}
p span {
  display: inline;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
