<!-- 发现界面 -->
<template>  
<div style="display:flex;flex-direction:column;margin: 0 auto ;" :style="{'min-width':screen+'px'}">
     <!-- 首页发现页面的白色区域 -->
    <div class="history" >
        <!-- 历史搜索区域 -->
            <div class="top">
                    <div id="left" >
                        <div class="lefttop" >
                            <h6 class="font16">历史搜索</h6>
                            <span  @click="EmptyHistory()" class="lishi font14 move">清空</span>
                        </div>
                        <ul class="SearchHistory">
                            <li class="font14 font80 text-ellipsis move" @click="Historysearch(item)"  v-for="(item,index) in historyList" :key="index">{{item}}</li>
                        </ul>
                    </div>
                <!-- 搜索区域 -->
                    <div class="seach">
                        <div class="topmid serchwidth" style="position: relative;margin: 0 auto;">
                            <img src="../../assets/index/seach.png" alt="">
                            <input class="serchinput serchwidth" type="text" placeholder="请输入关键字搜索" v-model="SearchByKeyword" @keydown.enter="SearchBy()">
                        </div>
                    </div> 
            </div>
    </div>

        <!-- 灰色区域 -->
    <div class="bottom" :style="{'min-width':screen+'px'}">
        <div class="top">
                    <!-- 显示搜索到的信息 -->
            <ul class="bbb" v-cloak>
                <li @click="MyContentone(index)" class="myli"  v-for="(p,index) in SearchContent" :key="p.id">
                    <div class="imgtop" style="position: relative;overflow:hidden;" >
                        <img class="imgcss" :src="getServerUrl()+'/1image/'+ p.picurl" alt="" >
                    </div>
                    <p class="font16 text-ellipsis tit">{{p.title}}</p>
                </li>
            </ul>
  
        <!-- 显示相关内容的导航栏-->
            <div class="xiangguan">
                <span >相关内容</span>
                <span @click="changeonechange()" class="hyh" >换一换</span>
        
            </div>
        <!-- 具体显示相关内容 -->
       
            <ul class="ccc" v-cloak style="display: flex;overflow:hidden">
                <li class="myli" id="search" v-for="(p,index) in RandomDisplay" :key="p.id"  @click="MyContenttwo(index)">
                    <div class="imgtop" style="position: relative;">
                        <img class="imgcss" :src="getServerUrl()+'/1image/'+ p.picurl" alt="">
                    </div>
                    <p class="font16 text-ellipsis tit">{{p.title}}</p>
                </li>
            </ul>
        </div>
       
    </div>

</div>
</template>

<script scoped>
import { Search } from '@element-plus/icons-vue'
import axios from '@/Api/axios'
export default {
    methods:{
        getServerUrl(){
                return axios.getServerUrl();
        },
        Historysearch(item){
            axios.post("PC/project/getResourcesByTitle",{"text":item}).then(res=>{
             
                    this.SearchContent=[];
                if (res.data.data!="暂无搜索结果")
                {
                    let a=res.data.data;
                    for (let i=0;i<a.length;i++){
                        if (a[i].picurl==null || a[i].picurl==[]){
                            a[i].picurl='mr.png'
                        }
                    }
                    this.SearchContent=a;
                }
            })
        },
        // 清空历史
        EmptyHistory(){
            localStorage.removeItem('historyList');
            this.historyList = [];
        },
        MyContentone(e){
            axios.get("PC/project/"+this.SearchContent[e].id).then(res=>{

                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:1}})

                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:1}})
                }
                
               
            })
            
        },
        MyContenttwo(e){
           
            axios.get("PC/project/"+this.RandomDisplay[e].id).then(res=>{
          
                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:1}})

                }
                else
                {
                   this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:1,}})
                }
            })
        },
        // 搜索
        SearchBy(){
            if (this.SearchByKeyword!="")
            {
                axios.post("PC/project/getResourcesByTitle", { "text":this.SearchByKeyword}).then(res=>{
                  
                    this.SearchContent=[];
                if (res.data.data!="暂无搜索结果")
                {
                    let a=res.data.data;
                    for (let i=0;i<a.length;i++)
                    {
                        if (a[i].picurl==null || a[i].picurl==[]){
                            a[i].picurl='mr.png'
                        }
                    }
                    this.SearchContent=a;
                }
                
            
          })
            }
          
          if(this.SearchByKeyword!="")
          {
            // 没有搜索记录
            if (this.historyList.length>=12)
            {
                this.historyList.length=11;
            }
            if(!this.historyList.includes(this.SearchByKeyword))
            {
                this.historyList.unshift(this.SearchByKeyword);
                localStorage.setItem("historyList",JSON.stringify(this.historyList))
            }
            else
            {
                let i=this.historyList.indexOf(this.SearchByKeyword);
                this.historyList.splice(i,1)
                this.historyList.unshift(this.SearchByKeyword)
                localStorage.setItem("historyList",JSON.stringify(this.historyList))
            }
          }
        },
        changeonechange(){
            axios.get("PC/project/getRanFive").then(res=>{
                let a=res.data.data;
            if (a!=[] && a!=null && a!="")
            {
                for (let i=0;i<a.length;i++)
                {
                    if (a[i].picurl=="" || a[i].picurl==null)
                    {
                        a[i].picurl="mr.png"
                    }
                }
            }
          
            this.RandomDisplay=a;
          })
        },
    },
    data(){
        return{
            // 绑定搜索框
            SearchByKeyword:"",
            Search:Search,
            // 接受后台数据
            SearchContent:[],
            RandomDisplay:[],
            //历史搜索记录，存本地
            historyList: [], 
            screen:0
        }
    },
    mounted(){
        this.screen=window.screen.availWidth-80
   
        axios.get("PC/project/getRanFive").then(res=>{
            let a=res.data.data;
            if (a!=[] && a!=null && a!="")
            {
                for (let i=0;i<a.length;i++)
                {
                    if (a[i].picurl=="" || a[i].picurl==null)
                    {
                        a[i].picurl="mr.png"
                    }
                }
            }

            this.RandomDisplay=a;
          })
          //如果本地存储的数据historyList有值，直接赋值给data中的historyList
        if (JSON.parse(localStorage.getItem("historyList"))) {
            this.historyList = JSON.parse(localStorage.getItem("historyList"));
            if (this.historyList>12)
            {
                this.historyList.length=12;
            }
        }
        let a=[]
        for (let i=0;i<this.historyList.length;i++)
        {
            if (this.historyList[i]!='' && this.historyList[i]!=null)
            {
                a.push(this.historyList[i])
            }
        }
        this.historyList=a;
     
    }
    
}
</script>

<style scoped>
.bbb {
    margin-top: 48px;
}
.bottom .top {
    display: block;
}
.tit {
    padding: 0 24px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
}
*
{
margin: 0px;
padding: 0px;
}

.serchinput {
    /* border-color: 2px #002580; */
    position: relative;
    outline: none;
    border: none;
    transition: 500ms;
    height: 36px;
    padding-left:44px;
    background-color: #f2f2f2;
    border-radius: 18px;
}
html,body,#app {
    margin: 0px;
    padding: 0px;
    height: 100%;
    background-color: #e6e6e6;
}
/* 平板 */
@media  screen and (max-width:1200px){
    .top{
        /* background-color: pink; */
        width: 980px;
        height: 100%;
        margin:  0 auto;
        display: flex;
    }
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        background-color: #fff;
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .imgtop {
        position: relative;
        overflow: hidden;
        width: 224px;
        height: 126px;
    }
    .myli:nth-child(n+5){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
    .ccc .myli:nth-child(n+5) {
        display: none;
    }
    .serchwidth {
        width: 360px;
    }   
}
/* pc */
@media  screen and (min-width:1201px){
    .top{
        width: 1366px;
        height: 100%;
        margin:  0 auto;
        /* background-color: #00b2a2; */
        display: flex;
    }
    /* .whatfind{
        display: inline-block;
        width: 224px;
        height: 150px;
        background: white;
        margin-right:24px;
        margin-top: 20px;
        border-radius: 8px;
    }
    .whatfind:nth-child(5n){
    margin-right:0px;
    } */
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
    }
    .myli:nth-child(5n){
        margin-right: 0;
    }
    .myli:nth-child(n+6){
        margin-top: 31.5px;
    }
    .serchwidth {
        width: 612px;
    }   
}
.history {
    width: 100%;
    height: 188px;
    background-color: white;
}
#left
{
    width: 276px;
    height: 100%;
    padding: 24px 0;
    box-sizing: border-box;
    overflow: hidden;
    /* background-color: pink; */
}
.lefttop {
    display: flex;
    justify-content: space-between;
    /* height: 20px; */
    margin-bottom: 24px;
}
.xiangguan {
    margin-top: 48px;
    margin-bottom: 24px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
}
.lishi:hover{
    color: #002580;
}
.hyh:hover{
    color: #002580;
    cursor: pointer;

}

.seach {
    flex: 1;
}
.seach input:focus{
border: 1px solid #002580;
transition: 500ms;

}
.seach img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    left: 12px;
    z-index: 2;
}
.SearchHistory{
    width: 100%;
    background-color: #fff;
}
.SearchHistory li
{
    display: inline-block;
    width: 60px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;    
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 0 8px;
    margin-left: 12px;
    text-align: center;
}
.SearchHistory li:nth-child(1) {
    margin-left: 0;
}
.SearchHistory li:nth-child(4n+1) {
    margin-left: 0;
}
.SearchHistory li:nth-child(n+5) {
    margin-top: 10px;
}


</style>