<!-- 已读消息页面 -->
<template>
      <div class="titbox">
          <span class="font20 font25 move">已读消息</span>
          <img class="del-img topmid move" src="../../assets/index/del.png" alt="" @click="clear()" >
      </div>


      <div class="messbox bd" v-for="(p,index) in ReadTheInformation" :key="p.id">
            <img src="../../assets/message/unread.png" alt="">
            <span  class="EachContent font16 font25">{{ReadTheInformation[index].name}}</span>
            <span  class="time font12 font80" >{{ReadTheInformation[index].time}}</span><br/>
          <span class="font16" style="padding: 24px 24px 24px 24px;display: block;">{{begin[index]}} 
            <span v-if="ReadTheInformation[index].type==0">{{ReadTheInformation[index].p_type}}</span> 
            <span class="font16 font25" style="font-weight: 700">{{ReadTheInformation[index].p_name}}</span>
            {{end[index]}}
          </span>
          <el-checkbox class="check" label="" v-model="checkList[index]" :class="[isshow==true?'www':'w']"></el-checkbox>
          
          <div v-if="ReadTheInformation[index].type==1 ||ReadTheInformation[index].type==3">
                <div style="padding-bottom: 24px;text-align: right;padding-right: 24px;" v-if="ReadTheInformation[index].operation==1 || ReadTheInformation[index].operation==2">
                  <span  class="choose font16 font80 move" v-if="ReadTheInformation[index].operation==1">已接受</span>
                  <span  class="choose font16 font80 move" v-if="ReadTheInformation[index].operation==2">已拒绝</span>
                </div>
          </div>     
      </div> 


  <div class="bott"  :class="[isshow==true?'ww':'wwww']">
    <button class="btnblue font16"  @click="queren()" style="margin-right: 64px;" >确认</button>
    <button class="btntrs font16 font00"   @click="quanxuan()" style="margin-right: 64px;">全选</button> 
    <button class="btntrs font16 font00"   @click="quxiao()">取消</button>     
  </div>


<!-- 确认删除历史记录的dialog -->
  <el-dialog
  style="border-radius: 12px;position: relative;height: 196px;"
  v-model="dialogVisible"
  width="420px"
  :show-close="false"
  align-center
  class="delmessage"
  :before-close="handleClose">
  <p class="font00 font18 leftmid">确定删除已读消息吗？</p>
  <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
      <button class="btnblue move" style="margin-right: 48px;"  @click="delword()">确认</button>
      <button class="btnwhite move" @click="dialogVisible = false">取消</button>
  </div>
  
  <!-- <span  style="position: absolute;top: 100px;left: 37%;">
    <el-button type="primary" @click="delword()" style="border: 1px solid black; border-radius: 20px;color: black;background-color:white;">确 定</el-button>
    <el-button @click="dialogVisible = false" style="border: 1px solid black; border-radius: 20px;background-color:#002580;color:azure;">取 消</el-button>

  </span> -->
</el-dialog>
</template>

<script scoped>
import axios from '@/Api/axios'
import { Flag } from '@element-plus/icons-vue'
export default {
  data(){
    return{
      ReadTheInformation:[],
      begin:[],
      end:[],
      // 选中要删除的消息
      checkList:[],
      isshow:false,
      dialogVisible:false
    }
  },
  methods:{
    delword(){
  
             let m_id=[]
            
     
        for (let i=0;i<this.checkList.length;i++)
        {
          if (this.checkList[i]==true)
          {
              m_id.push(this.ReadTheInformation[i].id)
          }
        }
 
        axios.post("PC/message/remMsgs",m_id).then(res=>{
            if (res.data.data==true)
            {
              this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.loadpost();
            }
            else
            {
              this.$message({
          message: '删除失败',
          type: 'warning'
        });
            }
        }) 
        this.dialogVisible=false
    },
    queren(){
     this.dialogVisible=true
     
    },
    quanxuan(){
      if (this.checkList!=[] && this.checkList!=null)
      {
        if (this.checkList[0]==false)
        {
          for (let i=0;i<this.checkList.length;i++)
            {
              this.checkList[i]=true
          }
        }
        else
        {
          for (let i=0;i<this.checkList.length;i++)
            {
              this.checkList[i]=false
                      }
        }
      }
    },
    quxiaoquanxuan(){
      for (let i=0;i<this.checkList.length;i++)
      {
        this.checkList[i]=false
      }
    },
    quxiao(){
      this.quxiaoquanxuan()
        this.isshow=false;
        
    },
      clear(){
        this.quxiaoquanxuan()
        if (this.isshow==true)
        {
          this.isshow=false;
        }
        else

        {
          this.isshow=true;
        }
      },
      loadpost()
      {
        axios.get("PC/message/isread/" + this.$cookies.get("selectValue").id).then(res => {
            this.ReadTheInformation = res.data.data;
            for (let i=0;i<this.ReadTheInformation.length;i++)
            {
              this.checkList[i]=false
            }
            let n=-1;
            for (let i=0;i<this.ReadTheInformation.length;i++)
            {

              if (this.ReadTheInformation[i].time!="" && this.ReadTheInformation[i].time!=null)
              {
                let t=this.ReadTheInformation[i].time;
                
                let a=t.substring(5,10);
                let b=t.substring(11,16);
                this.ReadTheInformation[i].time=a+" "+b;
              }
              n=n+1;
          
              switch(this.ReadTheInformation[i].type)
              {
                
                case 0:
                  {
                      this.begin[n]="恭喜您的";
                      this.end[n]="被推至首页";
                      break;
                  }
                case 1:
                  {
                    
                    this.begin[n]="邀请您加入";
                      this.end[n]="";
                      break;
                  }
                case 2:
                  {
                  
                    this.begin[n]="恭喜您注册成功";
                      this.end[n]=""
                    break;
                  }
                  case 3:
                  {
                    
                    this.begin[n]="申请加入您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 4:
                  {
                    
                      this.begin[n]="";
                      this.end[n]="您申请使用了资源"
                    break;
                  }
                  case 5:
                  {
                    
                    this.begin[n]="恭喜您发布";
                      this.end[n]="成功";
                    break;
                  }
                  case 6:
                  {
                    this.begin[n]="评论了您的";
                    this.end[n]="活动"
                    break;
                  }
                  case 7:
                  {
                    
                    this.begin[n]="您的";
                      this.end[n]="活动被置顶";
                    break;
                  }
                  case 8:
                  {
                    
                    this.begin[n]="通过了您对";
                      this.end[n]="资源的申请"
                    break;
                  }
                  case 9:
                  {
                    
                    this.begin[n]="驳回了您对";
                      this.end[n]="的申请,并回复了您"+this.ReadTheInformation[i].reply
                      
                    break;
                  }
                  case 13:
                    {
                      this.begin[n]="接受了你";
                      this.end[n]="的邀请"
                      break;
                    }
                    case 14:
                      {
                        this.begin[n]="拒绝了你";
                        this.end[n]="的邀请"
                        break;
                      }
                      case 12:
                      {
                        this.begin[n]="您的";
                        this.end[n]="活动被取消置顶";
                        break;
                      } 
                      case 10:
                        {
                          this.begin[n]="通过了您加入";
                          this.end[n]="活动的申请";
                           break;
                        }
                        case 11:
                        {
                          this.begin[n]="拒绝您加入";
                          this.end[n]="活动的申请";
                         break;
                        }
                        case 15:
                          {
                            this.begin[n]="将您从";
                            this.end[n]="移除了";
                            break;
                          }
                          case 16:{
                            this.begin[n]="退出了您的";
                            this.end[n]="活动";
                            break;
                          }
                 
              }
            }
        });
      }
  },
  mounted(){
      this.loadpost()
  }
}
</script>

<style scoped lang="scss">
.delmessage p {
    text-align: center;
}
.delmessage .bbbtn {
    bottom: 24px;
}
.delmessage .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
/deep/ .el-checkbox {
    --el-checkbox-checked-icon-color: #002580!;
    --el-checkbox-checked-bg-color: #fff;
    --el-checkbox-checked-input-border-color: #002580;
    --el-checkbox-input-border-color-hover: #002580;
}
/deep/ .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #808080;
}
/deep/ .el-checkbox__inner::after {
  border: 2px solid #002580;
  border-left: 0;
  border-top: 0;
  top: 20%;
  left: 40%;
  // transform: translateY(-50%);
  transform: rotate(45deg) scaleY(0) translateY(-50%) translateX(-50%);
}
.choose {
  display: inline-block;
  width: 50px;
  height: 24px;
  line-height: 15px;
  border-bottom: 2px solid #808080;
}
.el-checkbox-group {
  font-size: 16px;
  line-height: 16px;
}
.titbox {
  display: flex;
  justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-radius: 9px;
    height: 68px;
    line-height: 68px;
    padding: 0 22px 0 24px;
    box-sizing: border-box;

}
.messbox {
  position: relative;
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  margin-top: 24px;
}
.messbox img {
  width: 16px;
  height: 20px;
  vertical-align: bottom;
}
.del-img {
  width: 44px;
  height: 44px;
}
.time {
  margin-left: 24px;
}
.check {
position: absolute;
top: 24px;
right: 38px;
}
.EachContent {
  margin-left: 4px;
}
.bott {
  margin: 64px 0 0;
  text-align: center;

}
.bott button {
  width: 128px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  border: none;
}
.leftb {
  background-color: #002580;
  color: #fff;

}
.midb {
  border: 2px solid #002580!important;
  background-color: #fff;
  margin: 0 64px;
}
.rightb {
  border: 2px solid #002580!important;
  background-color: #fff;
}
.ww{
  display: block;
}
.wwww{
    display: none;
}
.w{
  display: none;
}
.www{
    display: block;
}
</style>