<template>

  <div class="mw-wrapper">
    <span class="mw-btn" @click="uploadPhoto">
    
    </span>
    <div class="mw-uploader">
      <div
        class="viewImg"
        :style="'width:' + width + 'px;height:' + height + 'px'"
        v-for="(item, index) in iga" 
        :key="index"
      >
      <!--  v-show="item.showImg"
          :title="item.name" -->
        <img
          :src="item.base64"
          ref="image"
          style="width: 100%"
         
          alt=""
        />
        <div  class="z-close" @click="delPhoto(index)"><img src="../../assets/imgdel.png" alt=""></div>
      </div>
      <div
        class="add-btn"
        v-if="iga.length < limit"
        :style="'width:' + width + 'px;height:' + height + 'px'"
        @click="addPhoto"
      >
        +
      </div>
      <input
        ref="inputFile"
        type="file"
        class="input-file"
        :accept="accept ? accept : 'image/*'"
        @change="selectPhoto"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "imgUploader",
  computed: {
    computediga(){
   
      return this.lga;
    }
  },
  watch:{
      imgUrl:{
        handler(news,old){
   
          this.imgUrl=news;
        },
        deep:true 
      }
      
  },
  
  props: {
    //图片宽度
    width: {
      type: Number,
      default: () => 200,
    },
    //图片高度
    height: {
      type: Number,
      default: () => 200,
    },
    limit: {
      type: Number,
      default: () => 9999,
    },
    accept: {
      type: String,
      default: () => "",
    },
    iga:{
      // type:[],
      default: () => [],
    }
  },
  data() {
    return {
      imgUrl: [],
    };
  },
   
  mounted(){
  },

  methods: {
    uploadPhoto() {
      if (this.imgUrl.length) {
        this.$emit("upload", this.imgUrl);
      } else {
        this.$emit("empty");
      }
    },
    selectPhoto(e) {
       this.imgUrl=this.$props.iga;
      const obj = e.target.files[0];
      if (obj==undefined)
      return
      const reader = new FileReader();
     
      reader.onload = (evt) => {
        
        this.imgUrl.push({
          base64: evt.target.result,
          file: obj,
          showImg: false,
        });

        // 创建image对象
        const image = new Image();
        image.src = evt.target.result;
        //如果宽度小于容器宽度，按照图片本身宽度显示
        setTimeout(() => {
          this.$nextTick(() => {
            if (image.width < this.width) {
              this.$refs.image[this.$refs.image.length - 1].style.width =
                image.width + "px";
            }
          });
          this.imgUrl[this.imgUrl.length - 1]. showImg= true
        , 100});
      };
      
       reader.readAsDataURL(obj);
       
    },
    addPhoto() {
     
      this.$refs.inputFile.click();
    },
    delPhoto(index) {
      this.imgUrl=this.$props.iga;
      this.imgUrl.splice(index, 1);

      this.$refs.inputFile.value = "";
    },
  },
};
</script>
<style lang="less" scoped>
.mw-wrapper {
  text-align: left;
  padding: 0 10px;
  .mw-btn {
    display: inline-block;
    margin-bottom: 12px;
  }
  .mw-uploader {
    display: flex;

    align-items: center;
    flex-wrap: wrap;
    .viewImg {
      position: relative;
      
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #f7f0f0;
      box-sizing: border-box;
      margin-right: 12px;
      margin-bottom: 12px;
      transition: all 0.4s;
      border-radius: 6px;
      background-color: #fff;
      // background-color: red;
      overflow: hidden;
      .z-close {
        display: none;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        right: 8px;
        // bottom: 0;
        // left: 150px;
        // right: 192px;
        z-index: 4;
        // background: rgba(179, 175, 181, 0.32);
        // box-shadow: inset 0 0 26px 5px rgb(179 184 189 / 62%);
        font-size: 26px;
        color: #ff0909;
        cursor: pointer;
        // justify-content: center;
        // align-items: center;
      }
      &:hover {
        .z-close {
          display: flex;
        }
      }
    }
    .add-btn {
      position: relative;
      overflow: hidden;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #d9d9d9;
      // 修改组件颜色
      // background: c0c4cc;
      margin-right: 12px;
      margin-bottom: 12px;
      &:hover {
        border-color: #409eff;
        color: #409eff;
      }
    }
    .input-file {
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;
    }
  }
}
</style>
