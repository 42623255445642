<!-- 个人信息 -->
<template>
  <div style="width:100%;">
    <div id="main">

        <!-- 左上区域 -->
        <div class="upperleftone" >
            <div class="someleft leftwidth upperleft">
              <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl" alt="">
              <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length<=17 " :src="getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt=""><br/>
              <p class="leftmid font16 font00">{{Personalinformation.username}}</p><br/>
              <p class="leftmid font16 font80">{{Personalinformation.sys}}</p>
            </div>
                <!-- 右上区域 -->
            <div class="upperrightone padingleft"  >
                  <p class="font20 font25">{{ContentOfTheColumn}}</p>
                <!-- 右下 -->
                <div class="perlowerright" >
                  <router-view/>  
                </div>
            </div>

        </div>


          <!-- 左下 -->
          <div class="someleft leftwidth overhid" >
            <ul>
              <li @click="personalinformation(1)" class="font16 font00 move" style="position: relative;"  :class="[personlowerleftindex==1?'fontw':'']">
                <!-- <div class="yellowone" v-if="personlowerleftindex==1"></div>个人信息 -->
                <img class="yellowone" src="../assets/index/yel.png" v-if="personlowerleftindex==1" alt="">个人信息
              </li>
              <li @click="mypath(2)" class="font16 font00 move" style="position: relative;" :class="[personlowerleftindex==2?'fontw':'']">
                <!-- <div class="yellowone"  v-if="personlowerleftindex==2"></div>我的轨迹 -->
                <img class="yellowone" src="../assets/index/yel.png" v-if="personlowerleftindex==2" alt="">我的轨迹
              </li>
              <li @click="usingtherecord(3)" class="font16 font00 move" style="position: relative;" :class="[personlowerleftindex==3?'fontw':'']">
                <!-- <div class="yellowone"  v-if="personlowerleftindex==3"></div>使用记录 -->
                <img class="yellowone" src="../assets/index/yel.png" v-if="personlowerleftindex==3" alt="">使用记录
              </li>
            </ul>
          </div>

    </div>
  </div>
  </template>
  

<script>
import router from '@/router'
import { ElMessage } from 'element-plus'
import axios from '@/Api/axios' 
export default {

  data()
    {
      return{
        ContentOfTheColumn:"",
        Personalinformation:"",
        personlowerleftindex:1,
      }
    },
  methods:
  {
    getServerUrl(){
     return axios.getServerUrl();
    },
    personalinformation(e)
    {       
       this.ContentOfTheColumn="个人信息"
       router.replace({
        name:"subpersonalInformati"
       })
       this.personlowerleftindex=e

    },
    mypath(e)
    {
       this.ContentOfTheColumn="我的轨迹"
       router.replace({
        name:"submytrajectory"
       })
       this.personlowerleftindex=e
    },
    usingtherecord(e)
    {
       this.ContentOfTheColumn="使用记录"
       router.replace({
        name:"subusagerecord"
       })
       this.personlowerleftindex=e
    }
  },
  mounted(){
    axios.get("PC/wechat_user/auto/"+this.$cookies.get('selectValue').id).then(res=>{
               
               this.Personalinformation=res.data.data.data; 
           
       })
    let a=this.$router.getRoutes().filter(value=>value.meta.title!=null);
  for (let i=0;i<a.length;i++)
  {
    if (this.$route.path==a[i].path)
    {
      this.ContentOfTheColumn=a[i].meta.title
      if (this.ContentOfTheColumn=="个人信息")
      {
        this.personlowerleftindex=1;
      }
      else if (this.ContentOfTheColumn=="我的轨迹")
      {
        this.personlowerleftindex=2;
      }
      else
      {
        this.personlowerleftindex=3;
      }
    }
  }
  }
}
</script>

<style scoped>
    li:hover {
        background-color: rgb(236, 245, 255);
        transition: .3s;
    }

.upperleftone {
  display: flex;
}
.upperleftone .upperrightone {
  flex: 1;
  height: 48px;
  /* background-color: #00b2a2; */
}
.upperrightone p {
  background-color: #fff;
  border-radius: 9px;
  height: 68px;
  line-height: 68px;
  padding-left: 24px;
  margin: 24px 0;
}
.someleft {
  border-radius: 9px;
  overflow: hidden;
  margin-top: 24px;
  background-color: #fff;
}
.someleft ul {
  border-radius: 9px;
  overflow: hidden;
}
.someleft li {
  padding: 24px;
}
      .upperleft {    
        padding-top: 36px;
        padding-bottom: 36px;
    }
    
    .upperleft img {

    width: 84px;
    height: 84px;
    border-radius: 50%;
    /* background-color: red; */
    }
    .upperleft p {
    display: inline-block;
    }
    .upperleft :nth-child(3){
    margin-top:24px ;
    /* background-color: red; */
    }
    .upperleft :nth-child(5){
    margin-top:6px ;
    }
    .personlowerleft {
      background-color: #fff!important;
      border-radius: 9px;
    }
    .perlowerright {
      /* background-color: pink; */
      height: 100px;
    }
       /* pc */
       @media (min-width: 1201px) {
          #main {
            width: 1366px;
            margin: 0 auto;
            /* background-color: pink; */
          }
          .leftwidth {
            width: 240px;
          }
          .padingleft {
            padding-left: 39.5px;
          }
       }
        /* 平板 */
      @media (max-width: 1200px) {
          #main {
            width: 980px;
            margin: 0 auto;
          }
          .leftwidth {
            width: 232px;
          }
          .padingleft {
            padding-left: 20px;
          }
      }
      .yellowone {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
/* .fontw {
  font-weight: 700;
}
    .yellowone {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 6px;
        border-radius: 3px ;
        background-color: #fed71a;
        font-weight: 700;
    }
      .personlowerleft
    {
        position: absolute;
        width:250px;
        min-height: 200px;
        position: absolute;
        left: 50px;
        top:370px;
    }
    .personlowerleft ul li {
      position: relative;
      height: 50px;
      line-height: 50px;
      text-align: left;

      padding: 0 20px;

      font-size: 15px;
    }
    .perlowerright
    {
        border-radius: 10px;
        width:1150px;
        position: absolute;
        left: 330px;
        top: 210px;

        min-height: 50px;
    }
    .sameone
    {
        background-color: white;
        border-radius: 10px;
    }
    .upperrightone
    {
        width: 1150px;
        height: 80px;
        position: absolute;
        left: 330px;
        top:100px;
        
    }
    .upperleftone
    {
        position: absolute;
        left: 50px;
        top: 100px;
        width: 250px;
        height: 250px;
       
    } */

    

</style>
