<!-- 活动预览页 -->
<template>
 
  <div class="body" id="top">
     <!-- 紫色块 -->
     <div class="res-top">
      <p class="top-find main font14 font80">
          <span>我的</span> 
          <img src="../../assets/ResourceDetails/arrows.png" alt="图片不见啦">
          <span >新建</span>
          <img src="../../assets/ResourceDetails/arrows.png" alt="图片不见啦">
          活动预览
      </p>
      <div class="main"  style="justify-content: center; box-sizing: border-box; padding: 0 96px;margin: 0 auto;display: flex;flex-wrap: wrap;align-items: center;">
          <h1 class="font30 font00 widthh1" style="font-weight: 700;">
            {{this.$store.state.activeruleForm.name}}
          </h1>
          <button class="bt" style="background-color: #8bc34a;" @click="backup()" >预览返回</button> 
      </div>
     
     
      <ul  class="resources-firstul font14 font80" style="list-style: none;">
          <li>{{time}}</li>
          <span>|</span>
          <li>活动 / {{this.$store.state.activeruleForm.resource}}</li>
          <span>|</span>
          <li>
              <img src="../../assets/ResourceDetails/collect.png" alt="">
              0
              <img src="../../assets/ResourceDetails/like.png" alt="">
              0
              <img src="../../assets/ResourceDetails/comment.png" alt="">
              0
          </li>
      </ul>  
  </div>


<!-- 白色大块 -->
<div class="res-mid main">
  <!-- 活动信息 -->
      <div class="mesmessgae" style="padding-top: 48px;" >
          <div class="res-tit">
                  <img src="../../assets/ResourceDetails/message-a.png" alt="">
                  <span class="font28 font25 pleft">活动信息</span>
          </div>
          <!-- <span style="position: relative;left: -580px;font-size: 25px;">活动信息</span> -->
          <ul class="resources-secondul font00"  style="list-style: none;font-weight: 700;letter-spacing:1.5px!important;line-height: 32px!important;">
              <li v-if="(starttime!='' || endtime!='')">
                <p></p><div class="lefttit font20">活动时间:</div>
                <span class="font18 font00" style="font-weight: 400;">{{starttime}}~{{endtime}}</span>
              </li>
              <li v-if="this.$store.state.activeruleForm.activitySite!=''">
                <p></p><div class="lefttit font20">活动地点:</div>
                <span class="font18 font00" style="font-weight: 400;">{{this.$store.state.activeruleForm.activitySite}}</span>
              </li>
              <li v-if="this.$store.state.activeruleForm.resource!=''"  >
                <p></p><div class="lefttit font20">活动类型:</div>
                <span class="font18 font00" style="font-weight: 400;">{{this.$store.state.activeruleForm.resource}}</span>
              </li>
              <li v-for="p in OtherInformation" :key="p.id"  >
                <p></p><div class="lefttit font20">{{p.name}}:</div>
                <span class="font18 font00" style="font-weight: 400;">{{p.content}}</span>
              </li>
          </ul>
      </div>
      <!-- 活动详情 -->
      <div style="padding-top: 96px; padding-bottom: 96px;">
          <!-- <span style="display: block;padding-left: 5%; font-size: 25px;width:100px;">活动详情</span> -->
          <div class="res-tit">
                  <img src="../../assets/EventDetails/detail-a.png" alt="">
                  <span class="font28 font25 pleft">活动详情</span>
          </div>
        <div id="text" style="text-align: center; list-style: inside;">

        </div>
          
      </div>
      <!-- 活动成员 -->
      <div style="padding-bottom: 96px;">
          <!-- <span style="display: block;padding-left: 135px; font-size: 25px;width:100px;">活动成员</span> -->
          <div class="res-tit">
                  <img src="../../assets/EventDetails/member-a.png" alt="">
                  <span class="font28 font25 pleft">活动成员</span>
          </div>
          <ul class="resources-secondul2" style="list-style: none;">
              <li style="padding-left: 40px;">
                  <p></p><span class="lefttit font20 fontweight">组织者:</span>
                  <span class="font18" style="font-weight: 400;font-size: 18px;">{{this.$cookies.get("selectValue").username}}
                    <span style="margin-left:24px;font-weight: 400;">{{this.$cookies.get("selectValue").sys}}</span>
                  </span>
                  <!-- <ul class="Eventdetails-third-one">
                      <li>{{this.$cookies.get("selectValue").username}}
                        <span>{{this.$cookies.get("selectValue").sys}}</span>
                      </li>
                  </ul> -->
              </li>
            
              <li style="padding-left: 40px;">
                    <span style="font-weight: 700;display:flex;margin-top: 36px;">
                        <p></p><div class="lefttit font20">成员:</div>
                        <span class="font18 font80" >预览中，无法查看成员信息</span>
                        <!-- <ul class="Eventdetails-third-two " style="flex:1;" >
                            <li style="font-weight: 400;" class="font18" v-for="(p) in number" :key="p.id">{{p}}</li>

                        </ul> -->
                      </span>      
                </li>
          </ul>
      </div>
</div>

<!-- <footers></footers> -->
</div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import footers from "@/components/footer.vue"
import axios from "axios"
import router from '@/router'
export default {
  components:{
      footers,
  },
  data(){
    return{
      // 当前日期
        time:"",
      // 起始时间
      starttime:"",
      // 结束时间
      endtime:"",
      // 其它信息
      OtherInformation:[],
      number:[]
    }
  },
  mounted(){
   
        document.querySelector("#top").scrollIntoView(true);
        //    let aaaaaa= this.duibi("2023-06-06 00:00:00","2023-06-01 00:00:00")
        // let aaaaaa= this.duibi("2023-06-01 00:00:00","2023-06-06 00:00:00")
            // console.log(aaaaaa)
      let a=new Date();
      let year=a.getFullYear();
      let month=a.getMonth()+1;
      let data=a.getDate();
   
      this.time=year+"-"+month+"-"+data
  if (this.$store.state.activeruleForm.inviteMembers!="" && this.$store.state.activeruleForm.inviteMembers!=null)
  {
    this.number= this.$store.state.activeruleForm.inviteMembers.split(', ')
  }

      if (this.$store.state.activeruleForm.startingTime!="" && this.$store.state.activeruleForm.endOfTime!="" && this.$store.state.activeruleForm.startingTime!=null && this.$store.state.activeruleForm.endOfTime!=null)
      {
        this.starttime=this.$store.state.activeruleForm.startingTime.substring(5,this.$store.state.activeruleForm.startingTime.length);
      this.endtime=this.$store.state.activeruleForm.endOfTime.substring(5,this.$store.state.activeruleForm.endOfTime.length);
      }

      document.getElementById("text").innerHTML=this.$store.state.activeruleForm.richtext;
    
    
      for (let i=0;i<this.$store.state.activeruleForm.information.length;i++)
      {
        if (this.$store.state.activeruleForm.information[i].content!="" &&  this.$store.state.activeruleForm.information[i].name!="")
        {
            this.OtherInformation.push(this.$store.state.activeruleForm.information[i])
        }
      }

  },
  methods:{
    backup(){
      window.history.back();
    },
    // duibi(a,b)
    // {
    //   var arr = a.split("-");
    //   var arr2=arr[2].split(" ")
    //   var arr3=arr2[1].split(":")
  
    // var starttime = new Date(arr[0], arr[1], arr2[0],arr3[0],arr3[1],arr3[2]);
    // var starttimes = starttime.getTime();
 
    // var arrs = b.split("-");
    // var arr2s=arrs[2].split(" ")
    //   var arr3s=arr2s[1].split(":")
  
      
    // var lktime = new Date(arrs[0], arrs[1],  arr2s[0],arr3s[0],arr3s[1],arr3s[2]);
    // var lktimes = lktime.getTime();

    // if (starttimes >= lktimes) {
    //   return 1;
    // }
    // else{
    //   return 2;
    // }

    // },
  }
}
</script>

<style scoped>
.tit {
    padding: 0 24px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
}

.mes {
    display: none;
    position: absolute;
    bottom:80px;
    right: 60px;
    width: 47px!important;
    height: 21px!important;
    padding-left: 10px;
    box-sizing: border-box;
    /* text-align: center; */
    line-height: 22px;;
    /* background-color: pink; */
    background-image: url(../../assets/abcd.png) ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
}
.meshover {
    display: block;
    position: absolute;
    bottom:80px;
    right: 60px;
    width: 47px!important;
    height: 21px!important;
    padding-left: 10px;
    box-sizing: border-box;
    /* text-align: center; */
    line-height: 22px;
    /* background-color: pink; */
    background-image: url(../../assets/abcd.png) ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
}
.content {
    width:100%;
}
.read-id {
    width: 90%;
}
.rightbox {
    z-index: 10086;
    position:fixed;
    bottom:90px;
    right: 60px;
    /* background-color: pink; */
}
.rightbox img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-top: 16px;
    /* background-color: pink; */
}
.Eventdetails-third-two li{
    display: inline-block;
    width: 33.3%;
}
.Eventdetails-third-two li:nth-child(n+4) {
    margin-top: 20px;
}
/* PC */
@media screen and (min-width:1201px) {
    .main {
        width: 1366px;
        /* background-color: #00b2a2; */
    }
    .widthh1 {
        max-width: 1032px;
    }
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
    }
        .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align:middle;
    }
    .myli:nth-child(5n){
        margin-right: 0;
    }
    .myli:nth-child(n+6){
        margin-top: 31.5px;
    }
}
/* 平板 */
@media screen and (max-width:1200px) {
    .main {
        width: 980px;
        /* background-color: pink; */
    }
    .widthh1 {
        max-width: 646px;
    }
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        background-color: #fff;
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
    }
    .myli:nth-child(n+5){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
    .ccc .myli:nth-child(n+5) {
        display: none;
    }
}
.box {
    padding-top: 48px;
}
.body {
    background-color: #fff;
}
.main {
    margin: 0 auto;
}
.res-top {
    /* height: 194px; */
    background-color: #eef3ff;
}
.res-top .top-find {
    padding-top:24px;
    padding-left: 48px;
    box-sizing: border-box;
}

.res-top .top-find img {
    margin: 0 13px;
}
.res-top h1 {
    /* flex: 1; */
    box-sizing: border-box;
    font-weight: 400;
    text-align: center;
    /* background-color: pink; */
    word-wrap: break-word;
}
.res-top .resources-firstul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 36px 0 48px 0; */
    padding: 36px 0 48px 0;
    /* background-color: red; */
}
.res-top .resources-firstul span {
    margin: 0 16px;
}
.res-top .resources-firstul p {
    display: inline-block;
    margin: 0 6px;
}
.res-top .resources-firstul img {
    vertical-align: top;
    width: 14px;
    height: 13px;
}
.resmessgae {
    padding-top: 48px;
}
.res-tit {
    display: flex;
    margin-bottom: 36px;
    
}
.res-tit span {
    margin-left: 12px;
    line-height: 35px;
}
.res-tit img{
    vertical-align: bottom;
    width: 28px;
    height: 30px;
}
.res-tit .pleft {
    margin-left: 12px;
}
.resources-secondul {
    /* margin-top: 48px;
    margin-bottom: 96px; */
    /* padding: 0 0 96px 0; */
    /* background-color: red; */
}
.resources-secondul2 li{
    position: relative;
    padding: 0 0;
    box-sizing: border-box;
    
}
.resources-secondul2 p{
    position: absolute;
    top: 10px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    /* margin-right: 24px; */
}




.resources-secondul p {
    position: absolute;
    top: 14px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    /* margin-right: 24px; */

}
.resources-secondul li {
    position: relative;
    padding: 0 40px;
    box-sizing:border-box;
    /* background-color: pink; */
    /* background: red; */
    display: flex;
}
.resources-secondul li:nth-child(n+1) {
    margin-top:36px ;
}

.resources-secondul li span {
    /* margin-left: 24px; */
    display: inline-block;
    background-color: #fff;
    padding: 0 0 0 24px;
    box-sizing: border-box;
    flex: 1;
}
.lefttit {
    display: inline-block;
    width: 164px;
    /* background-color: pink; */
    padding-left: 32px;
}
.vvv {
    width: 844px;
    height: 485px;
    margin: 0 auto;
    overflow: hidden;
}
video {
    width: 844px;
    height: 485px;
}
.dian {
    position: absolute;
    top: 14px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    margin-right: 24px;
}
.resources-third {
    padding: 0 40px;
    position: relative;
}
.resources-third span {
    padding-left: 24px;

    /* width: 196px;
    display: inline-block;
    padding-left: 32px;
    box-sizing: border-box;
    background-color: pink; */
}
.bt {
    width: 118px;
    height: 40px;
    border-radius: 20px;
    /* display: block; */
    color: #fff;
    outline: none;
    border: none;
    font-size: 17px;
    margin-left: 24px;
    cursor: pointer;
}
.resources-third-one li {
    padding-left: 24px;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;

    /* background-color: red; */

}
.resources-third-one li:nth-child(n+3) {
    margin-top: 12px;
}
.res-bottom {
    background-color: #fff;
}
.toppinglun {
    position: relative;
    display: flex;
    padding: 0 36px 0 36px;
    /* background-color: #00b2a2; */
}
.toppinglun textarea {
    /* padding-left: 12px; */
    padding: 12px;
    box-sizing: border-box;
    flex: 1;
    border-radius: 9px;
    outline: none;
    border: 2px solid #d9d9d9;
    background-color: #f2f2f2;
    resize: none;
}
.res-bottom button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    border: none;
    background-color: #002580;
    color: #fff;
}
.res-bottom img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 28px;
}
.read {
    background-color: #fff;
    padding: 0 36px;
    box-sizing: border-box;
    border-radius: 9px;
}
.read li {
    display: flex;
    padding: 24px 0;
    box-sizing: border-box;
    border-bottom: 2px solid #d9d9d9;
    /* background-color: red; */
}
.read ul li:nth-last-child(1){
    border: none!important;
    /* background-color: pink; */
}
.xiangguan {
    margin-top: 96px;
    margin-bottom: 24px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
}
.hyh:hover{
    color: #002580;
    cursor: pointer;

}
.shoucanglogo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue.png); 
    background-size: contain;
}
.shoucanglogohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue-l.png); 
    background-size: contain;
}
.chuangzuo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue.png); 
    background-size: contain;
}
.chuangzuohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue-l.png); 
    background-size: contain;
}
</style>