<!-- 添加资源 -->
<template>
     <!-- 基本信息 -->
     <div @click.stop="hahaha()">
     <div class="divfirst bd" id="topup">
 
        <h3 class="font18 font25 fontweight title paddingside24">基本信息</h3>
        <el-form :model="ruleForm"  ref="ruleForm" class="demo-ruleForm">
            <el-form-item class="inputlist paddingside24" label="标题" prop="name">
              <span class="import pp">*</span>
              <input type="text"  class="titinput inputquan font14" v-model="ruleForm.name" placeholder="请输入标题">
            </el-form-item>
            <el-form-item class="inputlist paddingside24" label="分类" prop="name">
              <span class="import pp">*</span>
              <div class="inputban" style="position: relative;">
                <input readonly type="text" @click.stop="pp()"  class="titinput inputban font14 move" v-model="ruleForm.resource" placeholder="请选择资源类型">
                <div :class="{'lowimg' : flag,'topimg' : !flag}" ></div>
                
            </div>
            <div class="fufu inputban  fufupo1 height386" :class="{'a' : flag,'aaa' : !flag}" @click.stop="hahaha1()">
                              <input  v-model="resource" type="text" placeholder="请输入具体地址"   >

                              <div class="fufu-ridiocontent">
                                  <el-form-item > 
                                    <el-radio-group class="ridio"  v-model="resource">
                                      <el-radio class="font14" v-for="(p,index) in  backend.resource" :key="index"  :label="p.type" />
                                    </el-radio-group>
                                  </el-form-item>
                              </div>

                              <div class="bottom" >
                                <button class="btn3 btnblue" @click.prevent.stop="queren1()">确认</button>
                                <button class="btn3 btnwhite" @click.prevent.stop="quxiao1()">取消</button>
                              </div>
              </div>
            </el-form-item>

    </el-form>
        
        
        <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="paddingside24">

              <el-form-item  label="标题" prop="name">
                <input @input="biaoti()"  type="text" class="titinput inputquan" v-model="ruleForm.name" placeholder="请输入标题">
              </el-form-item>

            <div class="classify">
              <span class="name">分类</span>
              <div class="long" style="position: relative;">
                <input v-model="ruleForm.resource" disabled type="text"  placeholder="请选择活动类型" class="titinput inputquan"/>
                <div :class="{'topimg' : flag,'lowimg' : !flag}" @click="pp()"></div>
                  <div class="fufu" v-show=flag>
                        <div class="seach">
                            <input  v-model="resource" type="text"  placeholder="请输入活动类型"   >
                        </div>
                        <div class="fufu-content"  style=" flex:5 ;overflow: auto;width: 100%;position: relative;">
                          <el-form-item>
                              <el-radio-group
                                v-model="resource"
                                style="position: absolute; left: -150px; top: 0"
                              >
                                <el-radio v-for="(p,index) in  backend.resource" :key="index" style="position: relative;top: 0;left: 0;display: block;width: 100%;height: 50px;" :label="p.type"/>
                              
                          </el-radio-group>
                        </el-form-item>
                        </div>
                      
                        <div class="bottom" style="flex: 1; display: flex;justify-content: space-around; box-sizing: border-box;  padding-top:15px;">
                          <button style=" width:100px ; height:40px;border-radius:20px;background-color: #002680;color: #fff; border: none;" @click.prevent="queren1()">确认</button>
                          <button style="width:100px ; height:40px;border-radius:20px;border:2px solid #909090; color: #909090;background-color: #fff;" @click.prevent="quxiao1()">取消</button>
                        </div>
                  </div>

                </div>
              </div>

          </el-form> -->
    </div>

  <div  class="divfirst bd margintop12">
    <h3 class="font18 font25 fontweight title paddingside24">上传图片<span class="import" style="font-size: 14px;position: relative;right: -4px;">*</span></h3> 
      <div class="paddingside24">
        <h4 class="margintop28">上传图片</h4>
        
        <imgUploader
        :iga="ruleForm.img1"
        :width="200"
        :height="200"
        :limit="4"
        @empty="emptyShow"
        @upload="uploadVideo"
        @change="img1()"
        ref="img1"
        >
        </imgUploader>
        <p class="font13 font00 fontt">(支持jpg/gif/png格式，RGB模式，大小1920*1080。)</p>

        <h4 class="font16 font00 fonttit">上传封面</h4>
        <imgUploader
        :iga="ruleForm.img2"
          :width="200"
          :height="200"
          :limit="1"
          @empty="emptyShow"
          @upload="uploadVideo"
          @change="img2()"
          ref="img2"
        >
        </imgUploader>
        <p class="font13 font00 fontt">(支持jpg/gif/png格式，RGB模式，大小1920*1080。)</p>
      </div>

    </div>
  <!-- 结束上传图片 -->
   
   <!-- 上传视频 -->
  <div class="divfirst bd margintop12">
          <h3 class="font18 font25 fontweight title paddingside24">上传视频</h3> 
          <div class="paddingside24">
                <h4 class="margintop28">上传视频</h4>  
                  <videoUploader
                  :via="ruleForm.rotationVideo"
                  :width="200"
                  :height="200"
                  :limit="1"
                  @empty="emptyShow"
                  @upload="uploadVideo"
                  @change="vid1()"
                  ref="vid1"
                >
                </videoUploader>

              <p class="font13 font00 fontt">(最多上传1个视频，单个视频不超过500M。)</p>
              <h4 class="font16 font00 fonttit">上传视频封面</h4>
              <imgUploader
                  :iga="ruleForm.img3"
                  :width="200"
                  :height="200"
                  :limit="1"
                  @empty="emptyShow"
                  @upload="uploadVideo"
                  @change="img3()"
                  ref="img3"
                >
                </imgUploader>
                <p class="font13 font00 fontt">(支持jpg/gif/png格式，RGB模式，大小1920*1080。)</p>
          </div>
</div>
  <!-- 结束上传视频 -->


 <!-- 上传教程 -->
 <div class="divfirst bd margintop12">
          <h3 class="font18 font25 fontweight title paddingside24">上传教程</h3> 
        <div class="paddingside24">
          <h4 class="margintop28">上传教程视频</h4>  
            <videoUploader
            :via="ruleForm.video"
            :width="200"
            :height="200"
            :limit="3"
            @empty="emptyShow"
            @upload="uploadVideo"
            @change="vid2()"
            ref="vid2"
          >
          </videoUploader>
          <p class="font13 font00 fontt">(最多上传3个教程视频，单个视频不超过500M。)</p>
        </div>

  </div>
  <!-- 结束上传教程 -->



  <!-- 添加详细信息 -->
  <div class="divfirst bd margintop12">
      <h3 class="font18 font25 fontweight title paddingside24">添加详细信息</h3> 
          
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="paddingside24"
    >
      <el-form-item class="message" label="资源简介" prop="name">
          <textarea maxlength="200"  :rows="row1"  @keyup.stop="xyz1"  class="inputquan font14" v-model="ruleForm.resourceIntroduction" placeholder="请输入资源简介200字以内" ></textarea>
      </el-form-item>
      <div class="messagelist" style="display: flex;" >
          <span>选择资源空间</span>

          <div class="" style="position: relative;" @click.stop="pp2()">
            <span class="import pp">*</span>
            <input v-model="ruleForm.spaceOfChoice" disabled type="text"   placeholder="请选择资源空间" class="titinput inputban "/>
            <!-- <div class="arrows" :class="{ activity: flag }" @click="pp2()"> </div> -->
            <div :class="{'lowimg' : flag2,'topimg' : !flag2}" ></div>
            <div class="fufu inputban fufupo1 height386" :class="{'a' : flag2,'aaa' : !flag2}" @click.stop="hahaha2()">
                              <input v-model="spaceOfChoice" type="text" placeholder="请输入具体地址"   >

                              <div class="fufu-ridiocontent">
                                  <el-form-item > 
                                    <el-radio-group class="ridio"  v-model="spaceOfChoice">
                                      <el-radio class="font14 res"  v-for="(p,index) in backend.SpaceOfChoice" :key="index" :label="p.name" />
                                    </el-radio-group>
                                  </el-form-item>
                              </div>

                              <div class="bottom" >
                                <button class="btn3 btnblue" @click.prevent.stop="queren2()">确认</button>
                                <button class="btn3 btnwhite" @click.prevent.stop="quxiao2()">取消</button>
                              </div>
              </div>
            <!-- <div class="fufu" v-show=flag2 >
                  <div class="seach">
                      <input  v-model="ruleForm.spaceOfChoice" type="text"  placeholder="请输入具体地址"   >
                  </div>
                  <div class="fufu-content">
                    <el-form-item>
                      <el-radio-group
                          v-model="spaceOfChoice"
                        >
                          <el-radio v-for="(p,index) in backend.SpaceOfChoice" :key="index" :label="p.name"/>
                     
                    </el-radio-group>
            </el-form-item>
                  </div>
                
                  <div class="bottom">
                    <button @click.prevent="queren2()">确认</button>
                    <button @click.prevent="quxiao2()">取消</button>
                  </div>
        </div> -->
          </div>
        </div>
        <el-form-item class="message2"  label="是否公开" style="position: relative;" >
                  <span class="import one">*</span>
                  <el-radio-group v-model="ruleForm.open" style="flex-wrap:nowrap">
                      <el-radio label="是" />
                      <el-radio style="margin-left:30px"  label="否" />
                   </el-radio-group>
                </el-form-item>
                <el-form-item label="是否多人使用" style="position: relative;" >
                  <span class="import pp">*</span>
                  <el-radio-group v-model="ruleForm.only">
                      <el-radio label="单人" />
                      <el-radio style="margin-left:14px"  label="多人" />
                   </el-radio-group>
        </el-form-item>
                <div class="useneed margintop28">
       
                    <p style="margin-right:56px;white-space:nowrap">使用须知</p>
                    <div style="flex:1">
                        <el-upload
                                  :action="getServerUrl()+'/admin/project/upload'"
                                  :on-preview="handlePreview"
                                  :on-remove="handleRemove"
                                  :before-remove="beforeRemove"
                                  :on-success="success"
                                  :on-exceed="handleExceed"
                                  :on-change="handchange"
                                  multiple
                                  :limit="1"
                                  :file-list="filelist"
                                  accept=".pdf,.docx,.pptx,.ppt,.doc,.xls,.xlsx"
                              >
                              <span class="font25 move">添加附件</span>   
                        </el-upload>
                        <p style="margin-left:5px" class="font13 font00">(支持pdf、word文件，附件大小不超过20M。)</p>
                        <!-- <el-input class="fontquan margintop12" v-model="ruleForm.notes" placeholder="请输入使用须知" /> -->
                        <!-- <textarea placeholder="请输入使用须知" class=" inputquan2 font14 margintop12" rows="1"></textarea> -->
                        <textarea  v-model="ruleForm.notes" placeholder="请输入使用须知" class=" inputquan font14 margintop12" :rows="row2" @keyup="xyz2" maxlength="500"></textarea>
                    </div>
                </div>

    </el-form>      

  </div>
  <!-- 结束添加详细信息 -->


  <!-- 添加其他信息 -->
  
  <div class="divfirst bd margintop12">
    <h3 class="font18 font25 fontweight title paddingside24" >添加其他信息</h3> 
      <div id="addothers " class="otherlist paddingside24" v-for="(p,index) in ruleForm.information" :key="index">
            <img @click.stop="Reductionother(index)"  class="jian move" src="../../assets/AddinganActivity/jian.png" alt="">
            <div class="ol">
                <div class="otherbox">
                  <p class="marginright12">信息名称{{index+1}}</p>
                  <textarea maxlength="18" :rows="p.leng1" @keyup="xyz3($event,index)" @keyup.backspace="xyz4" style="white-space: pre-wrap;" class="inputban" v-model="p.name" placeholder="请填写信息名称" />
                </div>
                <div class=" margintop28 flexone">
                  <p class="marginright12">信息内容{{index+1}}</p>
                <div class="long">
                  <textarea maxlength="100" class="inputban" @keyup="xyz4($event,index)" :rows="p.leng2" v-model="p.content"  style="white-space: pre-wrap;" placeholder="请填写信息内容" />
                </div>
                
              </div>
            </div>
        </div>

      <div class="margintop24 move paddingside24" >
        <img  @click.stop="addother()" class="jia move" src="../../assets/AddinganActivity/jia.png" alt="">
        <span @click.stop="addother()" class="font25 move">添加更多信息名称/内容</span>
      </div>
  </div>
  <!-- 结束添加其他信息 -->

    <!-- 两个按钮 -->
    <div class="btnbottomres margintop64">
      <el-button class="btn2 btnblue" style="border: none;margin-right: 64px;" type="primary" round @click.stop="add()">添加</el-button> 
      <el-button class="btn2 btntrs" round @click.stop="preview()">预览</el-button>  
    </div>
  <img class="topbtn move" @click.stop="BackToTheTop('#topup')" src="../../assets/EventDetails/top.png" alt="图片不见啦！">
</div>
</template>

<script >
import footers from "@/components/footer.vue"
import {ref} from "vue";
import axios ,{getServerUrl} from '@/Api/axios'
import router from '@/router'
import { ElMessage } from 'element-plus'
export default {
        data(){
            return{
              filelist:[{
                response:{
                  url:"",
                  originName:""
                }
              }],
             
                ruleForm:{
                  // 作者
                  w_id:"",
                  // 标题
                    name:"",
                    // 分类
                    resource:"",
                    // 资源简介
                    resourceIntroduction:"",
                    // 选择资源空间
                    spaceOfChoice:"",
                    // 使用人数
                    only:"",
                    // 是否公开
                    open:"",
                    // 须知文本
                    notes:"",
                    rotationVideo:[],
                    fileurl:"",
                   filename:"",
                  // 其它信息
                  information:[{id:1,name:"",content:"",leng1:1,leng2:1}],
                  img1:[],
                  img2:[],
                  img3:[],
                  video:[],
                },
                ruleFormtwo:{
                  // 作者
                  w_id:"",
                  // 标题
                    name: '',
                    // 分类
                    resource:"",
                    // 资源简介
                    resourceIntroduction:"",
                    // 选择资源空间
                    spaceOfChoice:[],
                    // 使用人数
                    only:"",
                    // 是否公开
                    open:"",
                    // 须知文本
                    notes:"",
                    filename:[],
                    // 视频
                    rotationVideo:[],
                    fileurl:"",
                   filename:"",
                  // 其它信息
                  information:[{id:1,name:"",content:"",leng1:1,leng2:1}],
                  img1:[],
                  img2:[],
                  img3:[],
                  // 教程
                  video:[],
                },
                //动态渲染人数
                usepeoplenum:[],
                peopleindex:-1,//选中人数
                usepeople:false,
                resource:"",
                spaceOfChoice:"",
                flag:true,
                flag2:true,
                backend:{
                  // 分类
                  resource:"",
                   // 选择资源空间
                   SpaceOfChoice:"",
                    // 分类
                  resource2:"",
                   // 选择资源空间
                   SpaceOfChoice2:"",

                },
                rowother:1,
                row1:1,
                row2:1
            }
        },
       
       async mounted(){
      
          this.filelist.length=0
          let leng=window.location.href.indexOf("homepage")-2;
          // 接受后端的分类
          await axios.get("/PC/type/getResourcesType").then(res=>{
              this.backend.resource=res.data.data
              this.backend.resource2=res.data.data
              // title
            })
            // 接受后端的地址
             await axios.get("PC/place").then(res=>{
              this.backend.SpaceOfChoice=res.data.data;
              this.backend.SpaceOfChoice2=res.data.data;
            })
          localStorage.setItem("abcde",'')
          if (JSON.stringify(this.$store.state.resourcesruleForm)!="{}")
           {
            this.filelist=[{
                response:{
                  url:"",
                  originName:""
                }
              }],
                    this.filelist[0].name=this.$store.state.resourcesruleForm.filename
                   this.filelist[0].response.url=this.$store.state.resourcesruleForm.fileurl
                   if (this.filelist[0].name=="" || this.filelist[0].response.url=="")
                   {
                    this.filelist.length=0
                   }
              this.ruleForm= this.$store.state.resourcesruleForm;
              this.row1=this.ruleForm.resourceIntroduction.split("\n").length;
              this.row2=this.ruleForm.notes.split("\n").length;
              // window.location.href.substring(leng+1,window.location.href.length)!="/homepage/activitiesresourcesmain/resourcess"
           }
           else if (window.location.href.indexOf('MyWord')==-1)
           {
           
           
            this.filelist.length=0
              axios.get("PC/project/"+this.$route.query.paramName).then(res=>{
               
                  
                  let a=res.data.data
                  this.filelist=[{
                    name:"",
                response:{
                  url:"",
                  originName:""
                }
              }],
                  this.ruleForm.w_id=this.$cookies.get("selectValue").id;
                  this.ruleForm.name=a.title;
                   this.ruleForm.resource=a.t_type;
                   this.resource=a.t_type
                    this.ruleForm.resourceIntroduction=a.context;
                    this.row1=this.ruleForm.resourceIntroduction.split("\n").length;
                   this.ruleForm.spaceOfilelistfChoice=a.space;
                   this.spaceOfChoice=a.space;
                   this.ruleForm.notes=a.message;
                   this.row2=this.ruleForm.notes.split("\n").length;
                   this.ruleForm.open=a.open==1 ? "是" :"否"
                   this.ruleForm.only=a.only==1 ? "单人" : "多人"
                   this.ruleForm.filename=a.filename;
                   this.ruleForm.fileurl=a.fileurl;
                   this.ruleForm.spaceOfChoice=a.space
                   this.ruleForm.filename=a.filename
                   this.ruleForm.fileurl=a.fileurl
                   this.filelist[0].name=a.filename
                  this.filelist[0].response.originName=a.filename
                   this.filelist[0].response.url=a.fileurl
                   
               })
              //  视频封装
               axios.get("PC/image/"+this.$route.query.paramName).then(res=>{
        
                       let a=res.data.data
                       for (let i=0;i<a.length;i++)
                       {
                        if (a[i].type==2)
                        {
          
                            this.ruleForm.img1.push({base64:this.getServerUrl()+'/1image/'+a[i].url})
                        }
                        else if (a[i].type==1)
                        {
                          this.ruleForm.img2.push({base64:this.getServerUrl()+'/1image/'+a[i].url})
                        }
                        else if (a[i].type==4)
                        {
                          this.ruleForm.img3.push({base64:this.getServerUrl()+'/1image/'+a[i].url})
                        }
                        else if (a[i].type==5)
                        {
                          this.ruleForm.video.push({base64:this.getServerUrl()+'/video/'+a[i].url})
                        }
                        else if (a[i].type==7)
                        {
                          this.ruleForm.rotationVideo.push({base64:this.getServerUrl()+'/video/'+a[i].url})
                        }
                       }                
               })
              //  其它信息
                axios.get("PC/other/"+this.$route.query.paramName).then(res=>{
           
                    let a=res.data.data;
                    
                    this.ruleForm.information=[]
                    for (let i=0;i<res.data.data.length;i++)
                    {
                      let a1=a[i].title.split("\n").length
                    let a2=a[i].context.split("\n").length
                        this.ruleForm.information.push({id:a[i].id,name:a[i].title,content:a[i].context,leng1:a1,leng2:a2})
                    }
                  if (res.data.data.length==0)
                  {
                    this.ruleForm.information.push({id:1,name:"",content:"",leng1:1,leng2:1})
                  }
               })
           }else
           {
            this.ruleForm.information=[]
            this.ruleForm.information.push({id:1,name:"",content:"",leng1:1,leng2:1})
   
           }
         
       
            for (let i=0;i<20;i++)
            {
                this.usepeoplenum.push(i+"个");
                
            }
          
        },
        methods:{
          xyz3(e,index){
      
      if (e.key=="Enter" )
      {
        this.ruleForm.information[index].leng1= this.ruleForm.information[index].leng1+1;
      }
      else if (e.key=="Backspace")
      {
        if (this.ruleForm.information[index].leng1>1)
        {
          this.ruleForm.information[index].leng1-=1;
        }
      }
    },
    xyz4(e,index){
      if (e.key=="Enter")
      {
        this.ruleForm.information[index].leng2+=1;
      }
      else if (e.key=="Backspace")
      {
        if (this.ruleForm.information[index].leng2>1)
        {
          this.ruleForm.information[index].leng2-=1;
        }
      }
    },
          xyz2(e){
      if (e.key=="Enter")
      {
        this.row2=this.row2+1;
      }
      else if (e.key=="Backspace")
      {
        if (this.row2>1)
        {
          this.row2=this.row2-1;
        }
      }
       
    },
          xyz1(e){
      if (e.key=="Enter")
      {
        this.row1=this.row1+1;
      }
      else if (e.key=="Backspace")
      {
        if (this.row1>1)
        {
          this.row1=this.row1-1;
        }
      }
       
    },
          hahaha2(){
            this.flag=true
            this.flag2=false
          },
          hahaha1(){
            this.flag=false
            this.flag2=true
          },
          hahaha(){
              this.flag2=true
              this.flag=true
          },
          BackToTheTop(e){
            document.querySelector(e).scrollIntoView(true);
        },
          biaoti(){
        if (this.ruleForm.name.length>=8)
        {

          this.ruleForm.name=this.ruleForm.name.substring(0,8)
        }
    },

    success(response,file,UploadFiles){
   
      this.ruleForm.fileurl=file.response.url
      this.ruleForm.filename=file.name

    },

 handleRemove(file, fileList){


  this.ruleForm.fileurl=""
  this.ruleForm.filename=""


},
 handleExceed(){
  ElMessage.error("请删除之前的附件再重新上传")
},

 handlePreview(file){

window.open(file.response.url);



},

          // 获得路径
          getServerUrl(){
              return axios.getServerUrl();
          },
          // 预览
          preview(){
              if (this.ruleForm.name=="")
              {
               
                ElMessage({
                    showClose: true,
                   message: '标题不能为空',
                   type: 'warning',
                    })
              }
              else if(this.ruleForm.resource=="")
              {
             
                ElMessage({
                  showClose: true,
                  message: '请选择分类',
                type: 'warning',
                 })
              }
              else if (this.ruleForm.img1=="" || this.ruleForm.img2=="" )
              {
                
                ElMessage({
                    showClose: true,
                    message: '必须上传图片和图片封面',
                    type: 'warning',
                    })
              }
              else if ( this.ruleForm.spaceOfChoice=="")
              {
                
                ElMessage({
                    showClose: true,
                    message: '请选择资源空间',
                    type: 'warning',
                    })
              }
              else if (this.ruleForm.rotationVideo.length!=0 && this.ruleForm.img3=="")
               {
                
                ElMessage({
                    showClose: true,
                    message: '您上传了视频，必须上传视频封面',
                    type: 'warning',
                    }) 
               }
                 else if (this.ruleForm.rotationVideo=="" && this.ruleForm.img3!="")
                 {
               
                     ElMessage({
                    showClose: true,
                    message: '您上传了视频封面，没有上传视频',
                    type: 'warning',
                    }) 
                  }
                  else if (this.ruleForm.only=="")
               {
             
                ElMessage({
                        showClose: true,
                        message: '请选择使用人数',
                        type: 'warning',
                       }) 
               }
               else if (this.ruleForm.open=="")
               {
                
                ElMessage({
                        showClose: true,
                        message: '请选择是否公开',
                        type: 'warning',
                       }) 
               }
              else
              {
               
               
                if (this.ruleForm.video!=[])
                {
                
                  axios.post("PC/project/savepreview",this.ruleForm.video).then(res=>{
                    // this.ruleForm.video=res.data.data;
            
                    this.ruleForm.video=[]
                    for (let i=0;i<res.data.data.length;i++)
                    {
                      // if (res.data.data[i].base64)
                      let b=res.data.data[i].base64.indexOf(".");
                      let c=res.data.data[i].base64.substring(0,b);
                      if (isNaN(c)==true)
                      {
                        this.ruleForm.video.push({base64:res.data.data[i].base64})
                      }
                      else
                      {
                        this.ruleForm.video.push({base64:this.getServerUrl()+ '/video/'+res.data.data[i].base64})
                      }
                       
                    }
                    this.$store.commit("resourcesruleForm",this.ruleForm)
                    router.push({
                   name:"resourcedetailspreview"
                })
                  })
                }
                else
                {
                  
                  this.$store.commit("resourcesruleForm",this.ruleForm)
                    router.push({
                  name:"resourcedetailspreview"
                })
                }
               
              
             
               
              }
              
            
          },
          add(){
            this.ruleForm.w_id=this.$cookies.get("selectValue").id;
          
              if (this.ruleForm.name=="")
              {
                ElMessage({
                    showClose: true,
                   message: '标题不能为空',
                   type: 'warning',
                    })
              }
              else if(this.ruleForm.resource=="")
              {
                ElMessage({
                  showClose: true,
                  message: '请选择分类',
                type: 'warning',
                 })
              }
              else if (this.ruleForm.img1==""|| this.ruleForm.img2=="")
              {
                ElMessage({
                    showClose: true,
                    message: '图片和图片封面不能为空',
                    type: 'warning',
                    })
              }
              
              else if ( this.ruleForm.spaceOfChoice=="")
              {
                ElMessage({
                    showClose: true,
                    message: '请选择资源空间',
                    type: 'warning',
                    })
              }
              else if (this.ruleForm.rotationVideo.length!=0 && this.ruleForm.img3=="")
                {
                        ElMessage({
                            showClose: true,
                           message: '您上传了视频，必须上传视频封面',
                           type: 'warning',
                             }) 
                }
                  else if (this.ruleForm.rotationVideo=="" && this.ruleForm.img3!="")
                {
                    ElMessage({
                        showClose: true,
                        message: '您上传了视频封面，没有上传视频',
                        type: 'warning',
                       }) 
               }
               else if (this.ruleForm.only=="")
               {
                ElMessage({
                        showClose: true,
                        message: '请选择使用人数',
                        type: 'warning',
                       }) 
               }
               else if (this.ruleForm.open=="")
               {
                ElMessage({
                        showClose: true,
                        message: '请选择是否公开',
                        type: 'warning',
                       }) 
               }
              else
              {
                let leng=window.location.href.indexOf("homepage")-2;
                
                if (window.location.href.substring(leng+1,window.location.href.length)!="/homepage/activitiesresourcesmain/resourcess")
                {
                  let aa=JSON.stringify(this.ruleForm)
                  let bb=JSON.parse(aa)
                  this.ruleFormtwo=bb
                  this.ruleFormtwo.id=this.$route.query.paramName
              
                     for (let i=0;i<this.backend.resource2.length;i++)
                        {
                             if (this.backend.resource2[i].type==this.ruleForm.resource)
                  {
                        this.ruleFormtwo.resource=this.backend.resource2[i].id
                  }
                      }
              
                  // 其他信息处理
                    if (this.ruleFormtwo.information[this.ruleFormtwo.information.length-1].name==""|| this.ruleFormtwo.information[this.ruleFormtwo.information.length-1].content=="")
                     {
                    this.ruleFormtwo.information=this.ruleFormtwo.information.slice(0,-1);
                      }
                      this.ruleFormtwo.only=this.ruleForm.only=="单人" ?1:0
                  this.ruleFormtwo.open=this.ruleForm.open=="是"?1:0
                  // 图片格式转换
                  axios.post("PC/project/savepreview",this.ruleFormtwo.video).then(res=>{
                      this.ruleFormtwo.video=res.data.data;
                      axios.post("/PC/project/update",this.ruleFormtwo).then(res=>{
                      
            if (res.data.msg=="修改成功")
            {
              ElMessage({
                 showClose: true,
                message: '修改成功!',
                type: 'success',
               })
               this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:this.ruleFormtwo.id,page:5}})
            }
            else if (res.data.msg=="资源名不能重复")
            {
              ElMessage({
                showClose: true,
                 message: '修改资源名不能重复!',
                type: 'warning',
                })
            }
            else 
            {
              ElMessage({
                showClose: true,
                 message: '修改失败!',
                type: 'warning',
                })
            }
                })

                  })
                  
                
                   
      }
                else
      {
                let aa=JSON.stringify(this.ruleForm)
                let bb=JSON.parse(aa)
                this.ruleFormtwo=bb
                for (let i=0;i<this.backend.resource2.length;i++)
                {
                  if (this.backend.resource2[i].type==this.ruleForm.resource)
                  {
                    this.ruleFormtwo.resource=this.backend.resource2[i].id
                  }
                }
              
                // 其他信息处理
               if (this.ruleFormtwo.information[this.ruleFormtwo.information.length-1].name==""|| this.ruleFormtwo.information[this.ruleFormtwo.information.length-1].content=="")
                {
                    this.ruleFormtwo.information=this.ruleFormtwo.information.slice(0,-1);
                }
                this.ruleFormtwo.only=this.ruleForm.only=="单人" ?1:0
                  this.ruleFormtwo.open=this.ruleForm.open=="是"?1:0
                 axios.post("PC/project/savepreview",this.ruleFormtwo.video).then(res=>{
                      this.ruleFormtwo.video=res.data.data;
                      // this.ruleFormtwo.spaceid=this.$route.query.MyWord
                      axios.post("/PC/project/resource/accept",this.ruleFormtwo).then(res=>{

if (res.data.msg=="发布成功")
{
ElMessage({
   showClose: true,
  message: '发布成功',
  type: 'success',
 })
 this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data,page:5}})
}
else if (res.data.msg=="资源名不能重复")
{
ElMessage({
  showClose: true,
   message: '资源名不能重复!',
  type: 'warning',
  })
}
else 
{
ElMessage({
  showClose: true,
   message: '发布失败!',
  type: 'warning',
  })
}
  })
                    })
              
      }
                   
            }
          },
          img1(){
                let vm=this;
                let b=vm.$refs.img1.imgUrl;
                this.ruleForm.img1=b
         
          },
          img2(){
                let vm=this;
                let b=vm.$refs.img2.imgUrl;
                this.ruleForm.img2=b
             
          },
          vid1(){
            let vm=this;
                let b=vm.$refs.vid1.videoUrl;
                this.ruleForm.rotationVideo=b

          },
          vid2(){
            let vm=this;
                let b=vm.$refs.vid2.videoUrl;
                this.ruleForm.video=b

          },
          img3(){
            let vm=this;
                let b=vm.$refs.img3.imgUrl;
                this.ruleForm.img3=b
         
          },
          
          // 减去其它信息
      Reductionother(e){
      if (e==0 && this.ruleForm.information.length>1)
    {
      this.ruleForm.information.splice(e,1)
    }
    else if (this.ruleForm.information.length>0 && e!=0)
      {
        this.ruleForm.information.splice(e,1)
      }
      
    },
    // 添加其他信息
    addother(){
      let a=this.ruleForm.information.length
      if (this.ruleForm.information[a-1].name!="" && this.ruleForm.information[a-1].content!="")
      {
        // this.rowother++;
        this.ruleForm.information.push({id:this.a,name:"",content:"",leng1:1,leng2:1})
    
      }
      else
      {
        ElMessage({
          showClose: true,
          message: '请先填写上面信息',
          type: 'warning',
          })
      }
      

    },  
            
 
    pp() {
     
       this.flag2=true
        this.flag = !this.flag;
    },
    pp2() {
   
        this.flag=true
        this.flag2 = !this.flag2;
       
    },
    queren1() {
      this.ruleForm.resource = this.resource;
      this.flag = !this.flag;
    },
    quxiao1() {
      this.ruleForm.resource = "";
      this.resource = "";
      this.flag = !this.flag;
    },
    queren2() {
      this.ruleForm.spaceOfChoice = this.spaceOfChoice;
      this.flag2 = !this.flag2;
    },
    quxiao2() {
      this.ruleForm.spaceOfChoice = "";
      this.spaceOfChoice = "";
      this.flag2 = !this.flag2;
    },
    
    usepeople1(){
        this.usepeople=!this.usepeople;
    }
        },
        components:{
            footers
        },
        
}
</script>

<style scoped lang="scss">

 /deep/.res .el-radio__input {
  margin-right: 0!important;
 }
 /deep/.res .el-radio__inner {
  margin-right: 0!important;
 }
 /deep/.el-radio-group::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
 }
 /deep/.res .el-radio-group {
  
  overflow:unset;
 }
.fufupo1 {
  position: absolute;
  top:37px;
  left:0;
}
/deep/ .fufupo1 .el-radio{
   width:100%!important;
   height: 38px!important;;
}

/deep/ .fufupo1 .el-form-item{
  width: 100%;
  height: 100%;
  margin-top: 0;
}
/deep/ .fufupo1 .el-radio-group{
  height: 100%;
  width: 100%;
  display: block;
}
/deep/ .fufupo2 .messagelist span {
  margin-right: 0!important;
}
/deep/ .fufu .el-radio {
  width: 100%;
  height: 38px;
}
.useneed {
  display: flex;
}
// .message2 
.messagelist span {
  margin-right: 24px;
}
/deep/ .mw-wrapper .mw-uploader .add-btn:hover {
  color: #002580!important;
}
/deep/ .viewvideo {
  width: 192px!important;
  height: 108px!important;
}
/deep/ .mw-wrapper .mw-uploader .viewvideo{
  margin-bottom: 0!important;
}
/deep/.el-form-item {
  margin-bottom: 0!important;
}
/deep/ .el-radio{
  height: 0!important;
  color:#000c1c!important;
  --el-radio-input-border-color-hover: #002580!important;
}
/deep/ .el-radio__label {
  padding-left: 12px!important;
}
/deep/ el-form-item__label {
  margin-right: 50px;
}
/deep/ .el-radio__inner::after {
  width: 6px!important;
  height: 6px!important;
  left: 51%!important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color:transparent!important;
  background:#002580!important;
}
/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #000c1c!important;
}
// 日历样式
/deep/ .el-input__prefix {
  display: none!important;
}
 /deep/ .el-input {
   --el-input-hover-border:#d9d9d9!important;
    --el-input-focus-bordertransparent:#d9d9d9!important ;
    --el-input-focus-border: #d9d9d9!important;
    --el-input-focus-border-color: #d9d9d9!important;
    --el-input-border:2px solid #d9d9d9!important;
    --el-border-radius-base: 9px!important;
    --el-input-bg-color: #f2f2f2!important;
    --el-input-border-color: #d9d9d9!important;
    --el-input-border: 2px!important;
 }
 .pp {
  position: absolute;
  top: -5px;
  left:-20px ;
}
.one {
  position: absolute;
  top: -5px;
  left:-53px ;
}
/* pc */

@media (min-width: 1201px) {
    .main {
      margin: 0 auto;
      width: 1366px;
      padding-top:24px ;
    }
    .rightmenu {
      width: 240px!important;
    }
    .paddingright {
      padding-left: 39.5px;
    }
    .inputban {
    width: 324px;
  }
  .inputquan {
    width: 994px;
  }
  .inputquan2 {
    width: 919px;
  }
    // .inputban {
    // width: 324px;
    // }
    // .inputquan {
    // width: 994px;
    // }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 894px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 894px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

 /* 平板 */
 @media (max-width:1200px) {
    .main {
      margin: 0 auto;
      width: 980px;
      padding-top:24px ;
    }
    .rightmenu {
      width: 232px;
    }
    .paddingright {
      padding-left: 20px;
    }
    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__wrapper {
      width: 260px;
      height: 36px;
      line-height: 36px;
    }
    .inputban {
    width: 260px;
  }
  .inputquan {
    width: 636px;
  }
  .inputquan2 {
    width: 555px;
  }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 539px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 539px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  /deep/.mw-wrapper .mw-uploader .viewImg:nth-child(4) {
    margin-top: 28px;
  }
  /deep/.mw-wrapper .mw-uploader .add-btn:nth-child(4) {
    margin-top: 28px!important;
  }
}
.otherlist {
  margin-top:28px;
  display: flex;
  // background-color: pink;
}

.otherlist .otherbox {
  min-height: 36px;
  line-height: 36px;
  display: flex;
}

.otherlist p {
  // display: flex;
  // display: inline-block;
  height: 36px;
  line-height: 36px;
  // background-color: pink;
}
// 图片
/deep/ .add-btn {
  width: 192px!important;
  height: 108px!important;
  background-color: #f2f2f2;
  border: none!important;
  border-radius: 0!important;
  content: ""!important;
}
/deep/.mw-wrapper {
  padding: 0!important;
  // margin-top: 0!important;
}
/deep/.mw-wrapper .mw-uploader .add-btn {
  margin: 0!important;
}
/deep/.mw-wrapper .mw-btn {
  display: none!important;
}
/deep/.mw-wrapper .mw-uploader .viewImg {
  // margin: 0!important;
  margin-right: 24px!important;
  margin-bottom: 0!important;
}
/deep/ .viewImg {
  width: 192px!important;
  height: 108px!important;
  background-color: #f2f2f2;
  border: none!important;
  border-radius: 0!important;
  content: ""!important;
}
// 单选框
/deep/ .el-radio {
  margin-right: 0;
}
// 
/deep/.el-form {
    --el-form-label-font-size: 16px;
} 
/deep/.el-form-item__label {
  display: inline;
  padding: 0;
  margin-right: 24px;
  height: 100%;
  line-height: 36px!important;
  color: #000c1c;
}
/deep/ .message .el-form-item__label {
  display: inline;
  padding: 0;
  margin-right: 55px;
  height: 100%;
  line-height: 36px!important;
  color: #000c1c;
}
/deep/ .message2 .el-form-item__label {
  display: inline;
  padding: 0;
  margin-right: 56px;
  height: 100%;
  line-height: 36px!important;
  color: #000c1c;
}
/deep/.el-form-item {
  margin-top: 28px;
}
/deep/.mw-wrapper {
  margin-top: 28px;
}
/deep/.mw-wrapper .mw-uploader .add-btn {
  margin: 0!important;
}
/deep/.mw-wrapper .mw-btn {
  display: none!important;
}
/deep/.mw-wrapper .mw-uploader .viewImg {
  // margin: 0!important;
  margin-right: 24px!important;
  margin-bottom: 0!important;
}
/deep/ .el-form-item {
  margin-bottom: 28px;
}
.divfirst {
  padding-bottom: 28px;
}
// 分类
.classify {
  display: flex;
  align-self: center;
}
.classify .name {
  line-height: 36px;
}
h4 {
  margin-top:28px ;
}
.long {
  display: flex;
}
/* .topa{
  position: relative;
  top: 10px;
  left: -50px;

  width: 1150px;
  height: 70px; 

  border-radius: 20px; 
  background-color: #fff;
}
.topa p {

  left: 10px;
  width: 1150px;
  height: 70px;
  line-height: 70px;
  text-align: left;
  font-size: 20px; 
  padding: 0 20px;
  box-sizing: border-box;
  color:RGB(0,30,102);
  font-weight: 700;
  letter-spacing: 1px;
}
li {
    width: 100%;
    height: 50px;
    text-align: center;
    list-style: none;

}
.stratfufu {
  display: block !important;
}
.peoplenum {
    background-color: #fed71a;
}
.divfirst h3 {
  padding-left: 24px;
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box;
}

.btnbottom :nth-child(1) {
  width: 150px;
  height: 40px;
  margin-top: 50px;
  margin-right: 100px;
  border-radius: 20px;
  letter-spacing: 1px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #002580;
}
.btnbottom :nth-child(2) {
  width: 150px;
  height: 40px;
  margin-left: 40px;
  border-radius: 20px;
  letter-spacing: 1px;
  border: 2px solid #002580;
  outline: none;
  color: #000;
  background-color: #e0e0e0;
}
.divfirst h3 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  color:RGB(0,30,102);
  text-align: left;
  border-bottom: 3px solid #f5f5f5;
}
.titinput {
  width: 1000px;
  height: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  border: 2px solid #e4e2e2;
  background-color: #f2f2f2;
}
.classify {
  width: 450px;
  display: flex;

  margin-left: 58px;
  font-size: 15px; 

  margin-bottom: 20px;
}
.name {
  position: relative;

  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  white-space: nowrap;
}
.titinput2 {
  width: 400px; 
  height: 30px; 
  padding-left: 20px; 
  box-sizing: border-box; 
  outline: none; 
  border-radius: 5px; 
  border: 2px solid #e4e2e2;
  background-color: #f2f2f2;
}
.arrows {
  width: 10px;
  height: 10px;
  border-right: 2px solid #002580;
  border-bottom: 2px solid #002580;
  position: absolute;
  top: 8px;
  right: 15px;
  transform: rotate(45deg);
  transform-origin: bottom bottom;
}

.fufu {
  position: absolute; 
  left:0;
  top:30px; 
  display: flex;
  flex-direction:column; 
  box-sizing:border-box; 
  border: 2px solid #b0b0b0; 
  width: 400px;
  height: 400px; 
  background-color: #fff;
  z-index: 5;
}
.fufu .seach {
  padding: 20px 0;
}
.fufu .seach input {
  width:80% ; 
  height:50px;
  background-color: #f5f5f5;
  border-radius: 25px; 
  outline: none; 
  border: none; 
  padding-left: 20px; 
  box-sizing: border-box;
}
.el-radio {
  margin-right:0!important;
  letter-spacing: 1px;
}
.activity {
  transform: rotate(223deg);
  position: absolute;
  top: 12px;
  right: 15px;
}
.stratfufu {
  display: block !important;
}
.arrows {
  width: 10px;
  height: 10px;
  border-right: 2px solid #002680;
  border-bottom: 2px solid #002680;
  position: absolute;
  top: 10px;
  right: 15px;
  transform: rotate(43deg);
  transform-origin: bottom bottom;
}
.pa {
  padding: 0 40px 0;
}
.plece {
  position: relative;
  display: flex; 
  height: 50px;
  text-align:left ;
  line-height: 50px;
  margin-left: 60px;
  color:RGB(0,30,102);
  font-size: 15px;
}
.plece .plece-input {
  position: relative;
  width: 400px;
  height: 35px;
  line-height: 35px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;
  margin-top: 10px;
  margin-left: 50px;
  border-radius: 3px;
  box-sizing: border-box; 
  color: #808080;
  padding: 0 20px;
  font-size: 14px;
}
.blue {
  position: absolute;
  top: 8px;
  right: 10px; 
  width:8px;
  height: 8px;
  transform: rotate(45deg);
  border-right: 2px solid #002580;
  border-bottom: 2px solid #002580;
}
.fufubox {
  position: absolute;
  left: 140px;
  top: 48px; 
  width: 400px;
  height: 450px; 
  background-color: #fff;
  z-index: 2;
  border: 3px solid #e0e0e0;
  box-sizing: border-box;
  display: none;
}
.fufubox .seach {
  position: relative; 
  width: 100%;
  height: 50px; 
  text-align: center;
}
.fufubox .el-radio {
  transform: translateX(-100px);
}
.seach img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
}
.el-radio {
  position: relative;
  top: 0;
  left: 0;
  display: block; 
  width: 100%;
  height: 50px;
}
.diy {
  display: flex;
  flex-direction: column; 
  margin-top: 200px;
  margin-left: 50px;
} */
</style>