<!-- 活动详情页 -->
<template>
  <div class="body">
    <!-- 紫色块 -->
    <div class="res-top">
      <p class="top-find main font14 font80" id="topup">
        <span
          class="move"
          v-if="this.$route.query.page == 1"
          @click="$router.push({ name: 'findpage' })"
          >发现</span
        >
        <span
          class="move"
          v-else-if="this.$route.query.page == 2"
          @click="$router.push({ path: '/homepage/mypage/myhomescreen' })"
          >我的</span
        >
        <span
          class="move"
          v-else-if="this.$route.query.page == 3"
          @click="$router.push({ name: 'laboratorypage' })"
          >实验室</span
        >
        <span class="move" v-else-if="this.$route.query.page == 5"
          ><span
            @click="$router.push({ path: '/homepage/mypage/myhomescreen' })"
            >我的</span
          ><img
            src="../../assets/ResourceDetails/arrows.png"
            alt="图片不见啦"
          /><span @click="$router.push({ name: 'activitiess' })"
            >新建</span
          ></span
        >
        <span
          class="move"
          v-else-if="this.$route.query.page == 6"
          @click="$router.push({ name: 'submytrajectory' })"
          >我的轨迹</span
        >
        <span class="move" v-else @click="$router.push({ name: 'firstpage' })"
          >首页</span
        >
        <img src="../../assets/ResourceDetails/arrows.png" alt="图片不见啦" />
        活动详情
      </p>

      <div
        class="main"
        style="
          justify-content: center;
          box-sizing: border-box;
          padding: 0 96px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        "
      >
        <h1 class="font30 font00 widthh1" style="font-weight: 700">
          {{ ReceiveData.title }}
        </h1>
        <span v-if="this.$cookies.get('selectValue') != null">
          <button
            class="bt"
            style="background-color: #808080"
            v-if="ReceiveData.isover == '1'"
          >
            活动结束
          </button>
          <button
            class="bt"
            style="background-color: #c70202"
            @click="exitqct()"
            v-else-if="
              stateActivity == '已加入' &&
              ReceiveData.author != this.$cookies.get('selectValue').id
            "
          >
            退出活动
          </button>
          <button
            class="bt"
            style="background-color: #c70202"
            v-else-if="
              stateActivity == '已加入' ||
              (stateActivity == '禁止加入!' &&
                ReceiveData.author == this.$cookies.get('selectValue').id)
            "
          >
            创建者
          </button>
          <button
            class="bt"
            style="background-color: #8bc34a"
            v-else-if="stateActivity == '已申请'"
          >
            已申请
          </button>
          <button
            class="bt"
            style="background-color: #808080"
            v-else-if="stateActivity == '禁止加入!'"
          >
            禁止加入
          </button>
          <button
            class="bt"
            style="background-color: #8bc34a"
            @click="ApplyToUse()"
            v-else
          >
            申请加入
          </button>
        </span>
      </div>

      <ul class="resources-firstul font14 font80" style="list-style: none">
        <li>{{ ReleaseTime }}</li>
        <span>|</span>
        <li>
          活动
          <p>/</p>
          {{ ReceiveData.t_type }}
        </li>
        <span>|</span>
        <li>
          <img src="../../assets/ResourceDetails/collect.png" alt="" />
          {{ ReceiveData.favocount }}
          <img src="../../assets/ResourceDetails/like.png" alt="" />
          {{ ReceiveData.lovenum }}
          <img src="../../assets/ResourceDetails/comment.png" alt="" />
          {{ ReceiveData.discount }}
        </li>
      </ul>
    </div>

    <!-- 白色大块 -->
    <div class="res-mid main">
      <!-- 活动信息 -->
      <div class="mesmessgae" style="padding-top: 48px">
        <div class="res-tit">
          <img src="../../assets/ResourceDetails/message-a.png" alt="" />
          <span class="font28 font25 pleft">活动信息</span>
        </div>
        <!-- <span style="position: relative;left: -580px;font-size: 25px;">活动信息</span> -->
        <ul
          class="resources-secondul font00"
          style="
            list-style: none;
            font-weight: 700;
            letter-spacing: 1.5px !important;
            line-height: 32px !important;
          "
        >
          <li
            v-if="
              ReceiveData.begintime != '' &&
              ReceiveData.endtime != '' &&
              ReceiveData.begintime != null &&
              ReceiveData.endtime != null
            "
          >
            <p></p>
            <div class="lefttit font20">活动时间:</div>
            <span class="font18 font00" style="font-weight: 400"
              >{{ ReceiveData.begintime }}~{{ ReceiveData.endtime }}</span
            >
          </li>
          <li v-if="ReceiveData.space != '' && ReceiveData.space != null">
            <p></p>
            <div class="lefttit font20">活动地点:</div>
            <span class="font18 font00" style="font-weight: 400">{{
              ReceiveData.space
            }}</span>
          </li>
          <li v-if="ReceiveData.t_type != '' && ReceiveData.t_type != null">
            <p></p>
            <div class="lefttit font20">活动类型:</div>
            <span class="font18 font00" style="font-weight: 400">{{
              ReceiveData.t_type
            }}</span>
          </li>
          <li v-for="(p, index) in RecentAnnouncement" :key="index">
            <p></p>
            <div class="lefttit font20">{{ p.title }}:</div>
            <span class="font18 font00" style="font-weight: 400">{{
              p.context
            }}</span>
          </li>
        </ul>
      </div>
      <!-- 活动详情 -->
      <div style="margin-top: 96px; padding-bottom: 96px">
        <div class="res-tit">
          <img src="../../assets/EventDetails/detail-a.png" alt="" />
          <span class="font28 font25 pleft">活动详情</span>
        </div>
        <div id="text" style="text-align: center; list-style: inside"></div>
      </div>
      <!-- 活动成员 -->
      <div style="padding-bottom: 96px">
        <div class="res-tit">
          <img src="../../assets/EventDetails/member-a.png" alt="" />
          <span class="font28 font25 pleft">活动成员</span>
        </div>
        <ul class="resources-secondul2" style="list-style: none">
          <li style="padding-left: 40px">
            <span style="font-weight: 700">
              <p></p>
              <div class="lefttit font20">组织者:</div>
              <span class="font18" style="font-weight: 400; font-size: 18px"
                >{{ organizers.w_name
                }}<span style="margin-left: 24px; font-weight: 400">{{
                  organizers.sys_number
                }}</span></span
              >
            </span>
            <!-- <ul class="Eventdetails-third-one font18 font00" >
                        <li>{{organizers.w_name}}<span >{{organizers.sys_number}}</span></li>
                    </ul> -->
          </li>
          <li style="padding-left: 40px">
            <span style="font-weight: 700; display: flex; margin-top: 36px">
              <p></p>
              <div class="lefttit font20">成员:</div>
              <ul
                class="Eventdetails-third-two"
                style="flex: 1; list-style: none"
              >
                <li
                  style="font-weight: 400"
                  class="font18"
                  v-for="p in MembersInformation"
                  :key="p.id"
                >
                  {{ p.w_name
                  }}<span style="margin-left: 24px; font-weight: 400">{{
                    p.sys_number
                  }}</span>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 右侧浮动 -->
    <div class="rightbox">
      <div
        class="font12 font00"
        :class="[hoverflag2 == false ? 'mes ' : 'meshover']"
      >
        收藏
      </div>
      <!-- 右侧编辑按钮 -->
      <div
        @click="edit()"
        class="move"
        :class="[hoverflag == false ? 'chuangzuo' : 'chuangzuohover']"
        @mousemove="mumu"
        @mouseleave="mumu2"
        v-if="
          this.$cookies.get('selectValue') != null &&
          ReceiveData.author == this.$cookies.get('selectValue').id
        "
      ></div>
      <img
        v-if="praise == '已点赞'"
        class="move"
        @click="GiveALike()"
        src="../../assets/ResourceDetails/like-blue.png"
        alt="图片不见啦！"
      />
      <img
        v-if="praise == '未点赞'"
        class="move"
        @click="GiveALike()"
        src="../../assets/ResourceDetails/like-blue-l.png"
        alt="图片不见啦！"
      />
      <!-- 右侧收藏按钮 -->
      <div
        style="margin-top: 16px"
        v-if="this.$cookies.get('selectValue') != null && sixnum != 0"
        class="shoucanglogo move shoucanglogohover"
        @mousemove="mumu3"
        @mouseleave="mumu4"
        @click="centerDialogVisible = true"
      ></div>
      <div
        style="margin-top: 16px"
        v-if="this.$cookies.get('selectValue') != null && sixnum == 0"
        class="shoucanglogo move"
        :class="[hoverflag2 == false ? 'shoucanglogo' : 'shoucanglogohover']"
        @mousemove="mumu3"
        @mouseleave="mumu4"
        @click="centerDialogVisible = true"
      ></div>

      <!-- 点击收藏后的显示框 -->
      <el-dialog
        v-model="centerDialogVisible"
        title=""
        width="420px"
        align-center
        class="shoucang"
        show-close="true"
        style="border-radius: 12px; padding: 24px; box-sizing: border-box"
      >
        <span class="font00 font18">收藏到文件夹</span>

        <div class="search">
          <img src="../../assets/index/seach.png" alt="" />
          <input
            @input="screening()"
            type="text"
            ref="test"
            placeholder="请输入关键字搜索"
            v-model="CollectionSearch"
          />
        </div>
        <ul style="list-style: none">
          <li v-for="p in MyWorld" :key="p.id">
            <!-- <img src="@/assets/01.jpg" style="border-radius: 50%;width: 40px;height: 40px;"/> -->
            <img
              src="../../assets/msa.png"
              v-if="p.space_name == '我的实验室'"
              alt=""
            />
            <img v-else :src="p.space_image" />
            <span class="font16 font00">{{ p.space_name }}</span>
            <button
              class="font15"
              :class="[p.isfavorites == 1 ? 'btnwhite' : 'btnblue']"
              @click="collection(p.p_id, p.space_name)"
            >
              {{ p.isfavorites == 1 ? "已收藏" : "收藏" }}
            </button>
          </li>
        </ul>

        <div class="bbbtn" style="display: flex; justify-content: center">
          <button
            class="btnblue move"
            style="margin-right: 48px"
            @click="(centerDialogVisible = false), (dialogFormVisible = true)"
          >
            新建
          </button>
          <button class="btnwhite move" @click="centerDialogVisible = false">
            确定
          </button>
        </div>
      </el-dialog>

      <img
        class="move"
        @click="BackToTheTop('#topup')"
        src="../../assets/ResourceDetails/arrows-blue.png"
        alt="图片不见啦！"
      />
    </div>
    <!-- <img v-show="(hoverflag2==true)"  src="../../assets/ResourceDetails/messbox.png" alt=""> -->

    <div
      style="background-color: #f0f0f0; padding-top: 96px; padding-bottom: 96px"
    >
      <!-- 评论 -->
      <div class="res-bottom main bd">
        <!-- <span style="display: block;padding-left: 10px; font-size: 25px;width:100px;padding-top: 10px;">评论</span> -->
        <div class="res-tit" style="padding: 24px 0 0 24px">
          <img
            style="margin-right: 0"
            src="../../assets/ResourceDetails/discuss-a.png"
            alt=""
          />
          <span class="font28 font25 pleft">评论</span>
        </div>

        <!-- <el-avatar style="margin-top:20px ;"/> -->
        <div class="toppinglun">
          <img
            v-if="
              this.$cookies.get('selectValue') != null &&
              this.$cookies.get('selectValue').avatarUrl.length > 17
            "
            :src="this.$cookies.get('selectValue').avatarUrl"
            alt=""
          />
          <img
            v-else-if="
              this.$cookies.get('selectValue') != null &&
              this.$cookies.get('selectValue').avatarUrl.length <= 17
            "
            :src="
              getServerUrl() +
              '/1image/' +
              this.$cookies.get('selectValue').avatarUrl
            "
            alt=""
          />
          <!-- <input @keydown.enter="published()" v-model="PublishContent" type="text"  placeholder="说点什么吧..."> -->
          <textarea
            class="font16"
            v-model="PublishContent"
            cols="30"
            rows="9"
            placeholder="说点什么吧..."
          >
          </textarea>
        </div>
        <div style="text-align: right; padding: 24px 36px 36px 0">
          <button class="move btnblue" @click="published()">发表</button>
        </div>

        <!-- 评论区已经发表的评论 -->
        <div class="read main">
          <ul style="list-style: none">
            <li v-for="p in comments" :key="p.id">
              <img v-if="p.headImage.length > 17" :src="p.headImage" />
              <img
                v-else-if="p.headImage.length <= 17"
                :src="getServerUrl() + '/1image/' + p.headImage"
              />
              <div class="read-id">
                <span style="margin-right: 12px" class="font25 font16">{{
                  p.w_name
                }}</span>
                <span class="font80 font12">{{ p.puttime }}</span>
                <span
                  class="content font16 font00 huan"
                  style="margin-top: 12px"
                  >{{ p.context }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 你可能喜欢 -->
      <div class="Resources-Like main">
        <div class="xiangguan">
          <p>你可能喜欢</p>
          <span @click="changeonechange()" class="hyh">换一换 </span>
        </div>

        <!-- <el-icon  style=" font-size: 20px;position: relative;left: 550px;top: 40px;" @click="changeonechange()"><RefreshLeft /></el-icon> -->
        <ul class="ccc" style="display: flex; list-style: none">
          <li
            class="myli move"
            v-for="(p, index) in RandomDisplay"
            :key="p.id"
            @click="MyContent(index)"
          >
            <div class="imgtop" style="overflow: hidden; position: relative">
              <img
                class="imgcss"
                :src="getServerUrl() + '/1image/' + p.picurl"
                alt=""
              />
            </div>
            <span class="font16 text-ellipses tit">{{ p.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <footers></footers>
  </div>

  <el-dialog
    v-model="dialogFormVisible"
    align-center
    :show-close="false"
    class="create"
    style="width: 420px; height: 196px; border-radius: 12px; position: relative"
  >
    <p class="font18 font00">创建我的世界</p>
    <input
      class="inputbox leftmid2"
      v-model="Space.space_name"
      autocomplete="off"
      type="text"
      placeholder="实验室"
    />
    <div class="bbbtn leftmid2" style="display: flex; justify-content: center">
      <button class="btnblue move" style="margin-right: 48px" @click="save()">
        确认
      </button>
      <button class="btnwhite move" @click="dialogFormVisible = false">
        取消
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import footers from "@/components/footer.vue";
import axios from "@/Api/axios";
import router from "@/router";
export default {
//   watch: {
//     $route(to, from) {
//       if (this.$route.query.paramName) {
//         this.showshow();
//       }
//     },
//   },
  data() {
    return {
      Space: {
        space_name: "",
      },
      dialogFormVisible: false,
      centerDialogVisible: false,
      // 收藏里面的项目
      MyWorld: [],
      MyWorld2: [],
      //收藏按钮是否隐藏
      centerDialogVisible: false,
      // 搜索
      CollectionSearch: "",
      ReceiveData: {},
      ReleaseTime: "",
      RecentAnnouncement: [],
      islike: {
        w_id: "",
        p_id: "",
      },
      praise: "",
      // 来源页面
      SourcePage: "",
      // 接受评论
      comments: [],
      // 评论数
      commentsNums: "",
      // 发表内容
      PublishContent: "",
      // 发表评论
      Discuss: {
        w_id: "",
        p_id: "",
        context: "",
      },
      // 喜欢
      RandomDisplay: [],
      //  申请加入后端接受
      Member: {
        w_id: Number,
        p_id: Number,
      },
      //  创建者
      organizers: {},
      //  成员
      MembersInformation: [],
      //  申请状态信息
      ApplicationStatus: "",
      hoverflag2: false,
      hoverflag: false,
      // 接受后端给的其它信息
      backOther: [],
      // 当前状态
      stateActivity: "",
      sixnum: 0,
    };
  },
  components: {
    footers,
  },
  beforeUnmount() {
    document.getElementById("text").innerHTML = ''
  },
  mounted() {
    this.showshow();
  },
  methods: {
    showshow() {
        // localStorage.setItem("abcde", '');
      this.BackToTheTop("#topup");
      // 获取当前申请状态
      if (this.$cookies.get("selectValue") != null) {
        let menber = {};
        menber.w_id = this.$cookies.get("selectValue").id;
        menber.p_id = this.$route.query.paramName;
        axios.post("PC/member/state", menber).then((res) => {
          this.stateActivity = res.data.msg;
        });
      }
      // 判断是否收藏
      if (this.$cookies.get("selectValue") != null) {
        axios
          .post(
            "PC/space/" +
              this.$cookies.get("selectValue").id +
              "/getfavorites/" +
              this.$route.query.paramName
          )
          .then((res) => {
            this.MyWorld = res.data.data;
            this.MyWorld2 = res.data.data;

            for (let i = 0; i < this.MyWorld.length; i++) {
              if (this.MyWorld[i].isfavorites == 1) {
                this.sixnum = this.sixnum + 1;
              }
            }
          });
      }
      if (this.$route.query.page == 0) {
        this.SourcePage = "发现";
      } else if (this.$route.query.page == 1) {
        this.SourcePage = "我的";
      } else if (this.$route.query.page == 2) {
        this.SourcePage = "实验室";
      }
      axios.get("PC/project/" + this.$route.query.paramName).then((res) => {
        this.ReceiveData = res.data.data;
        document.getElementById("text").innerHTML = this.ReceiveData.richtext;
        this.ReleaseTime = res.data.data.puttime.substr(0, 10);
        if (
          this.ReceiveData.begintime != "" &&
          this.ReceiveData.endtime != "" &&
          this.ReceiveData.begintime != null &&
          this.ReceiveData.endtime != null
        ) {
          this.ReceiveData.begintime = this.ReceiveData.begintime.substring(
            5,
            this.ReceiveData.begintime.length-3
          );
          this.ReceiveData.endtime = this.ReceiveData.endtime.substring(
            5,
            this.ReceiveData.endtime.length-3
          );
        }

          

      });

      // 其它信息
      axios.get("PC/other/" + this.$route.query.paramName).then((res) => {
        this.RecentAnnouncement = res.data.data;
      });
      // 判断是否点赞
      if (this.$cookies.get("selectValue") != null) {
        this.islike.w_id = this.$cookies.get("selectValue").id;
        this.islike.p_id = this.$route.query.paramName;
        axios.post("like/islike", this.islike).then((res) => {
          this.praise = res.data.data;
        });
      }
      //  显示评论
      axios.get("PC/discuss/" + this.$route.query.paramName).then((res) => {
        this.comments = res.data.data.message;
        this.commentsNums = this.comments.length;
      });
      // 推荐内容
      this.changeonechange();

      axios
        .post("PC/member/SelectMenberByPid/" + this.$route.query.paramName)
        .then((res) => {
          this.organizers = res.data.data.member;

          this.MembersInformation = res.data.data.members;
        });
    },
    
    exitqct() {
      axios
        .get(
          "PC/project/" +
            this.$cookies.get("selectValue").id +
            "/exit/" +
            this.$route.query.paramName
        )
        .then((res) => {
          if (res.data.data == true) {
            let menber = {};
            menber.w_id = this.$cookies.get("selectValue").id;
            menber.p_id = this.$route.query.paramName;
            axios.post("PC/member/state", menber).then((res) => {
              this.stateActivity = res.data.msg;
            });
            this.$message("您成功退出了活动");
          } else {
            this.$message({
              message: "退出活动失败",
              type: "warning",
            });
          }
        });
    },
    getServerUrl() {
      return axios.getServerUrl();
    },
    async edit() {
      await axios.get("PC/project/" + this.$route.query.paramName).then((res) => {
        let a = res.data.data;
        // console.log(a.richtext)
        localStorage.setItem("abcde", a.richtext);
      });
      this.$store.commit("activecontent", {});
      this.$router.push({
        name: "activitiess",
        query: { paramName: this.$route.query.paramName },
      });
    },
    save() {
      this.centerDialogVisible = true;
      this.Space.w_id = this.$cookies.get("selectValue").id;
      if (this.Space.space_name == "" || this.Space.space_name.trim() == "") {
        ElMessage({
          message: "名字不能为空",
          type: "warning",
        });
      } else {
        axios.post("PC/space/add", this.Space).then((res) => {
          // 创建成功
          if (res.data.code == 20041) {
            ElMessage({
              type: "success",
              //  message: `成功创建我的世界:${this.Space.space_name}`,
              message: "创建我的世界成功",
            });
            axios
              .post(
                "PC/space/getSpaceByUserIdNoPid/" +
                  this.$cookies.get("selectValue").id
              )
              .then((res) => {
                this.$store.commit("show8", res.data.data.data);
              });
            axios
              .post(
                "PC/space/" +
                  this.$cookies.get("selectValue").id +
                  "/getfavorites/" +
                  this.$route.query.paramName
              )
              .then((res) => {
                this.MyWorld = res.data.data;
                this.MyWorld2 = res.data.data;
              });
          } else if (res.data.msg == "空间名不能重复") {
            ElMessage({
              type: "info",
              message: `空间名不能重复`,
            });
          }
          // 创建失败
          else {
            ElMessage({
              type: "info",
              message: `创建失败`,
            });
          }
        });
      }
      this.dialogFormVisible = false;
      centerDialogVisible = true;
    },
    mumu() {
      this.hoverflag = true;
    },
    mumu2() {
      this.hoverflag = false;
    },
    mumu3() {
      this.hoverflag2 = true;
    },
    mumu4() {
      this.hoverflag2 = false;
    },
    // 收藏/取消收藏
    collection(id, name) {
      let space = { w_id: "", p_id: "", space_name: "" };
      space.w_id = this.$cookies.get("selectValue").id;
      space.p_id = id;
      space.space_name = name;
      axios.post("PC/space", space).then((res) => {
        axios
          .post(
            "PC/space/" +
              this.$cookies.get("selectValue").id +
              "/getfavorites/" +
              this.$route.query.paramName
          )
          .then((res) => {
            this.MyWorld = res.data.data;
            this.MyWorld2 = res.data.data;
            this.sixnum = 0;
            for (let i = 0; i < this.MyWorld.length; i++) {
              if (this.MyWorld[i].isfavorites == 1) {
                this.sixnum += 1;
              }
            }
          });
        axios.get("PC/project/" + this.$route.query.paramName).then((res) => {
          this.ReceiveData = res.data.data;
        });
      });
    },
    //搜索
    screening() {
      this.MyWorld = [];
      for (let i = 0; i < this.MyWorld2.length; i++) {
        if (this.MyWorld2[i].space_name.indexOf(this.CollectionSearch) != -1) {
          this.MyWorld.push(this.MyWorld2[i]);
        }
      }
    },
    // 创建实验室
    create() {
      // let Space={ w_id:"",space_name:""}
      // ElMessageBox.prompt('', '创建我的世界', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         inputValidator: (val) => {
      //   if (val === null||val.length < 1 || val.length > 10) {
      //     return false;
      //   }
      // },
      // inputErrorMessage: '此项不能为空，最多可输入10个字符',
      //     })
      //     .then(({ value }) => {
      //         Space.w_id=this.$cookies.get('selectValue').id;
      //         Space.space_name=value;
      //          axios.post("PC/space/add",Space).then(res=>{
      //             // 创建成功
      //             if (res.data.code==20041)
      //             {
      //                 ElMessage({
      //              type: 'success',
      //              message: `成功创建我的世界:${value}`,
      //                })
      //                axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{
      //                 this.$store.commit("show8",res.data.data.data);
      //                 })
      //                 axios.post("PC/space/"+this.$cookies.get('selectValue').id+"/getfavorites/"+this.$route.query.paramName).then(res=>{
      //                      this.MyWorld=res.data.data;
      //                         this.MyWorld2=res.data.data;
      //                      })
      //             }
      //             else if (res.data.msg=="空间名不能重复")
      //             {
      //                 ElMessage({
      //              type: 'info',
      //              message: `空间名不能重复`,
      //                })
      //             }
      //             else
      //             // 创建失败
      //             {
      //                 ElMessage({
      //              type: 'info',
      //              message: `创建失败`,
      //                })
      //             }
      //         })
      //      })
      //     //  取消创建
      //     .catch(() => {
      //         ElMessage({
      //             type: 'info',
      //             message: '取消创建',
      //             })
      //         })
    },
    MyContent(e) {
      axios.get("PC/project/" + this.RandomDisplay[e].id).then((res) => {
        if (res.data.data.parent == 0) {
          this.$router.push({
            path: "/homepage/resourcesfordetails",
            query: { paramName: res.data.data.id, page: 1 },
          });
        } else {
          this.$router.push({
            path: "/homepage/eventdetails",
            query: { paramName: res.data.data.id, page: 1 },
          });
        }
      });
    },
    // 换一换
    changeonechange() {
      axios.get("PC/project/getRanFive").then((res) => {
        let a = res.data.data;
        if (a != [] && a != null && a != "") {
          for (let i = 0; i < a.length; i++) {
            if (a[i].picurl == "" || a[i].picurl == null) {
              a[i].picurl = "mr.png";
            }
          }
        }
        this.RandomDisplay = a;
      });
    },
    // 发表内容
    published() {
      if (
        this.$cookies.get("selectValue") != null &&
        this.PublishContent != "" &&
        this.PublishContent.trim() != ""
      ) {
        this.Discuss.w_id = this.$cookies.get("selectValue").id;
        this.Discuss.p_id = this.$route.query.paramName;
        this.Discuss.context = this.PublishContent;
        axios.post("PC/discuss", this.Discuss).then((res) => {
          axios.get("PC/discuss/" + this.$route.query.paramName).then((res) => {
            this.comments = res.data.data.message;

            this.commentsNums = this.comments.length;
          });
        });
        this.PublishContent = "";
      } else if (
        this.PublishContent == "" ||
        this.PublishContent.trim() == ""
      ) {
        this.$message({
          message: "发表内容不能为空",
          type: "warning",
        });
      } else {
        this.$message({
          message: "请先登入",
          type: "warning",
        });
      }
    },
    // 申请加入
    ApplyToUse() {
      this.Member.w_id = this.$cookies.get("selectValue").id;
      this.Member.p_id = this.$route.query.paramName;

      axios.post("member/Applyjoin", this.Member).then((res) => {
        if (res.data.data == true) {
          this.$message({
            showClose: true,
            message: "申请成功",
            type: "success",
          });
          this.stateActivity = "已申请";
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          this.stateActivity = res.data.msg;
        }
      });
    },
    // 点赞
    GiveALike() {
      axios.post("PC/like/like", this.islike).then((res) => {
        if (res.data.data == 1) {
          this.ReceiveData.lovenum = this.ReceiveData.lovenum + 1;
          this.praise = "未点赞";
        } else if (res.data.data == 2) {
          this.ReceiveData.lovenum = this.ReceiveData.lovenum - 1;
          this.praise = "已点赞";
        }
      });
    },

    // 回到顶部
    BackToTheTop(e) {
      // document.body.scrollTop=0;
      // document.documentElement.scrollTop=0;
      document.querySelector(e).scrollIntoView(true);
    },
  },
};
</script>

<style scoped lang="scss">
.create p {
  position: absolute;
  top: 24px;
  left: 24px;
}
.create .bbbtn {
  bottom: 24px;
}
.create .inputbox {
  outline: none;
  padding-left: 12px;
  top: 71px;
  width: 372px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  font-size: 14px;
  border: 1.5px solid #808080;
}
.create .bbbtn button {
  width: 90px;
  height: 36px;
  border-radius: 18px;
  outline: none;
  border: none;
}
/deep/.shoucang .el-dialog__headerbtn {
  // display: none;
}
/deep/ .shoucang .el-dialog__header {
  // display: none;
  height: 0;
  padding: 0;
}
/deep/ .shoucang .el-dialog__body {
  // display: none;
  padding: 0 !important;
}
.shoucang .bbbtn {
  margin-top: 24px;
}
.shoucang .bbbtn button {
  width: 90px;
  height: 36px;
  border-radius: 18px;
  outline: none;
  border: none;
}
.shoucang .search {
  position: relative;
  margin: 16px 0;
  // background: pink;
}
.shoucang .search input {
  padding-left: 44px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  border: none;
  background-color: #f2f2f2;
}
.shoucang .search img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 21px;
  left: 12px;
  z-index: 2;
  margin-top: 0;
}
.shoucang ul {
  height: 320px;
  overflow-y: auto;
}
.shoucang li {
  position: relative;
  height: 64px;
  line-height: 64px;
  // background: #00b2a2;
}
.shoucang li img {
  vertical-align: middle;
  margin-top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
  // background-color: pink;
}
.shoucang li button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  border: none;
  text-align: center;
  width: 72px;
  height: 36px;
  border-radius: 18px;
  // font-size: 15px;
}
.tit {
  padding: 0 24px;
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
}

.mes {
  // display: none;
  position: absolute;
  bottom: 80px;
  right: 60px;
  width: 47px !important;
  height: 21px !important;
  padding-left: 10px;
  box-sizing: border-box;
  /* text-align: center; */
  line-height: 22px;
  /* background-color: pink; */
  background-image: url(../../assets/abcd.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: 0.3s;
  opacity: 0;
}
.meshover {
  // display: block;
  opacity: 1;
  position: absolute;
  bottom: 80px;
  right: 60px;
  width: 47px !important;
  height: 21px !important;
  padding-left: 10px;
  box-sizing: border-box;
  /* text-align: center; */
  line-height: 22px;
  /* background-color: pink; */
  background-image: url(../../assets/abcd.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: 0.3s;
}
.content {
  width: 100%;
}
.read-id {
  width: 90%;
}
.rightbox {
  z-index: 10086;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 90px;
  right: 60px;
  /* background-color: pink; */
}
.rightbox img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-top: 16px;
  /* background-color: pink; */
}
.Eventdetails-third-two li {
  display: inline-block;
  width: 33.3%;
}
.Eventdetails-third-two li:nth-child(n + 4) {
  margin-top: 20px;
}
/* PC */
@media screen and (min-width: 1201px) {
  .main {
    width: 1366px;
    /* background-color: #00b2a2; */
  }
  .widthh1 {
    max-width: 1032px;
  }
  .myli {
    position: relative;
    display: inline-block;
    /* display: flex;
        flex-direction: column; */
    width: 248px;
    height: 187.5px;
    background-color: #fff;
    margin-right: 31.5px;
    overflow: hidden;
    border-radius: 9px;
  }
  .imgtop {
    width: 248px;
    height: 139.5px;
    vertical-align: middle;
  }
  .myli:nth-child(5n) {
    margin-right: 0;
  }
  .myli:nth-child(n + 6) {
    margin-top: 31.5px;
  }
}
/* 平板 */
@media screen and (max-width: 1200px) {
  .main {
    width: 980px;
    /* background-color: pink; */
  }
  .widthh1 {
    max-width: 646px;
  }
  .myli {
    position: relative;
    display: inline-block;
    width: 224px;
    height: 174px;
    /* background-color: #00b2a2; */
    background-color: #fff;
    margin-right: 28px;
    overflow: hidden;
    border-radius: 9px;
  }
  .myli:nth-child(4n) {
    margin-right: 0;
  }
  .imgtop {
    width: 224px;
    height: 126px;
  }
  .myli:nth-child(n + 5) {
    margin-top: 28px;
  }
  .myli img {
    vertical-align: middle;
  }
  .ccc .myli:nth-child(n + 5) {
    display: none;
  }
}
.box {
  padding-top: 48px;
}
.body {
  background-color: #fff;
}
.main {
  margin: 0 auto;
}
.res-top {
  /* height: 194px; */
  background-color: #eef3ff;
}
.res-top .top-find {
  padding-top: 24px;
  padding-left: 48px;
  box-sizing: border-box;
}

.res-top .top-find img {
  margin: 0 13px;
}
.res-top h1 {
  /* flex: 1; */
  box-sizing: border-box;
  font-weight: 400;
  text-align: center;
  /* background-color: pink; */
  word-wrap: break-word;
}
.res-top .resources-firstul {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 36px 0 48px 0; */
  padding: 36px 0 48px 0;
  /* background-color: red; */
}
.res-top .resources-firstul span {
  margin: 0 16px;
}
.res-top .resources-firstul p {
  display: inline-block;
  margin: 0 6px;
}
.res-top .resources-firstul img {
  vertical-align: top;
  width: 14px;
  height: 13px;
}
.resmessgae {
  padding-top: 48px;
}
.res-tit {
  display: flex;
  margin-bottom: 36px;
}
.res-tit span {
  margin-left: 12px;
  line-height: 35px;
}
.res-tit img {
  vertical-align: bottom;
  width: 28px;
  height: 30px;
}
.res-tit .pleft {
  margin-left: 12px;
}
.resources-secondul {
  /* margin-top: 48px;
    margin-bottom: 96px; */
  /* padding: 0 0 96px 0; */
  /* background-color: red; */
}
.resources-secondul2 li {
  position: relative;
  padding: 0 0;
  box-sizing: border-box;
}
.resources-secondul2 p {
  position: absolute;
  top: 10px;
  left: 40px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #002580;
  /* margin-right: 24px; */
}

.resources-secondul p {
  position: absolute;
  top: 14px;
  left: 40px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #002580;
  /* margin-right: 24px; */
}
.resources-secondul li {
  position: relative;
  padding: 0 40px;
  box-sizing: border-box;
  /* background-color: pink; */
  /* background: red; */
  display: flex;
}
.resources-secondul li:nth-child(n + 1) {
  margin-top: 36px;
}

.resources-secondul li span {
  /* margin-left: 24px; */
  display: inline-block;
  background-color: #fff;
  padding: 0 0 0 24px;
  box-sizing: border-box;
  flex: 1;
}
.lefttit {
  display: inline-block;
  width: 164px;
  /* background-color: pink; */
  padding-left: 32px;
}
.vvv {
  width: 844px;
  height: 485px;
  margin: 0 auto;
  overflow: hidden;
}
video {
  width: 844px;
  height: 485px;
}
.dian {
  position: absolute;
  top: 14px;
  left: 40px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #002580;
  margin-right: 24px;
}
.resources-third {
  padding: 0 40px;
  position: relative;
}
.resources-third span {
  padding-left: 24px;

  /* width: 196px;
    display: inline-block;
    padding-left: 32px;
    box-sizing: border-box;
    background-color: pink; */
}
.bt {
  width: 118px;
  height: 40px;
  border-radius: 20px;
  /* display: block; */
  color: #fff;
  outline: none;
  border: none;
  font-size: 17px;
  margin-left: 24px;
  cursor: pointer;
}
.resources-third-one li {
  padding-left: 24px;
  box-sizing: border-box;
  display: inline-block;
  width: 50%;

  /* background-color: red; */
}
.resources-third-one li:nth-child(n + 3) {
  margin-top: 12px;
}
.res-bottom {
  background-color: #fff;
}
.toppinglun {
  position: relative;
  display: flex;
  padding: 0 36px 0 36px;
  /* background-color: #00b2a2; */
}
.toppinglun textarea {
  /* padding-left: 12px; */
  padding: 12px;
  box-sizing: border-box;
  flex: 1;
  border-radius: 9px;
  outline: none;
  border: 2px solid #d9d9d9;
  background-color: #f2f2f2;
  resize: none;
}
.res-bottom button {
  width: 90px;
  height: 36px;
  border-radius: 18px;
  border: none;
  background-color: #002580;
  color: #fff;
}
.res-bottom img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 28px;
}
.read {
  background-color: #fff;
  padding: 0 36px;
  box-sizing: border-box;
  border-radius: 9px;
}
.read li {
  display: flex;
  padding: 24px 0;
  box-sizing: border-box;
  border-bottom: 2px solid #d9d9d9;
  /* background-color: red; */
}
.read ul li:nth-last-child(1) {
  border: none !important;
  /* background-color: pink; */
}
.xiangguan {
  margin-top: 96px;
  margin-bottom: 24px;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
}
.hyh:hover {
  color: #002580;
  cursor: pointer;
}
.shoucanglogo {
  width: 48px;
  height: 48px;
  background-image: url(../../assets/ResourceDetails/collect-blue.png);
  background-size: contain;
}
.shoucanglogohover {
  width: 48px;
  height: 48px;
  background-image: url(../../assets/ResourceDetails/collect-blue-l.png);
  background-size: contain;
}
.chuangzuo {
  width: 48px;
  height: 48px;
  background-image: url(../../assets/ResourceDetails/caozuozhe-blue.png);
  background-size: contain;
}
.chuangzuohover {
  width: 48px;
  height: 48px;
  background-image: url(../../assets/ResourceDetails/caozuozhe-blue-l.png);
  background-size: contain;
}
</style>