<!-- 活动和资源添加共同界面 -->
<template>

<div class="main">

      <!-- 左上区域 -->
    <div>
      <div id="activitiesresourcesupperleft" class="leftwidth bd">
        <router-link class="list font00 font16" @click="ActivitiesOrResources1()" active-class="active" :to="{path:'activitiess',query:{MyWord:this.$route.query.MyWord}}">
          添加活动
          <div class="imgpo" v-if="(activeindex==1)">
            <img src="../../assets/index/yel.png" alt="">
          </div>
        </router-link>
        <router-link class="list font00 font16" @click="ActivitiesOrResources2()" active-class="active" :to="{path:'resourcess',query:{MyWord:this.$route.query.MyWord}}" >
          添加资源
          <div class="imgpo" v-if="(activeindex==2)">
            <img src="../../assets/index/yel.png" alt="">
          </div>
        </router-link>
      </div>
    </div>

    <!-- 主题区域 -->
    <div class="leftpadding rightwidth">
      <router-view />
    </div>

 
</div>
<div>
  <footers></footers>

</div>


</template>

<script scoped>
import footers from '@/components/footer.vue';
export default {
  components: { footers },
  
  data() {
    return {
      
      UpperRightData: "添加活动",
      activeindex:1,
    };
  },
  mounted(){
    let k=window.location.href;
        if (k.indexOf('resourcess')>=0)
        {
          this.UpperRightData="添加资源"
          this.activeindex=2
        }
        else{
          this.UpperRightData="添加活动"
          this.activeindex=1
          
        }
  },
  methods: {
    ActivitiesOrResources1() {
      this.UpperRightData = "添加活动";
      this.activeindex=1;
    },
    ActivitiesOrResources2() {
      this.UpperRightData = "添加资源";
      this.activeindex=2;
    },
  },

};
</script>

<style scoped>
/* pc */
@media  screen and (min-width:1201px){
  .main {
    margin: 0 auto;
    width: 1366px;
    margin-top: 24px;
  }
  .leftwidth {
    width: 240px;
    overflow: hidden;;
  }
  .leftpadding {
    padding-left: 39.5px;
  }
}

/* 平板 */
@media  screen and (max-width:1200px){
    .main {
      margin: 0 auto;
      width: 980px;
      margin-top: 24px;
    }
    .leftwidth {
     width: 232px;
   }
   .leftpadding {
    padding-left: 20px;
  }
}

.main {
  padding-bottom: 96px;
  display: flex;
}
.rightwidth {
  flex: 1;
}
#activitiesresourcesupperleft img {
  width: 6px;
  height: 24px;
  overflow: hidden;
  /* background-color: pink; */
}
.list {
  position: relative;
  display: block;
  padding: 24px;
  text-decoration: none;
}
.list:hover {
  background:rgb(236, 245, 255);
  transition: .3s;
}
.imgpo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 6px;
  height: 24px;
}
</style>