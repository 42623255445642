<template>
  <!-- 有箭头 -->

    <el-carousel class="move" @change="onChange"  arrow="always" :interval="timechange"  :autoplay="autoplay"  v-if="flagshow==false && shuju[0]"  :height="(screenHeight-48)+'px'" > 
      <el-carousel-item class="move" v-for="(item,index) in shuju" :key="item.id"   >
        <video   class="move" @click="aaa(item.id,item.parent,item.open)" v-if=" item.haveVideo==1" :class="'video'+index"   :key="item.url"  autoplay="autoplay" muted  :src="getServerUrl()+'/video/'+ item.v_url" style="width:100% ;height: 100%;object-fit: cover;"></video>
       <img v-else @click="aaa(item.id,item.parent,item.open)" :src="getServerUrl()+'/1image/'+item.picurl" alt="" style="width:100% ;height: 100%;object-fit: cover;"  >
      </el-carousel-item> 
      
    </el-carousel>
    
    <!-- 没箭头 -->
    <el-carousel  @change="onChange2"  class="wu move" arrow="always" :interval="timechange" :autoplay="true"  v-if="flagshow==true && shuju[0]"  :height="(screenHeight)+'px'" > 
      <el-carousel-item class="move" v-for="(item,index)  in shuju" :key="item.id"   >
        <video class="move" @click="aaa(item.id,item.parent,item.open)"  v-if=" item.haveVideo==1" :class="'video2'+index"  autoplay="autoplay"  muted   :key="item.url"  @ended="next"   :src="getServerUrl()+'/video/'+ item.v_url" style="width:100% ;height: 100%;object-fit: cover;">
          <source src="myVideo.mp4" type="video/mp4">
        </video>
       <img v-else  @click="aaa(item.id,item.parent,item.open)" :src="getServerUrl()+'/1image/'+item.picurl" alt="" style="width:100% ;height: 100%;"  >
      </el-carousel-item> 
      
    </el-carousel>
    <!-- <img v-if="flagshow==true && p!=[]"  :src="getServerUrl()+'/1image/'+p" :style="{width:screenWidth+'px',height:screenHeight+'px'}" alt=""> -->
    <img v-if="flagshow==true" src="../../assets/index/gang.png" alt="" style="position:fixed;z-index: 2;top: 64px;right: 64px;width: 48px;height: 48px; cursor: pointer;" @click="isshow()" > 
    <footers style="position: absolute; bottom: 0;left: 0; height:38px;opacity: 0.6;"></footers>
</template>

<script>
import axios from '@/Api/axios'
import router from '@/router';
import { ElMessage } from 'element-plus'
import footers from '@/components/footer.vue';
export default {
components:{
  footers,
},
  watch:
        {
       
       
            $route:
            {
              
              handler(newvalue,oldvalue){
              
               
              if (window.location.href.indexOf('code') >= 0  && this.$store.state.abcdef==true) {
                
              let code = window.location.href.split('?')[1];
              code = code.substring(5, code.indexOf('&'));
            
                     axios.post('PC/wechat_user/PClogin',{code:code}).then(res=>{
             
                        if (res.data.code==20041){
                          axios.get("wechat_user/auto/"+res.data.data).then(res=>{
                  
                            localStorage.setItem("isToken","aaa")
                            this.$store.commit("abcdefg",false)
                           
                            this.$cookies.set('selectValue', res.data.data.data,{ expires:0 })
                       
                            ElMessage.success("登录成功！"),
                         router.push({
                      name:"myhomescreen",
                            });
                            this.$store.commit("isshow",false)
                          })
                        
                        }
                        else
                        {
                          if ( this.$cookies.get("selectValue")==null || this.$cookies.get("selectValue")=="" )
                          {
                            ElMessage.warning("扫码登入失败")
                          }
                          
                        }
                     })
                }
              },
            deep:true,
             immediate:true
            }
          
        },
  methods:{
  
 
     
    
     onChange(index){
    
         if (this.shuju[index].haveVideo==1){
          let a=document.querySelector(".video"+index)
       
           a.currentTime=0
          // this.$refs.video.play();
            a.play() 
             this.timechange= a.duration*1000
          
           }
          else
           {
            axios.get("admin/sum/"+1).then(res=>{
       this.timechange=res.data.sum.carouseitime*1000
       
      })
           }
},
onChange2(index){
    
    if (this.shuju[index].haveVideo==1){
     let a=document.querySelector(".video2"+index)
      a.currentTime=0
       a.play() 
        this.timechange= a.duration*1000
     
      }
     else
      {
       axios.get("admin/sum/"+1).then(res=>{
  this.timechange=res.data.sum.carouseitime*1000
  
 })
      }
},
    getServerUrl(){ return axios.getServerUrl();
          },
      isshow(){
        this.flagshow=false;
        this.$store.commit("isshow",false);
      },
      aaa(id,parent,open){
        
        if (this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue')!="") //已经登入
        {
          if (parent==1 )
          {
              router.push({path:'/homepage/eventdetails',query:{paramName:id,page:4}})
          
           }
          else if (parent==0 )
          {
          router.push({path:'/homepage/resourcesfordetails',query:{paramName:id,page:4}})
          }
        }
        else{
          if (parent==1 && open==1)
          {
              router.push({path:'/homepage/eventdetails',query:{paramName:id,page:4}})
          
           }
          else if (parent==0 && open==1)
          {
          router.push({path:'/homepage/resourcesfordetails',query:{paramName:id,page:4}})
          }
          else
          {
            router.push({name:'login'})
          }
          
        }
      }
  },
    data () {
      return {
          screenWidth: document.body.clientWidth, // 屏幕宽度
          screenHeight: document.body.clientHeight, // 屏幕高度
          flagshow:true,
          shuju:[],
          p:{},
          isflag:false,
          timechange:5000,
          autoplay:true
      }
    },
  
     mounted() {

      
      if ( this.$cookies.get('selectValue')==null|| this.$cookies.get('selectValue')==""){
        localStorage.setItem("isToken","")  
      }
      setInterval(function () { 
       history.go(0)
      },86400000);

      axios.get("admin/sum/"+1).then(res=>{
       this.timechange=res.data.sum.carouseitime*1000
       
      })
      if (localStorage.getItem('isToken')=='aaa')
      {
        this.flagshow=false;
        this.$store.commit("isshow",false);
      }
      else
      {
        this.flagshow=true;
        this.$store.commit("isshow",true);
      }
            const that = this;
            window.onresize = () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth;
                    window.screenHeight = document.body.clientHeight;
                    that.screenWidth = window.screenWidth;
                    that.screenHeight = window.screenHeight;
                 
                })()
            };

            axios.get("project/find/getactivity").then(res=>{
              
              this.shuju=res.data.data;
              this.p=this.shuju[0].picurl
            })  
        },
        

}
</script>

<style scoped lang="scss">
/deep/.el-carousel__indicators--horizontal {
  bottom: 76px;
}
.el-carousel{
    width: 100%
}
 * {
  margin: 0px ;
  padding: 0px;
 }

 .block{
  padding: 0 0;
  width: 100%;
  height: 100%;
 }
 el-ico{
  font-size: 40px;
 }
 .el-carousel{
  --el-carousel-arrow-size: 60px;
  --el-carousel-arrow-font-size:30px;

 }
 .el-carousel__container {
    height: 100%!important;
    background-color: red;
 }
 .prev{
      position: absolute;
      left: 46px;
      top:100%;
      img{
        width: 75px;
        height: 74px;
      }
    }
    .wu /deep/ .el-carousel__arrow--left{
      display: none;
    }
    .wu /deep/ .el-carousel__arrow--right{
      display: none;
    }
    /deep/ .el-carousel__arrow--left{
      left: 96px;
    }
   /deep/ .el-carousel__arrow--right{
    right: 96px;
   }
</style>