<!-- 我的界面 -->
<template>
  <div id="main" :style="{'min-width':screen+'px'}" >

    <div class="contain">
        <div>
        <!-- 左上区域 -->
                    <div class="same upperleft">
                    <!-- <div class="demo-basic--circle" > -->
                        <img class="leftmid move"  v-if="this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl " alt=""  @click="showpersonalinformation()">
                        <img class="leftmid move" v-if="this.$cookies.get('selectValue').avatarUrl.length<=17 " :src="getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt=""  @click="showpersonalinformation()"><br/>
                        <p class="leftmid font16 font00">{{MyPersonalInformation.username}}</p><br/>

                        <p class="leftmid font16 font80">{{MyPersonalInformation.sys}}</p>
                    <!-- </div> -->
                    </div>
        <!-- 左下区域 -->
                    <div class="same lowerleft">
                            <div class="list">
                                <div style="display: flex;justify-content: space-between;">
                                    <span class=" font16 font00 move">我的世界</span>
                                    <span class="mywordfunction font14 font00 move" @click="create()">创建</span>
                                </div>
                            </div>
                            <ul class="listzi">
                                    <li  :class="this.$store.state.xiu==index ?'myword xiu':'myword'" class="font16 font00 move"  v-for="(a,index) in this.$store.state.MyWorld" :key="a.id"   @click="xuan(index)">
                                        {{a.space_name}}
                                        <span class="mywordfunction font14 move" @click="WordDelete(index)">
                                            删除
                                        </span>
                                    </li>
                            </ul>
                    </div>
        </div>
       <!-- 右边区域 -->
        <div class="lowerright">
            <div class="same upperrighttwo" >
                <p class="font20 font25" >{{this.$store.state.choicecontext}}</p>
                    <ul class="ulcss font16 font25">
                        <li style="margin-right:24px; cursor: pointer;;" @click="Newproject()">新建</li>
                        <li @click="rename()" style="cursor: pointer;">重命名</li>
                    </ul>   
        </div>

        <ul class="bo" >
                <li class="myli move" v-for="(p,index) in this.$store.state.Themaincontent" :key="p.id" @click="MyContent(index)" >
                    <div class="imgtop move" style="position: relative;overflow: hidden;">
                        <img class="imgcss" :src="getServerUrl()+'/1image/'+ p.picurl" alt="" >
                    </div>
                        
                    <img @click.stop="shanchuba(p.id)" id="del" src="../../assets/del2.png"/>
                    <span class="text-ellipsis">{{p.title}}</span>
                </li> 
        </ul>
        </div>
    </div>
  </div>
  <!--      close-delay="dialog"  -->
<!-- 创建我的世界的创建dialog -->
  <el-dialog
    v-model="dialogFormVisible"
    align-center
    :show-close="false"
    class="create"
    style="width: 420px;height: 196px;border-radius: 12px;position: relative;"
    >
       <p class="font18 font00">创建我的世界</p>
       <input class="inputbox leftmid2" v-model="Space.space_name" autocomplete="off" type="text" placeholder="实验室">
       <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button class="btnblue move" style="margin-right: 48px;" @click="save1()">确认</button>
                <button class="btnwhite move" @click="dialogFormVisible = false">取消</button>
        </div>
  </el-dialog>
  <!-- 删除的dialog -->
  <el-dialog
        style="border-radius: 12px;position: relative;height: 196px;"
        v-model="dialogVisible"
        width="420px"
        align-center
        :show-close="false"
        class="delworld"
        :before-close="handleClose">
    <p class="font00 font18">确认删除该世界吗？</p>
    <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button  class="btnblue move" style="margin-right: 48px;" @click="delword1()">确认</button>
                <button class="btnwhite move" @click="dialogVisible = false">取消</button>
    </div>
  <!-- <span  style="position: absolute;top: 100px;left: 37%;">
    <el-button type="primary" @click="delword()" style="border: 1px solid black; border-radius: 20px;color: black;background-color:white;">确 定</el-button>
    <el-button @click="dialogVisible = false" style="border: 1px solid black; border-radius: 20px;background-color:#002580;color:azure;">取 消</el-button>

  </span> -->
</el-dialog>
<el-dialog
    id="aaa"
    v-model="dialogFormVisible1"
    :show-close="false"
    class="create"
    align-center
    style="width: 420px;height: 196px;border-radius: 12px;position: relative;"
    :before-close="handleClose"
    >
       <p class="font18 font00">重命名我的世界</p>
       <input class="inputbox leftmid2" v-model="this.Renamingdata.space_name" autocomplete="off">
       <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button class="btnblue move" style="margin-right: 48px;" @click="save()">确认</button>
                <button class="btnwhite move" @click="dialogFormVisible1 = false">取消</button>
        </div>
  </el-dialog>
  <el-dialog
    style="border-radius: 12px;position: relative;height: 196px;"
        v-model="dialogVisible1"
        width="420px"
        align-center
        :show-close="false"
        class="delworld"
        :before-close="handleClose">
        
    <p class="font00 font18">确定从{{this.$store.state.choicecontext}}中移除吗？</p>
    <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button  class="btnblue move" style="margin-right: 48px;" @click="delword()">确认</button>
                <button class="btnwhite move" @click="dialogVisible1 = false">取消</button>
    </div>

</el-dialog>
</template>

<script >
import router from '@/router'
import axios from '@/Api/axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'


export default {
    data()
        {
            
            return{
                name:"",
                dialogFormVisible:false,
                dialogVisible:false,
                dialogFormVisible1:false,
                dialogVisible1:false,
                 centerDialogVisible:ref(false),
                // 我的世界里面的项目
                MyWorld:[],
                clickcolor:"",
                // xiu:0,
                // 接受个人信息
                MyPersonalInformation:[],
                SpaceDto:
                    {
                        w_id:"",
                        space_name:""
                    },
                // // 接受右下角的数据
                // Themaincontent:[],
                // 创建我的世界  
                Space:{
                    w_id:"",
                    space_name:""
                },
                circleUrl:"",
                //重命名传送时的数据
                Renamingdata:{
                    w_id:"",
                    oldname:"",
                    space_name:""
                },
                // 右上方显示的内容
                TopRightCornerContent:"",
                // 当前选中的id
                TheCurrentId:"",
                flag:"-1",
                // 每个状态的个数
                StateNumber:{
                    // 空闲
                    free:"",
                    // 维修
                    repair:"",
                    // 使用
                    use:"",
                    // 预约
                    appointment:""
                },
           
    
                // 每个状态里面的内容
                StateContent_free:[],
                StateContent_repair:[],
                StateContent_use:[],
                StateContent_appointment:[],
                abc:"",
                screen:0,
            }
        },
       
    methods:{
        
        save(){
            
            this.Renamingdata.w_id= this.$cookies.get("selectValue").id;
             this.Renamingdata.oldname=this.$store.state.choicecontext;
            
            if ( this.Renamingdata.space_name.trim() == '' || this.Renamingdata.space_name == "" || this.Renamingdata.space_name === null||this.Renamingdata.space_name.length < 1 || this.Renamingdata.space_name.length > 8) {
            
                this.$message({
                     message: '此项不能为空，最多可输入10个字符',
                    type: 'warning'
                    });      
            }
            else if (this.Renamingdata.oldname=="我的实验室")
            {
                this.$message({
                     message: '我的实验室不能重命名',
                    type: 'warning'
                    });  
            }
            else 
            {
                    axios.post("PC/space/rename",this.Renamingdata).then(res=>{
                            
        if (res.data.data==true)
        {
            this.TopRightCornerContent=this.Renamingdata.space_name;
            axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
                this.$store.commit("show8",res.data.data.data);
                this.$store.commit("show9",this.Renamingdata.space_name)
                       
                    })
                    ElMessage({
                            type: 'success',
                            message:"重命名成功"
                     })
                     this.dialogFormVisible1=false
        }
        else if (res.data.msg=="空间名不能重复")
        {
            ElMessage({
                type: 'info',
                 message: '空间名不能重复',
             })
        }
        else
        {
            ElMessage({
                type: 'info',
                 message: '重命名失败',
             })
        }
       
      })
            }
            
        },
        delword(){
            let a={
                    w_id:this.$cookies.get('selectValue').id,
                    p_id:this.k1,
                    space_name:this.$store.state.choicecontext
            }
                axios.post("PC/space/remSpaceProject",a).then(res=>{
                
              if (res.data.data==true)
              {
                this.$message({
                     message: '删除成功',
                    type: 'success'
                     }); 
                   
                    this.dialogVisible1=false
                    this.SpaceDto.w_id=this.$cookies.get('selectValue').id;
                    this.SpaceDto.space_name=this.$store.state.choicecontext
                    
                     
                    axios.post("PC/space/getspaceproject",this.SpaceDto).then(res=>{
                
                        
                            this.$store.commit("show",res.data.data.data);
             
                     
                            
                    })
                    // this.mounted()
                    // this.$store.commit("show9","我的世界");
              }
              else
              {
                this.$message({
                    message: '删除失败',
                     type: 'warning'
                  });
              }
                })
            // }
            
          
        },
        shanchuba(id){
           
            this.dialogVisible1=true
            this.k1=id;
        },
        MyContent(e){
            axios.get("PC/project/"+this.$store.state.Themaincontent[e].id).then(res=>{
                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:2}})

                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:2,}})
                }
                
               
            })
        },
        rename(e){
            this.Renamingdata.space_name=""
            this.dialogFormVisible1=true   
        },
        Newproject(){
            this.$store.commit("activecontent",{})
            this.$store.commit("resourcesruleForm",{})
             localStorage.setItem("abcde","")
            router.push({
                name:"activitiess",
                query:{MyWord:this.TheCurrentId}
            })     
        },
        getServerUrl(){
                    return axios.getServerUrl();
                 },
       async delword1(e){
                this.dialogVisible=false
                this.MyWorld=this.$store.state.MyWorld
            axios.get("PC/space/"+this.MyWorld[this.abc].id).then(res=>{
                    if (res.data.code=="20021")
                    {
                        ElMessage({
                             type: 'success',
                             message: `删除成功`,
                           })
                           axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
               
                                
                      
               
                this.$store.commit("show8",res.data.data.data);
                
                this.MyWorld= this.$store.state.MyWorld
                
   
                this.$store.commit("show9",this.MyWorld[0].space_name)
              this.$store.commit("show7",0);
              this.SpaceDto.w_id=this.$cookies.get('selectValue').id;
              this.SpaceDto.space_name=this.$store.state.MyWorld[0].space_name
              axios.post("PC/space/getspaceproject",this.SpaceDto).then(res=>{
                let aaa=res.data.data.data
                if (aaa!="" && aaa!=[] && aaa!=null)
                {
                    for (let i=0;i<aaa.length;i++)
                        {
                            if (aaa[i].picurl==null || aaa[i].picurl==''){
                                    aaa[i].picurl='mr.png'
                            }
                            
                        }
                }
                      
                            this.$store.commit("show",aaa); 
                           })  
             
              })
                        
                    }
                    else if (res.data.msg=="我的实验室不能删除")
                    {
                        ElMessage({
                             type: 'info',
                             message: `我的实验室不能删除`,
                           })
                    }
                    else
                    {
                        ElMessage({
                             type: 'info',
                             message: `删除失败`,
                           })
                    }
                   
                   
            })
        },
 
        xuan(e){
            this.TheCurrentId=this.$store.state.MyWorld[e].id
            this.SpaceDto.w_id=this.$cookies.get('selectValue').id;
            this.SpaceDto.space_name=this.$store.state.MyWorld[e].space_name
            axios.post("PC/space/getspaceproject",this.SpaceDto).then(res=>{
                let aaa=res.data.data.data
                if (aaa!="" && aaa!=[] && aaa!=null)
                {
                    for (let i=0;i<aaa.length;i++)
                        {
                            if (aaa[i].picurl==null || aaa[i].picurl==''){
                                    aaa[i].picurl='mr.png'
                            }
                            
                        }
                }
                      
                this.$store.commit("show",aaa);   

                this.$store.commit("show9",this.$store.state.MyWorld[e].space_name)
                
                this.$store.commit("show7",e)
            })
          
        },
        centerDialogVisibleOne()
        {
            centerDialogVisible=true;
        },
    
        //添加活动
        AddActivities(){
            router.push({
                name:"activities"
            })
        },
        // 点击头像显示我的信息
        showpersonalinformation(){
            router.push({
                name:"subpersonalInformati"
            })
            
        },
        // 测试跳转资源详情
        Resourcesfordetails(){
                router.push({
                    name:"resourcesfordetails"
                })
        },
        //测试跳转活动详情
        EventDetails()
        {
            router.push({
                name:"EventDetails"
                
            })
        },
        // 我的世界里面项目删除
        WordDelete(e)
        {
            this.dialogVisible=true;
            this.abc=e;
        },
        Transformtheworld(id)
        {
            this.clickcolor=id;
        },
        // 我的世界点击创建按钮
        save1(){
            this.dialogFormVisible=false
            this.Space.w_id=this.$cookies.get('selectValue').id;
            if (this.Space.space_name=="" || this.Space.space_name.trim()=="")
            {
                ElMessage({
                message: '名字不能为空',
                type: 'warning',
                }) 
            }
            else
            {
                axios.post("PC/space/add",this.Space).then(res=>{ 
    
    // 创建成功
    if (res.data.code==20041)
    {
        ElMessage({
     type: 'success',
    //  message: `成功创建我的世界:${this.Space.space_name}`,
    message:"创建我的世界成功"
       })
       axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 

        this.$store.commit("show8",res.data.data.data);
        
        })
    }
    else if (res.data.msg=="空间名不能重复")
    {
        ElMessage({
     type: 'info',
     message: `空间名不能重复`,
       })
    }
    else
    // 创建失败
    {
        ElMessage({
     type: 'info',
     message: `创建失败`,
       })
    }
})   
this.Space.space_name=""
            }
           
            },
        create()
        {
            this.dialogFormVisible=true
        }
        },
        beforeCreate(){
        // this.$store.commit("show7",0)
    },
        mounted()
        {  
            
            this.screen=window.screen.availWidth-80;
            axios.get("PC/wechat_user/auto/"+this.$cookies.get('selectValue').id).then(res=>{
               
                    this.MyPersonalInformation=res.data.data.data; 
                
            })
            axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
                    this.$store.commit("show8",res.data.data.data);

                })
                this.$store.commit("show9","我的实验室")
        axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
           
                this.MyWorld=res.data.data.data;
                if (this.MyWorld!="")
                {
                    this.TopRightCornerContent=this.MyWorld[0].space_name;
                    this.TheCurrentId=this.MyWorld[0].id;
                    this.SpaceDto.w_id=this.$cookies.get('selectValue').id;
                   
          
                    // this.SpaceDto.space_name=this.MyWorld[0].space_name;
                    this.SpaceDto.space_name=this.MyWorld[this.$store.state.xiu].space_name;
                    // this.SpaceDto.space_name=this.MyWorld[0].space_name;
                  
                    axios.post("PC/space/getspaceproject",this.SpaceDto).then(res=>{
                        let aaa=res.data.data.data
                        if (aaa!="" && aaa!=[] && aaa!=null){
                            for (let i=0;i<aaa.length;i++)
                        {
                            if (aaa[i].picurl==null || aaa[i].picurl==''){
                                    aaa[i].picurl='mr.png'
                            }
                            
                        }
                        }
                     
                            this.$store.commit("show",aaa);   
                    })
               
                }
                
            })
          
        },
        }
</script>

<style scoped lang="scss">

.create p {
    position: absolute;
    top: 24px;
    left: 24px;
}
.create .bbbtn {
    bottom: 24px;
}
.create .inputbox {
    outline: none;
    padding-left: 12px;
    top: 71px;
    width: 372px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    border: 1.5px solid #808080;
}
.create .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
/deep/ .el-dialog__header {
    display: none!important;
}
.delworld p {
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    white-space: nowrap;
}
.delworld .bbbtn {
    bottom: 24px;
}
.delworld .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.mywordfunction:hover {
    font-weight: 700;
}
/deep/ .el-dialog__header {
    padding: 0!important;
    background-color: pink;
}
/deep/.shoucang .el-dialog__headerbtn {
    display: none!important;
}
/deep/ .shoucang .el-dialog__header {
    display: none;
    padding: 0!important;
}
/deep/ .shoucang .el-dialog__body {
    // display: none;
    padding: 0 !important;
}
/* 弹出框 */
    /deep/.create .el-dialog__header {
        display: none!important;
    }
    /deep/.create .el-dialog__body{

    }
    /deep/.create .el-dialog__header {
        display: none!important;
        padding: 0!important;
    }
    /deep/.create .el-dialog__title {

    }
    /deep/.create .el-dialog__body {
        padding: 0!important;
    }
    .listzi li:hover {
        background-color: rgb(236, 245, 255);
        transition: .3s;
    }    

   /* pc */
    @media (min-width: 1201px) {
      .contain {
        display: flex;
        width: 1366px;
        /* background-color: red; */
      }
      .lowerright {
        padding-left:39.5px ;
        width: 1086.5px;
        /* background-color: green; */
        }
      .upperleft {    
        border-radius: 9px;
        padding-top: 36px;
        padding-bottom: 36px;
        width: 240px;
        background-color: #fff;
        margin-top: 24px;
    }
    .lowerleft {
        margin-top: 24px;
        width: 240px;
        /* padding: 0 24px; */
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 9px;
        overflow: hidden;
    }
    }
 /* 平板 */
    @media (max-width: 1200px) {
      .contain {
        display: flex;
        width: 980px;
        /* background-color: yellow; */
      }
      .upperleft {    
        border-radius: 9px;
        padding-top: 36px;
        padding-bottom: 36px;
        width: 232px;
        background-color: #fff;
        margin-top: 24px;
    }
    .lowerright {
        padding-left:20px ;
        width: 728px;
        /* background-color: green; */
        }
    .lowerleft {
        margin-top: 24px;
        width: 232px;
        /* padding: 0 24px; */
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 9px;
        overflow: hidden;
    }
    }
    .contain {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    li {
        list-style: none;
    }
    .upperleft img {

        width: 84px;
        height: 84px;
        border-radius: 50%;
        /* background-color: red; */
    }
    .upperleft p {
        display: inline-block;
    }
    .upperleft :nth-child(3){
        margin-top:24px ;
        /* background-color: red; */
    }
    .upperleft :nth-child(5){
        margin-top:6px ;
    }
   
    .lowerleft .list {
        padding: 24px 24px;
        /* background-color: red; */
    }
    
    .myword{
        padding: 24px 24px 24px 36px;
        display: flex;
        justify-content: space-between;
   }
   .xiu {
    background-color: #002580!important;
    color: #fff!important;
    
   }
   /* pc */
@media  screen and (min-width:1201px){
  .main {
    width: 1366px;
  }
}

/* 平板 */
@media  screen and (max-width:1200px){
    .main {
      width: 980px;
    }
}

/deep/.el-overlay .el-overlay-dialog{
    
    // background-color: pink;
    // background-color: transparent;
}
.create p {
    
    position: absolute;
    top: 24px;
    left: 24px;
}
.create .bbbtn {
    bottom: 24px;
}
.create .inputbox {
    outline: none;
    padding-left: 12px;
    top: 71px;
    width: 372px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    border: 1.5px solid #808080;
}
.create .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.bo {
    /* vertical-align: bottom; */
}
    #del{
        position: absolute;
        top: 12px;
        right: 12px;
        display: none;
        transition: .3s;
        width: 36px;
        height: 36px;
        cursor: pointer;
    }
    .myli:hover #del
    {
        display: inline;
        transition: .3s;
    }
    .upperrighttwo {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding-left:24px;
        height: 68px;
        line-height: 68px;
        border-radius: 9px;
    }
    .ulcss {
        margin-right: 24px;
        display: flex;
    }
    .ulcss li:hover {
        font-weight: 700;
    }

    
/* 平板 */
@media  screen and (max-width:1200px){
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
        vertical-align: top;

    }
    .myli:nth-child(3n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
        background: white;
        /* vertical-align: top; */
    }
    .myli:nth-child(n+4){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
}
/* pc */
@media  screen and (min-width:1201px){
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
        vertical-align: top;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align: top;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .myli:nth-child(n+5){
        margin-top: 31.5px;
    }

    
}




    .myli span {
        padding-left: 24px;
        padding-right:24px ;
        display: block;
        background-color: #fff;
        z-index: 2;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
    }
    .delworld p {
    text-align: center;
    line-height: 95px;
}
.delworld .bbbtn {
    bottom: 24px;
}
.delworld .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
</style>