import { createStore } from 'vuex'
import createpersisted from "vuex-persistedstate"
export default createStore({
  state: {
    Themaincontent:[],
    zidong:"",
       // 每个状态的个数
       StateNumber:{
        // 空闲
        free:"",
        // 维修
        repair:"",
        // 使用
        use:"",
        // 预约
        appointment:"",
    },
     // 每个状态里面的内容
     StateContent_free:[],
     StateContent_repair:[],
     StateContent_use:[],
     StateContent_appointment:[],
     xiu:0,
     xiu2:"1-0",
    //  我的页面当前选中的内容
    choicecontext:"",
     MyWorld:[],
    //  传出token
    token:"",
    // 未读消息个数
    unread:"",
    // 首页导航栏是否隐藏
    show:"false",
    // 活动预览表单的数据
    activeruleForm:{},
    // 资源预览表单的数据
    resourcesruleForm:{},
    // 存储富文本里面的信息
    fuwenben:"",
    // 是否是编辑
    isedit:"",
    // 成果归属
    cguo:[],
    // 邀请加入
    yaoqing:[],
    abcdef:true
  },
  getters: {
    
  },
  mutations: {
    zidonga(state,zidong){
      state.zidong=zidong
    },
    show(state,Themaincontent){
      state.Themaincontent=Themaincontent;
    },
    // 每个状态的个数
    showtwo(state,Themaincontent){
           state.StateNumber.free=Themaincontent.free;
           state.StateNumber.repair=Themaincontent.repair;
           state.StateNumber.use=Themaincontent.use;
           state.StateNumber.appointment=Themaincontent.appointment;
    },
    show3(state, StateContent_free){
        state.StateContent_free=StateContent_free
    },
    show4(state,StateContent_repair){
        state.StateContent_repair=StateContent_repair
    },
    show5(state,StateContent_use){
          state.StateContent_use=StateContent_use
    },
    show6(state,StateContent_appointment){
          state.StateContent_appointment=StateContent_appointment
    },
    show7(state,xiu){
        state.xiu=xiu;
    },
    show8(state,MyWorld){
        state.MyWorld=MyWorld;
    },
    show9(state,value)
    {
        state.choicecontext=value
    },
    changgetoken(state,token){
      state.token=token;
    },
    countunread(state,value)
    {
      state.unread=value;
    },
    isshow(state,aaa)
    {
          state.show=aaa;
    },
    isshow2(state)
    {
      state.show="false"
    },
    activecontent(state,value){
      state.activeruleForm=value
    },
    resourcesruleForm(state,value){
        state.resourcesruleForm=value;
    },
    fuwenben(state,value)
    {
      state.fuwenben=value
    },
    // 查看当前状态是否编辑
    isedit(state,value){
        state.isedit=value
    },
    cguo(state,value){
        state.cguo=value
    },
    yaoqing(state,value)
    {
      state.yaoqing=value
    },
    xyz(state,xiu2){
      state.xiu2=xiu2
    },
    abcdefg(state,abcdef){
          state.abcdef=abcdef
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[
    createpersisted({
      key:"pjrp",
    })
  ]
  
})


