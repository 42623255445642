<!-- 申请使用页面 -->
<template>
<!-- 菜单栏 -->
<div class="main" style="display: flex;" @click="hahaha()">
    <ul class="rightmenu bd " >
          <div class="title">
            <img src="../../assets/ApplicationlsToUse/resources-icon-l.png" alt="图片不见啦！">
            <span>资源</span>
          </div>
          <li class="one" v-for="(p,index) in class1" :key="index" >
              <span> {{p.type}}</span>
              <ul v-if="p.id== ResourceSelection[0]">
                <li class="two rightmenu text-ellipsis"><span> {{ResourceSelection[1]}}</span></li>
              </ul>
          </li>
        
        </ul>
  <!-- 申请使用 -->
    <div class="right paddingright">
        <div class="use bd paddingside24">
                  <span class="font22 font25 ">申请使用</span>

                  <span class="font16 font25" style="cursor: pointer;" @click.stop="back()">返回</span>
        </div>
    <!-- 右上2 -->
    <!-- v-if="this.$route.query.applyreason==1" -->
    <div class="usereason" v-if="this.$route.query.applyreason==1">
      <div class="classify bd paddingside24 margintop12"   >
                  <span class="name font18" >申请理由</span>
                  <span class="import marginr24">*</span>
                  <!-- 活动类型 -->
                  <div @click.stop="pp()">
                      <div class="topmid">
                        <input class="titinput inputban" v-model="ruleForm.resource"  disabled type="text" placeholder="请选择活动">
                        <div :class="{'topimg' : !flag,'lowimg' : flag}" ></div>
                      </div>

                      
                      
                  </div>
                  <div class="fufu inputban height386" :class="{'a' : flag,'aaa' : !flag}" @click.stop="hahaha2()">
                              <input  v-model="resource" type="text" placeholder="请输入具体地址"   >

                              <div class="fufu-ridiocontent">
                                  <el-form-item > 
                                    <el-radio-group class="ridio" v-model="resource">
                                      <el-radio class="font14" v-for="p in  ReasonsforApplication" :key="p.p_id" :value="p.p_id"  :label="`${p.p_name}`" />
                                    </el-radio-group>
                                  </el-form-item>
                              </div>

                              <div class="bottom" >
                                <button class="btn3 btnblue" @click.stop="queren1()">确认</button>
                                <button class="btn3 btnwhite" @click.stop="quxiao1()">取消</button>
                              </div>
                  </div>
                
                  
      </div>
              
    </div>
  <!-- 右上3 -->
    <div class="bd margintop12"  :class="{'fufufufufufu':this.$route.query.applyreason==0}">
              <h3 class="font18 font00 paddingside24">预约时间<span class="import">*</span></h3>          
            <div class="paddingside24">

                <!-- 按日 -->
                <div v-if="this.$route.query.deviceauthority==1" style="margin-top:28px ;margin-bottom:28px;">
                  <span class="demonstration marginright48">选择时段(日)</span>
                  
                    <el-date-picker
                      v-model="ruleForm.data"
                      type="dates"
                      placeholder="请选择连续的日期"
                      :disabled-date="disabledDate"
                    />
                </div>
                <!-- 按月 -->
                <div v-if="this.$route.query.deviceauthority==2" style="margin-top:28px ;margin-bottom:28px;">
                  <span class="demonstration marginright48">选择时段(月)</span>
                  <el-date-picker
        v-model="ruleForm.month"
        type="monthrange"
        range-separator="到"
        :handleOpen="disabledDate"
        :disabled-date="disabledDate"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
      />
                </div>

                <!-- 按时段 -->
                <div  v-if="this.$route.query.deviceauthority==0" >
                  <div class="list1" style="margin-top:28px ;">

                        <div class="block">
                            <span class="marginright48 font16">选择日期</span>

                            <div style="position: relative;display: inline-block;">
                                <el-date-picker
                                v-model="ruleForm.data1"
                                type="date"
                                placeholder="请选择一天"
                                :disabled-date="disabledDate"
                                @change="Periodoftime()"
                                @focus="focusss()"
                              />
                              <div></div>
                            </div>
                            
                        
                        </div>
                  </div>

                  <div class="lilst2">

                      <div class="classify"  style="margin: 28px 0;">
                                <span class="name marginright48 font16">
                                  选择时段
                                </span>
                                <div style="position: relative;" @click.stop="mm()" >
                                  <input  class="titinput inputban" v-model="ruleForm.periodshow"  disabled type="text" placeholder="请选择时段">
                                  <!-- <div class="arrows" :class="{activity : flag2}" @click="mm()" > </div> -->
                                  <div :class="{'topimg' : flag2,'lowimg' : !flag2}"></div>
                                </div>
                                <div class="fufu2 inputban"   :class="{'aaa' : flag2,'a' : !flag2}" >
                                      <div class="fufu-content">
                                        <div  @click.stop="coco(index)" class="move font14" v-for="(item,index) in time4" :key="index"  
                                        :class='[arr[index]==1 ? "yellow":"",DisabledTimePeriod[index]==1 ? "hui":""]' v-show="index>15 && index<44"> {{item}}</div>
                                      </div>
                                  </div>
                      </div>
                      

                  </div>           
                </div>

               <div style="padding-bottom: 20px;" >
                    <el-radio-group v-model="ruleForm.radio" >
                      <el-radio :label="3" @click.prevent="changeValue(3)">我已经仔细阅读《<span class="move" style="color: #002580;" @click.stop="xianshi()">使用手册</span>》</el-radio>
                    </el-radio-group>
                </div>

              </div>
  </div>
          <el-button class="btn2 bg btnblue leftmid" style="margin-top: 48px;border: 0;" type="primary"  round @click.stop="Applicationforuse()">申请使用</el-button>
    </div>

</div>

<!-- 请仔细阅读的dialog -->
  <el-dialog
  style="border-radius: 12px;height: 196px;box-sizing: border-box;padding-bottom: 0;position: relative;"
  v-model="dialogVisible"
  width="420px"
  title=""
  align-center
  :show-close="false"
  custom-class="req"
  :before-close="handleClose"
  >
  <p class="font00 font18 leftmid">亲，请仔细阅读并勾选《使用须知》</p>
  
  <div class="bbbtn" style="display:flex ;justify-content:center;" >
    <el-button  class="btnblue move" style="margin-right: 48px;" type="primary" @click="dialogVisible = false" >确 定</el-button>
    <el-button  class="btnwhite move" @click="dialogVisible = false" >取 消</el-button>
  </div>
</el-dialog>
<!-- 显示使用手册的内容 -->
<el-dialog
  style="border-radius: 12px;position: relative; height: 600px;padding: 24px;box-sizing: border-box;"
  v-model="dialogVisible2"
  width="420px"
  :show-close="false"
  :before-close="handleClose"
  class="txt"
  center="false"
  >
  <p class="font00 font16">
      {{this.xieyi}}
  </p>
  
  <div class="bbbtn" style="display:flex ;justify-content:center;">
    <el-button type="primary" @click="dialogVisible2=false"  class="btnblue move" style="margin-right: 48px;">确 定</el-button>
    <el-button @click="dialogVisible2 = false" class="btnwhite move">取 消</el-button>

  </div>
</el-dialog>
</template>

<script >
import axios from '@/Api/axios'
import { onMounted,onBeforeMount,created, ref, nextTick } from 'vue'
import { Edit } from "@element-plus/icons-vue"
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { create } from 'lodash'
export default {
  data() {
    return{
      Edit,
      ruleForm:{
        //申请理由
        resource:"",
        // 单选按钮
        radio:"",
        // 日期
        data:[],
        // 时段
        period:[],
        // 时段下标
        period_id:[],
        // 按月
        month:"",
 
        // 按时段的日
        data1:"",
        periodshow:""
      },
      flag:true,
      resource:"",
      flag2:false,
      time4:["00:00 - 00:30","00:30 - 01:00","01:00 - 01:30","01:30 - 02:00","02:00 - 02:30","02:30 - 03:00","03:00 - 03:30","03:30 - 04:00","04:00 - 04:30","04:30 - 05:00","05:00 - 05:30","05:30 - 06:00","06:00 - 06:30","06:30 - 07:00","07:00 - 07:30","07:30 - 08:00","08:00 - 08:30","08:30 - 09:00","09:00 - 09:30","09:30 - 10:00","10:00 - 10:30","10:30 - 11:00","11:00 - 11:30","11:30 - 12:00","12:00 - 12:30","12:30 - 13:00","13:00 - 13:30","13:30 - 14:00","14:00 - 14:30","14:30 - 15:00","15:00 - 15:30","15:30 - 16:00","16:00 - 16:30","16:30 - 17:00","17:00 - 17:30","17:30 - 18:00","18:00 - 18:30","18:30 - 19:00","19:00 - 19:30","19:30 - 20:00","20:00 - 20:30","20:30 - 21:00","21:00 - 21:30","21:30 - 22:00","22:00 - 22:30","22:30 - 23:00","23:00 - 23:30","23:30 - 24:00"],
      xuanzhong:-1,
      peneds: ['1'],
        // 资源分类
        class1:[],
        // 资源选中
        ResourceSelection:{},
           //时段多选数组下标
           selection:[],
           arr:[],
           newyear:0,
           newmonth:0,
           newday:0,
          //  申请理由
          ReasonsforApplication:[],
          // 每一天的禁用时段 1是禁用
          DisabledTimePeriod:[],
          // 禁用的月
          ForbiddenMonth:[],
          // 禁用的日
          ForbiddenDay:[],
          // 最大天数
          maxday:0,
          dialogVisible:false,
          dialogVisible2:false,
          xieyi:"",
          // 最多约的月份
          maxmonth:0,
      
          maxhour:0,

    }
    },

  methods:{
    focusss(){
      this.hahaha()
    },
    hahaha2(){
        this.flag=false
    },
    hahaha(){
     
        this.flag=true
        this.flag2=false
  
    },
    xianshi(){
        this.dialogVisible2=true
    },

    disabledDate(time) {

    //   let abc=useRouter()
    // let HowtoApply=ref("按月");
   //  禁用的月
       if (  sessionStorage.getItem("deviceauthority")==1)
       { 
        
           // 禁用日实现方式
              if(time.getTime()<Date.now()-8.64e7){
           
                return true
              }

              const timeyear = time.getFullYear(); // 获取时间选择器的年份 
             let timemonth = time.getMonth() + 1; // 获取时间选择器的月份
         if (timemonth >= 1 && timemonth <= 9) {
           timemonth = "0" + timemonth;
         }
         const elTimeData = timeyear.toString() + timemonth.toString(); 
              for(let i = 0 ;i<this.ForbiddenDay.length;i++){
              
                if(new Date(this.ForbiddenDay[i]).getTime() === time.getTime()){
                 
           
                  return true
                }
              }
            return false
       }
      else if (sessionStorage.getItem("deviceauthority")==2)
      {
     
           // 禁用月份实现方式
           const timeyear = time.getFullYear(); // 获取时间选择器的年份 
         let timemonth = time.getMonth() + 1; // 获取时间选择器的月份
         let date = new Date();
           let b=date.getFullYear().toString()+(date.getMonth()+1).toString()
         if (timemonth >= 1 && timemonth <= 9) {
           timemonth ="0"+  timemonth;
         }
         const elTimeData = timeyear.toString() + timemonth.toString(); 
          //小于指定时间都不可选

          if (b.length==5)
          {
            let c=b
            b=c.substring(0,4)+'0'+c.substring(4,5)

          }
         if (elTimeData < b )
         {

          return true
         }
        

         for (let i=0;i<this.ForbiddenMonth.length;i++)
         {
           if (this.ForbiddenMonth[i]==elTimeData)
           {
             return true
           }
         }
        return false

      }
      else if(sessionStorage.getItem("deviceauthority")==0)
      {
      
        if(time.getTime()<Date.now()-8.64e7){
              
                return true
              }
      }
    

},
    //根据date获取禁用时段 
    Periodoftime(){
     
     this.flag=true
     this.flag2=false
      this.ruleForm.period=[];
      for (let i=0;i<48;i++)
      {
        this.arr[i]=0;
      }
        let a={format:1,p_d:"",key:""}
        this.flag2=false;
        a.p_id=this.$route.query.paramName;
        a.key=this.formatDateTime(this.ruleForm.data1).substr(0,10);

        axios.post("PC/appointment/hour",a).then(res=>{
        
            this.DisabledTimePeriod=res.data.data;
        })
    },
    // 申请使用
    Applicationforuse(){
      let Applytousethebackend={w_id:"",p_id:"",begin_time:"",end_time:"",format:"",reason:""}
      if (this.ruleForm.radio!=3)
      {
        this.dialogVisible=true;
          return ;
      }
      else if (this.ruleForm.resource=="" && this.$route.query.applyreason==1)
      {
        ElMessage({
              message: '请选择申请理由',
              type: 'warning',
          })
          return ;
      }
    
      else{
        Applytousethebackend.w_id=this.$cookies.get("selectValue").id;
        if (this.$route.query.applyreason==1)
        {
          
          for (let i=0;i<this.ReasonsforApplication.length;i++)
          {
            if (this.ruleForm.resource==this.ReasonsforApplication[i].p_name)
            {
              Applytousethebackend.reason=this.ReasonsforApplication[i].p_id
            }
          } 
        
        }
        Applytousethebackend.p_id=this.$route.query.paramName;
        // 按月
        if (this.$route.query.deviceauthority==2)
          {
            
            Applytousethebackend.format=3
    
                  if (this.ruleForm.month=="" || this.ruleForm.month==[])
                  {
                    ElMessage({
                       message: '请先选择时段(月)',
                        type: 'warning',
                      })
                      return;
                  }
                 
                  else
                  {
                   let year1=new Date(this.ruleForm.month[0]).getFullYear()
                   let year2=new Date(this.ruleForm.month[1]).getFullYear()
                   let month1=new Date(this.ruleForm.month[0]).getMonth()+1
                   let month2=new Date(this.ruleForm.month[1]).getMonth()+1
               
                    let yearlong=year2-year1;
                    let longaaaa=0
                    if (yearlong==1){
                        longaaaa=(12-month1)+ month2+1
                    }
                    else if(yearlong>1){
                        longaaaa=(yearlong-1)*12
                    }
                    else {
                      longaaaa=(month2-month1)+1
                    }
                    if (longaaaa>this.maxmonth)
                    {
                      ElMessage({
                       showClose: true,
                       message: '最多选择'+this.maxmonth+"个月,请重新选择" ,
                       type: 'warning',
                   })
                   return ;
                    }
                      if (month1>=1 &&  month1<=9)
                      {
                        month1='0'+month1
                      }
                      if (month2>=1 && month2<=9){
                        month2='0'+month2
                      }
                      Applytousethebackend.begin_time=year1+'-'+month1
                      Applytousethebackend.end_time=year2+'-'+month2 
                   

                  }
               
          }
          else if (this.$route.query.deviceauthority==1)
          {
            // 按日
            Applytousethebackend.format=2
            if (this.ruleForm.data=="")
            {
              ElMessage({
                showClose: true,
                 message: '请先选择申请的日期',
                type: 'warning',
              })
              return;
            }
            else{
              if (this.ruleForm.data.length==1)
              {
                      let x=this.formatDateTime(this.ruleForm.data[0])
                      Applytousethebackend.begin_time=x.substr(0,10);
                      Applytousethebackend.end_time=x.substr(0,10);
              }
              else if (this.ruleForm.data.length> this.maxday)
              {
                    ElMessage({
                       showClose: true,
                       message: '最多选择'+this.maxday+"天,请重新选择" ,
                       type: 'warning',
                   })
                    return;
              }
              else
              {
                let a=[];
                for(let i=0;i<this.ruleForm.data.length;i++)
                {
                      a[i]=this.formatDateTime(this.ruleForm.data[i])
                }
                a=a.sort();
                // 判断是否连续
                let flag=true;
                for (let i=0;i<a.length-1;i++)
                {
                      if (new Date(a[i]).getTime()+86400000!=new Date(a[i+1]).getTime())
                      {
                            flag=false;
                      }
                }
                if (flag==false)
                {
                  ElMessage({
                          showClose: true,
                         message: '按日申请请选择连续的日期',
                         type: 'warning',
                      })
                      return;
                }
                Applytousethebackend.begin_time=a[0].substr(0,10);
                Applytousethebackend.end_time=a[a.length-1].substr(0,10);
              }
               
            }
          }
          else if (this.$route.query.deviceauthority==0)
          {
            // 按时段
              Applytousethebackend.format=1
            if (this.ruleForm.data1=="" || this.ruleForm.period=="" )
            {
              ElMessage({
                showClose: true,
                 message: '请先选择申请的日期和时段',
                type: 'warning',
              })
              return;
            }
           
            else
            {
             
                let a=this.ruleForm.period_id.sort();
                Applytousethebackend.begin_time=this.formatDateTime(this.ruleForm.data1).substr(0,11)+this.time4[a[0]].substr(0,5)
                Applytousethebackend.end_time=this.formatDateTime(this.ruleForm.data1).substr(0,11)+this.time4[a[a.length-1]].substr(8,5);
            }

          }
      }

     axios.post("appointment/insert",Applytousethebackend).then(res=>{
      
      if (res.data.data==true)
        {
          ElMessage({
              showClose: true,
              message: '申请使用成功',
              type: 'success',
                 })
                 this.back()
        }
        else if (res.data.msg= "不能多次预约"){
          ElMessage({
          showClose: true,
          message: '不能多次预约',
          type: 'warning',
            })
            }
        else
        {
          ElMessage({
          showClose: true,
          message: '申请使用失败',
          type: 'warning',
            })
        }
     })
    },
    // 转时间格式
    formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
    },
    // 通过申请理由获取申请id
    reasontoid(reason)
    {
      for(let i=0;i<this.ReasonsforApplication.length;i++)
      {
            if (this.ReasonsforApplication[i].p_name==reason)
            {
              return this.ReasonsforApplication[i].p_id
            }
      }
    },
    changeValue(val){
      val == this.ruleForm.radio ? this.ruleForm.radio = '' : this.ruleForm.radio = val
    },
    coco(e){
      this.ruleForm.periodshow=""
        if (this.ruleForm.data1=="")
        {
          ElMessage({
              message: '请先选择日期',
              type: 'warning',
           })
        }
        else
        {
      if ( this.selection.includes(e)==false)
      {
      if (this.selection=="" || this.selection==null)
      {
        this.selection.push(e);
      }
      else
      {
        this.selection.sort();
        let max=this.selection[this.selection.length-1];
        let min=this.selection[0];
        if (e==max+1 || e==min-1)
        {
          this.selection.push(e);
        }
       
      }
    }
    else
    {
      let aaa=[];
      this.selection.forEach(item=>{
        if (item<e)
        {
          aaa.push(item)
        }
      })
      this.selection=aaa;
    }

        for (let i=0;i<this.arr.length;i++)
        {
            this.arr[i]=-1;
        }
    for (let i=0;i<this.selection.length;i++)
    {
      this.arr[this.selection[i]]=1;
    }
       this.xuanzhong=e
       this.ruleForm.period=[]
       this.ruleForm.period_id=[]
      for (let i=0;i<48;i++)
      {
       
        if (this.arr[i]==1)
        {
        
          this.ruleForm.period.push(this.time4[i])
          this.ruleForm.period_id.push(i)
        }
        
      }
     
      let a=this.ruleForm.period[0].substr(0,7)
      let b=this.ruleForm.period[this.ruleForm.period.length-1].substr(7,6);
   
      this.ruleForm.periodshow=""
      this.ruleForm.periodshow=a+b
     
    }
    },
    pp(){
    
        this.flag=!this.flag
        this.flag2=false
      },
      mm(){
     
        this.flag2=!this.flag2
        this.flag=true
      },
    back(){
      this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:this.$route.query.paramName,page:1}})
    },
   
      queren1(){
        this.flag=true
        this.ruleForm.resource=this.resource;
       
       

      },
      quxiao1(){
       
        // this.ruleForm.resource="";
        // this.resource="";
        this.flag=true
      },
  },
  mounted(){
    axios.get("duration/"+this.$route.query.paramName+"/app").then(res=>{
    
this.maxday=res.data.data.day_duration;
this.maxmonth=res.data.data.month_duration;
this.maxhour=res.data.data.hour_duration
    })
    // if (this.$route.query.deviceauthority==1)
    // {
    //       axios.get("duration/"+this.$route.query.paramName+"/app").then(res=>{

    //         this.maxday=res.data.data.day_duration;
    //       })
    // }
    sessionStorage.getItem("abc",this.$route.query.deviceauthority);
      axios.get("PC/type/getTypeByPid/"+this.$route.query.paramName).then(res=>{
        this.class1=res.data.data.types;
        this.ResourceSelection=res.data.data.p;//
      })
      var myDate=new Date();
      this.newyear=myDate.getFullYear();
      this.newmonth=myDate.getMonth();
      this.newday=myDate.getDay();
      // 获取后端申请理由
      axios.get("PC/member/app/"+this.$cookies.get('selectValue').id).then(res=>{
     
          this.ReasonsforApplication=res.data.data;
      })
      // 获取协议
      axios.get('notice').then(res=>{
            this.xieyi=res.data.data[0].context;
      })
    
  },
  created(){
    // 按天禁用
    axios.post("PC/appointment/days",{"p_id":useRouter().currentRoute.value.query.paramName}).then(res=>{
        
               this.ForbiddenDay=res.data.data;
  
       
    })
  //  按月禁用
    axios.post("PC/appointment/months",{"p_id":useRouter().currentRoute.value.query.paramName}).then(res=>{
              
                  this. ForbiddenMonth=res.data.data;
              
                   // disabledDate()
                 
    })
  }

 
  
}
// 时间选择器
</script>
<style scoped lang="scss">
/deep/ .el-input__suffix {
  margin-right: 0!important;
  width: 30px;
}
.el-button+.el-button {
    margin-left: 0;
}
.req p {
  top: 0;
  text-align: center;
}
.req .bbbtn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
}
.req .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.txt p {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 372px;
  height: 492px;
  overflow-y: auto;
}
.txt .bbbtn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
}
.txt .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}

.import {
  margin-right: 12px;
}
/deep/.el-date-table td.current:not(.disabled) .el-date-table-cell__text {
  background-color: #002580!important;
  --el-datepicker-active-color: #002580!important;
  --el-datepicker-hover-text-color: #002580!important;
}
.fufu  {
  position: relative;
  top: 53px;
  transform: translateX(-100%);
}
.fufu2 {
  position: relative;
  top: 53px;
  transform: translateX(-100%);
}
/deep/ .fufu .el-radio {
  width: 100%;
  height: 38px;
}
/deep/ .el-radio{
  height: 0;
  color:#000c1c!important;
  --el-radio-input-border-color-hover: #002580!important;
}
/deep/ .el-radio__label {
  padding-left: 12px!important;
}
/deep/ .el-radio__inner::after {
  width: 6px!important;
  height: 6px!important;
  left: 51%;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color:transparent!important;
  background:#002580!important;
}
/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #000c1c!important;
}
// 日历样式
/deep/ .el-input__prefix {
  display: none!important;
}
 /deep/ .el-input {
   --el-input-hover-border:#d9d9d9!important;
    --el-input-focus-bordertransparent:#d9d9d9!important ;
    --el-input-focus-border: #d9d9d9!important;
    --el-input-focus-border-color: #d9d9d9!important;
    --el-input-border:2px solid #d9d9d9!important;
    --el-border-radius-base: 9px!important;
    --el-input-bg-color: #f2f2f2!important;
    --el-input-border-color: #d9d9d9!important;
    --el-input-border: 2px!important;
    --el-input-placeholder-color: #808080;

    
 }
 /deep/ .el-input__wrapper {
    box-shadow: 0 0 0 .8px var(--el-input-border-color,var(--el-border-color)) inset;
 }
/* pc */
@media (min-width: 1201px) {
    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__wrapper {
      width: 324px;
      height: 36px;
      line-height: 36px;
      z-index: 0;
    }
    .main {
      margin: 0 auto;
      width: 1366px;
      padding-top:24px ;
    }
    .rightmenu {
      width: 240px!important;
    }
    .paddingright {
      padding-left: 39.5px;
    }
    .inputban {
    width: 324px;
  }
  .inputquan {
    width: 994px;
  }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 900px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 900px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
 /* 平板 */
@media (max-width:1200px) {
    .main {
      margin: 0 auto;
      width: 980px;
      padding-top:24px ;
    }
    .rightmenu {
      width: 232px;
    }
    .paddingright {
      padding-left: 20px;
    }
    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__wrapper {
      width: 260px;
      height: 36px;
      line-height: 36px;
    }
    .inputban {
    width: 260px;
  }
  .inputquan {
    width: 636px;
  }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
    .rightmenu {
      overflow: hidden;
      /* background-color: pink; */
    }

    .rightmenu img {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      width: 20px;
      height: 20px;
    }
    .title {
      position: relative;
      height: 64px;
      line-height: 64px;
      /* background-color: red; */

    }
    .title {
        padding-left: 45px;
    }
    .one {
      padding-left: 40px;
      min-height: 64px;
      line-height: 64px;
      /* background-color: #00b2a2; */
    }
    .two {
      position: relative;
      transform: translateX(-40px);
      padding-left: 56px;
      padding-right: 20px;
      box-sizing: border-box;
      height: 64px;
      line-height: 64px;
      background-color: #002580;
      color: #fff;
    }

    .right {
      flex: 1;
      // background-color: #00b2a2;
    }
    .use {
      height: 68px;
      line-height: 68px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
    }
    .classify {
      /* padding: 0 24px; */
      box-sizing:border-box;
      height: 68px;
      line-height: 68px;
      display: flex;
    }
    .classify .inputcss {
         margin-left: 24px;
        height: 36px;
        line-height: 36px;
        /* background-color: red; */
    }
    .classify .inputcss input {
      width: 100%;
      height: 100%;
      border-radius: 18px;
      outline: none;
      border: 1px solid #d9d9d9;
      background-color: #f2f2f2;
    }
    h3 {
      box-sizing: border-box;
      height: 48px;
      line-height: 48px;
      border-bottom: 1px solid #e6e6e6;
      box-sizing: border-box;
      font-weight: 400;
    }

    /* .fufufufufufu{
      
      margin-top: 100px;
    }
  .rightmenu {
    background-color: pink;
  }
  .rightmenu img {
    width: 25px;
    height: 25px;
  }
  .rightmenu .title {

    width: 100%;
    height: 50px;
    line-height: 50px;

    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .use {
    position: relative;
    left:330px;
    top:90px;

    width: 1150px;
    height: 80px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 40px;
    box-sizing: border-box;
    font-size: 25px;
    border-radius: 20px;
    color: #002580;
    background-color: white; 
  }
  
  .usereason {
    width: 1150px;
    height:70px;
    background-color: white;
    position: relative;
    left: 330px;
    top: 70px;
    border-radius: 20px;
    margin: 40px 0px 80px 0px;
  }
  .usereason .classify {
    display:flex ;
    padding-left: 20px; 
    font-size: 15px; 
    margin: 20px 0;
    line-height: 70px;
  }
  .usereason .classify .name {
    font-size: 20px;
    font-weight: 500; 
    display: inline-block;
    height: 30px; 
    line-height: 30px; 
    margin-right:10px;
  }
  .classify input {
    width:400px; 
    height: 30px; 
    margin-left: 20px;
    padding-left: 10px; 
    box-sizing: border-box;
    outline: none; 
    border-radius: 5px; 
    border: 2px solid #f0f0f0;
  }
  .arrows {
  width: 10px;
  height: 10px; 
  border-right: 2px solid #002680; 
  border-bottom: 2px solid #002680; 
  position: absolute;
  top:10px;
  right:15px;
  transform: rotate(43deg);
  transform-origin:bottom bottom;
}
.activity {
  transform: rotate(223deg);
  position: absolute;
  top:13px;
  right:15px;
}
.fufu {
  position: absolute; 
  left:145px;
  top:55px; 
  display: flex;
  flex-direction:column; 
  box-sizing:border-box; 
  border: 2px solid #b0b0b0; 
  width: 400px;
  height: 400px; 
  background-color: #fff;
  z-index: 5;
}
.fufu .seach {
  padding: 20px 0;
}
.fufu .seach input {
  width:80% ; 
  height:50px;
  background-color: #f5f5f5;
  border-radius: 25px; 
  outline: none; 
  border: none; 
  padding-left: 20px; 
  box-sizing: border-box;
} */















  
  /* .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

.el-radio {
  margin-right: 0!important;
}


.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 50px;
  text-align: left;;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.yeyeye {
  background-color: #fed71a;
}
.disuse {
  background-color: #f2f2f2;
  color: #fff;
  cursor:no-drop;
  pointer-events: none;
} */

</style>