<template>
  <router-view/>
</template>
<script>
export default {
  mounted(){
    
    document.body.style.setProperty('--el-color-primary', '#002580');
    
  }
}
</script>
<style lang="scss">
// .myli img {
//     display: block; 
//     width: 100%; 
//     height: 100%; 
//     object-fit: contain; 
//     object-position: center center; 
// }
.el-dialog input:focus{
border: 1.5px solid #002580!important;
transition: 500ms;
}
.fufu input:focus{
border: 1.5px solid #002580!important;
transition: 500ms;
}
input {
  outline: none;
}
input:hover {
  background-color: #f8f8f8!important;
  transition: .3s;
}
div {
  cursor: default;
}
span {
  cursor:default;
}
 *
{
        margin: 0;
        padding: 0;
}
li {
  // list-style: none;
}
html,body {
  width: 100%;
  height: 100%;
  background-color: #e6e6e6!important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #000c1c;
  background-color: #e6e6e6;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
textarea {
  resize: none;
  padding: 0 12px;
  line-height: 36px;
  box-sizing: border-box;
  border-radius: 9px;
  border: 1.5px solid #d9d9d9;
  background-color: #f2f2f2;
  outline: none;
}
/* pc */
// @media (min-width: 1201px) {
//     .main {
//       margin: 0 auto;
//       width: 1366px;
//       padding-top:24px ;
//     }
//     .rightmenu {
//       width: 240px!important;
//     }
//     .paddingright {
//       padding-left: 39.5px;
//     }
//     .inputban {
//     width: 324px;
//     }
//     .inputquan {
//     width: 994px;
//     }
//   .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 295px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .long .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 900px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .topimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 295px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/top.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .long .topimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 900px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/top.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//}
 /* 平板 */
// @media (max-width:1200px) {
//     .main {
//       margin: 0 auto;
//       width: 980px;
//       padding-top:24px ;
//     }
//     .rightmenu {
//       width: 232px;
//     }
//     .paddingright {
//       padding-left: 20px;
//     }
//   .inputban {
//     width: 260px;
//   }
//   .inputquan {
//     width: 636px;
//   }
//   .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 232px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .long .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 232px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .long .topimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 232px;
//     width: 16px;
//     height: 9px;
//     background-image: url(./assets/top.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
// }

.imgmid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.imgcss {
  position: absolute; /* 绝对定位，以便于图片元素宽高被拉伸铺满容器 */
  top: 50%; /* 上边沿定位在垂直中央 */
  left: 50%; /* 左边沿定位在水平中央 */
  transform: translate(-50%, -50%); /* 以中心点为基准点水平垂直方向分别移动图片元素宽高的一半 */
  width: 100%; /* 保持原始宽高比，宽度自适应 */
  height: 100%; /* 高度铺满容器，拉伸或压缩宽度保持原始宽高比 */
  object-fit: cover; /* 把元素的内容刚好完全铺满容器 */
}
.topmid {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.leftmid {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.leftmid2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// 禁用
.hui {
  background-color: #f2f2f2;
  color: #fff;
  cursor:no-drop;
  pointer-events: none;
}
.green {
  background-color: #8bc34a;
}
.yellow {
  background-color: #ffd800;
}
.red {
  background-color: #c70202;
}
.imgmid {
  width: auto;height: auto;max-width:100%; position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);
}

.height386{
  height: 386px;
}

.margintop12 {
  margin-top: 12px;
}
.margintop24 {
  margin-top: 24px;
}
.margintop28 {
  margin-top: 28px;
}
.marginright12 {
  margin-right: 12px;
}
.margintop64 {
  margin-top: 64px;
}
.marginright24 {
  margin-right: 24px;
}
.marginright48 {
  margin-right: 48px;
}

.paddingside24 {
  padding: 0 24px;
  box-sizing: border-box;
}

.fontweight {
  font-weight: 700;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px!important;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.font17 {
  font-size: 17px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font28 {
  font-size: 28px;
}
.font30 {
  font-size: 30px;
}
.font80 {
  color: #808080;
}
.font00 {
  color: #000c1c;
}
.font25{
  color: #002580;
}
.fontgreen {
  color: #8bc34a;
}

button {
  border: 0;
}
.fufu {
  // position: absolute;
  // top: 0;
  // left: 0;
  padding:24px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #808080;
  z-index: 10096;
  // border-radius: 9px;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}
.fufu input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  line-height: 36px;
  border: none;
  padding: 0 12px;
  margin-bottom: 12px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}
.fufu .fufu-ridiocontent{
  height: 228px;
  overflow-y: auto;
  // background-color: pink;
}
.fufu .bottom {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  // background-color: green;
}
.fufu2 {
  padding:0 24px 12px 24px;
  box-sizing: border-box;
  height: 240px;
  overflow-y:auto ;
  background-color: #fff;
  border: 1px solid #808080;
  z-index: 10096;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}
.fufu2 .fufu-content {
  display: flex;
  flex-wrap: wrap;
}
.fufu2 .fufu-content div{
  margin-top: 12px;
  text-align: center;
  display: inline-block;
  width: 45%;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
}
.fufu2 .fufu-content div:nth-child(2n) {
  margin-left: 20px;
}
.move {
  cursor: pointer!important;
}
.text-ellipsis{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.dialogcss {
  width: 420px;
  height: 195px;
}
// 大标题样式
.title {
  display: block;
  // height: 48px;
  line-height: 48px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.bd {
  border-radius: 9px;
  // overflow: hidden;
  background-color: #fff;
}
/deep/ .el-checkbox {
    --el-checkbox-checked-icon-color: #002580!;
    --el-checkbox-checked-bg-color: #fff;
    --el-checkbox-checked-input-border-color: #002580;
    --el-checkbox-input-border-color-hover: #002580;
}
// 允许换行
.huan {
    word-break: normal;
    width: auto;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}
// 红色※
.import {
  display: inline-block;
  height: 100%;
  color: red;
  position: relative;
  top: -6px;
}
// 输入框
.titinput {
  padding: 0 12px;
  box-sizing: border-box;
  height: 36px;
  border-radius: 9px;
  line-height: 36px;
  border: 1.5px solid #d9d9d9;
  background-color: #f2f2f2;
  outline: none;
}
.titpadding {
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
}
.timetit {
  display: block;
  width: 100%;
  height: 48px;
  line-height: 48px;
  border-bottom: 2px solid #f2f2f2;
}
.btnbottomres {
  text-align: center;
  // background-color: pink;
}
.btnblue {
  background-color: #002580!important;
  color: #fff!important;
}
.btnblue:hover {
  transition: .3s;
  background-color: #023cc7!important;
}
.btntrs {
  background-color:transparent!important;
  color:#000c1c!important;
  border: 2px solid #002580!important;
}
.btntrs:hover {
  transition: .3s;
  border: 2px solid #023cc7!important;
}
.btnwhite {
  background-color: #fff!important;
  border: 2px solid #808080!important;
  color: #808080!important;
}
//正常按钮大小
.btn2 {
  width: 128px!important;
  height: 48px!important;
  border-radius: 24px!important;
  outline: none!important;
  border: none;
}
.btn3 {
  // font-size: 15px;
  width: 90px;
  height: 36px;
  border-radius: 18px;
  outline: none;
  border: none;
}
button {
  cursor: pointer;
}
.bg {
  background-color: #002580!important;
  color: #fff!important;
}
// 清除浮动
.clearfix::after{
  content:'';
  display:black;
  height:0;
  clear:both;
  visibility:hidden;
}
    // height: 16px; 
    // color: #000c1c !important;
    // --el-radio-input-border-color-hover: #002580!important;

// 单选框自定义样式
// /deep/ .el-radio{
//   height: 0!important;
//   color:#000c1c!important;
//   --el-radio-input-border-color-hover: #002580!important;
// }
// /deep/ .el-radio__label {
//   padding-left: 12px!important;
// }
// /deep/ .el-radio__inner::after {
//   width: 6px!important;
//   height: 6px!important;
// }
// /deep/ .el-radio__input.is-checked .el-radio__inner {
//   border-color:transparent!important;
//   background:#002580!important;
// }
// /deep/ .el-radio__input.is-checked+.el-radio__label {
//   color: #000c1c!important;
// }
// 日历样式
/deep/ .el-input__prefix {
  display: none!important;
}
 /deep/ .el-input {
   --el-input-hover-border:#d9d9d9!important;
    --el-input-focus-bordertransparent:#d9d9d9!important ;
    --el-input-focus-border: #d9d9d9!important;
    --el-input-focus-border-color: #d9d9d9!important;
    --el-input-border:2px solid #d9d9d9!important;
    --el-border-radius-base: 9px!important;
    --el-input-bg-color: #f2f2f2!important;
    --el-input-border-color: #d9d9d9!important;
    --el-input-border: 2px!important;
 }

//  添加资源活动页面
.jia,.jian {
  transform: translateX(-11px) translateY(-6px);
  width: 48px;
  height: 48px;
  vertical-align: middle;
}
.jia {
  transform: translateX(-11px) translateY(-1px);
}
.content {
  display: flex;
}
.contentright {
  flex: 1;
}
.contentright p{
  display: flex;
  align-self:center;
  // background-color: pink;
}
.more {
  line-height: 48px;
}
.fontt {
  margin-top: 12px;
}
.topbtn {
  position: fixed;
  bottom: 90px;
  right: 60px;
}
//详细信息块
.messagelist {
  min-height: 36px;
  line-height: 36px;
  // background-color: green;
  margin-top: 28px;
}
.messagelist span {
  margin-right: 50px;
}
.flexone {
  display: flex;
  justify-content: space-between;
}
.long {
  position: relative;
  flex: 1;
}
.long input {
  flex: 1;
}
// .overhid {
//   overflow: hidden!important;
// }
.a{
      // display: none;
      visibility: hidden;
      opacity: 0;
      transition: .3s;
    }
    .aaa{
      opacity: 1;
      visibility: visible;
      // display: block;
      transition: .3s;
    }
.seach img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    left: 12px;
    z-index: 2;
}

</style>
