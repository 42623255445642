<template>
 <!-- 首页导航界面 -->
  <div class="common-layout">
      <el-header id="HomePageNavigationBar" :style="{'min-width':screen+'px'}" v-if="(this.$store.state.show==false)">
          <div class="con">
              <img class="logo move" @click="logoto()" src="../assets/logo.png" alt="图片不见啦！">
              <div id="homepagemenus">
                    <router-link class="homepagelink" active-class="active" to="/">发现</router-link>
                    <router-link class="homepagelink" active-class="active" to="/homepage/mypage/myhomescreen">我的</router-link>
                    <router-link class="homepagelink" active-class="active" to="/homepage/laboratorypage">实验室</router-link>        
              </div>
              <!-- 右边三个 -->
              <img src="../assets/seach.png" class="seach move" alt="图片不见啦！" @click="SearchTheJump()">
              <img src="../assets/message.png" class="message move" alt="图片不见啦！"  @click="JumpMessage()">
                <div class="user move " v-if="this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue')!=''">
                  <img class="imgmid" v-if="this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue')!='' && this.$cookies.get('selectValue').avatarUrl.length>17  " :src="this.$cookies.get('selectValue').avatarUrl " alt="" @click="showpersonalinformation()">
                  <img class="imgmid" v-if="this.$cookies.get('selectValue')!=null && this.$cookies.get('selectValue')!='' && this.$cookies.get('selectValue').avatarUrl.length<=17" :src="getServerUrl()+'/1image/'+ this.$cookies.get('selectValue').avatarUrl" alt="" @click="showpersonalinformation()">
                </div>
                <!-- 登入 -->
              <p class="fonttext move" v-if="this.$cookies.get('selectValue')==null"  @click="into()">登录</p>
              <!-- 红点 -->
              <p v-if="(this.$store.state.unread!=0 && this.$cookies.get('selectValue')!=null)" class="messagecount font14">{{this.$store.state.unread}}</p>
          </div>
      </el-header>
          <!-- 首页下面的显示栏 -->
      <el-main   :class="[this.$store.state.show==false? 'abcd':'abcde']">
          <router-view/>
      </el-main>
  </div>
  
  <!-- </div> -->
</template>

<script>
  import router from '@/router';
  import axios from '@/Api/axios'
export default{
  data(){
    return{
      login:"",
      // 获取屏幕分辨率
      screen:0,
      screenheight:0
    }
  },
  methods: {
    getServerUrl(){ 
    
      return axios.getServerUrl();
 },
    into(){
      router.push({
        name:"login"
      })
    },
    SearchTheJump(){
      router.push({
        name:"findpage"
      })
    },
    logoto(){
      router.push({
        name:"firstpage"
      })
    },
    showpersonalinformation(){
            router.push({
                name:"subpersonalInformati"
            })
            
        },
        JumpMessage(){
            router.push({
              name:"unreadmessages"
            })
        }
   },
    created(){
      
  
 
        // 自动登入
        // axios.post("PC/wechat_user/autoLogin",{openid:this.$store.state.zidong}).then(res=>{
       
        // this.login=res.data.data;
      
        //   if (res.data.msg=="登陆成功")
        //   {
           
        //     this.$cookies.set('selectValue', res.data.data,{ expires: 0 })
       
        //     axios.get("PC/message/"+this.$cookies.get("selectValue").id).then(res => {
       
        //     this.$store.commit("countunread",res.data.data.length)
        //     localStorage.setItem("isToken","aaa")
        //     })
         
        //   }
        //   else
        //   {
        //           this.$cookies.set('selectValue', "","1d"),
        //         localStorage.setItem("isToken","")
        //           this.$store.commit("countunread",0)
        //   }
          
        // })
      
    
   
      //   console.log("eee")
      //       this.$cookies.set('selectValue', "","1d"),
      // localStorage.setItem("isToken","")
      // this.$store.commit("countunread",0)
   
      // console.log(this.login)
    },
   mounted(){
   
    this.screen=window.screen.availWidth-20
    this.screenheight=document.body.clientHeight-48;
    // console.log(this.screenheight)
    // if (localStorage.getItem("isToken")!='' && this.$cookies.get("selectValue")!=null)
    // {
     
    //   console.log("aa")
    //   axios.get("PC/message/"+this.$cookies.get("selectValue").id).then(res => {
    //       console.log(res)
    //         this.$store.commit("countunread",res.data.data.length)
              
    //         })
    // }
    // else
    // {
    //   this.$cookies.set('selectValue', "","1d"),
    //   localStorage.setItem("isToken","")
    //   this.$store.commit("countunread",0)
    // }
   }
   
}
</script >

<style scoped lang="scss">
.messagecount {
    position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    top: 3px;
    right: 60px;
    padding: 1px 6px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    background-color: #c70202;
    color: #fff;
}
.el-header {
  --el-header-padding: 0;
}
    // 电脑屏幕下
    @media (min-width: 1201px) {
      .con {
        width: 1366px;
        // background-color: red;
      }
    }
    // 平板
    @media (max-width: 1200px) {
      .con {
        width: 980px;
        // background-color: yellow;
      }
    }
    .con {
      display: flex;
      position: relative;
      height: 48px;
      margin: 0 auto;
    }
    .logo {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        margin-right: 128px;
    }
    .seach {
      height: 48px;
      margin-right: 24px;
    }
    .message {
      height: 48px;
      margin-right: 24px;
    }
    .abcd{
      height: calc(100vh - 48px);
      padding: 0!important;
    }
    .abcde{
      height: calc(100vh);
      padding: 0!important;
    }
    .common-layout {
      background-color: #e6e6e6;
    }
    #homepagemenus {
      flex: 1;
      height: 48px;
      display: inline-block;
    }
    .user {
      display: flex;
      position: relative;
      width: 48px;
      height: 48px;
      text-align: center;
    }
    .user img {
      
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
    #HomePageNavigationBar
    {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 48px;
        background-color: #002580;
        color: #fff;
        line-height: 48px;
        z-index: 3;
    }
   .homepagelink
    {
      display: inline-block;
    //  color: #ffd800;
    color: #fff;
     text-decoration: none;
     display:inline-block;
     width: 134px;
     text-align: center;
     height: 48px;
     line-height: 48px;
     font-size: 16px;
    }
    .fonttext {
      display: inline-block;
      width: 48px;
      height: 48px;
      text-align: center;
      cursor: pointer;
      // background-color: pink;
    }
    // #homepagemenus {
    //   display: inline-block;
    // }
  //   #homepagemenus
  //   {
  //      width: 500px;
  //     position: relative;
  //     left: 290px;
      
  //   }
    #homepagemenus>.active
    {
      color: #ffd800;
      font-weight: 700;
    }
  //   body{
  //     background-color: #f2f2f2;
  //   }
  //   .el-container {
  //     display: block;
  //   box-sizing: border-box;
  //   min-width: 0;
  //   }
</style>