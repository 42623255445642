<template>
    <div style="border: 1px solid #ccc">
 
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <!-- 默认内容 编译器的配置 -->
      <!-- {{wang}} -->
      <Editor
        style="height: 500px; overflow-y: hidden;"  
        v-model="valueHtml" 
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
        @onChange="onchange"
      />
    </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import axios from 'axios'
export default {
  components: { Editor, Toolbar },
  props:{
    wang:{
      type:String,
      default: () => "",
    },
 
  },
  setup(props) {
 
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    let valueHtml = ref('')
       // 模拟 ajax 异步获取内容
       onMounted(() => {
        setTimeout(() => {
            valueHtml.value = localStorage.getItem("abcde")
            localStorage.setItem("abcde",valueHtml.value)
        }, 5)
    })


    const toolbarConfig = {
      toolbarKeys:[
          'headerSelect', 
          'fontSize', 
          'fontFamily',
          "bold",
          'underline',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'numberedList', 
          'bulletedList', 
          // 'insertLink',
        // 菜单组，包含多个菜单
        {
            key: 'group-image', // 必填，要以 group 开头
            title: '图片', // 必填
            menuKeys: ['uploadImage', 
                'insertImage', 
                'deleteImage', 
                'editImage', 
                'viewImageLink']
        },
        {
            key: 'group-video',
            title: '视频',
      
            menuKeys: ['insertVideo',
                'uploadVideo']
        },
           'blockquote',
          'undo', 
          'redo',
          'fullScreen', 
         
        ]
    }
    const editorConfig = { placeholder:"请输入内容……",MENU_CONF:{} }

        // 上传图片
   editorConfig.MENU_CONF["uploadImage"]={
      server:"https://www.wulcl.cn:8055/PC/upload/uploadfu",
      fieldName:'file',
      customInsert(res, insertFn) {
    // res 即服务端的返回结果
    // 从 res 中找到 url alt href ，然后插图图片
        insertFn(res.data.url, res.data.name, '')
        }
    }

    // 上传视频
    editorConfig.MENU_CONF['uploadVideo'] = {
      server:"https://www.wulcl.cn:8055/PC/upload/uploadfu",
      fieldName:'file',
      customInsert(res, insertFn) {
    // res 即服务端的返回结果
    // 从 res 中找到 url alt href ，然后插图图片
        insertFn(res.data.url, res.data.name, '')
        }
    }


    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    const onchange=(editor)=>{
      
      
          valueHtml.value=editor.getHtml()
    


    }

    return {
      editorRef,
      valueHtml,
      mode: 'simple', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      onchange
    };
  }
}
</script >
<style scoped>
*{
  text-align: left;
}
</style>    