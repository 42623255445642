我的实验室第二页面
<template > 
     


    <!--确认删除 区域右上角 -->
 
<div class="main" id="myDiv" style="display: none;">12</div>



</template>

<script scoped>
import axios from '@/Api/axios'
import router from "@/router"
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data(){
        return{
            dialogFormVisible:false,
             // 我的世界里面的项目
                MyWorld:[],
                clickcolor:"",
                // xiu:0,
                // 接受个人信息
                // MyPersonalInformation:[],
                SpaceDto:
                    {
                        w_id:"",
                        space_name:""
                    },
                // 接受右下角的数据
                Themaincontent:[],
                // 创建我的世界  
                Space:{
                    w_id:"",
                    space_name:""
                },
                // circleUrl:"",
                //重命名传送时的数据
                Renamingdata:{
                    w_id:"",
                    oldname:"",
                    space_name:""
                },
                // 右上方显示的内容
                TopRightCornerContent:"",
          
                TheCurrentId:"",
                dialogVisible:false,

                    k1:'',
               
        }
    },
    
    methods:{
     
        save(){
            
            this.Renamingdata.w_id= this.$cookies.get("selectValue").id;
             this.Renamingdata.oldname=this.$store.state.choicecontext;
            
            if ( this.Renamingdata.space_name.trim() == '' || this.Renamingdata.space_name == "" || this.Renamingdata.space_name === null||this.Renamingdata.space_name.length < 1 || this.Renamingdata.space_name.length > 8) {
            
                this.$message({
                     message: '此项不能为空，最多可输入10个字符',
                    type: 'warning'
                    });      
            }
            else if (this.Renamingdata.oldname=="我的实验室")
            {
                this.$message({
                     message: '我的实验室不能重命名',
                    type: 'warning'
                    });  
            }
            else 
            {
                    axios.post("PC/space/rename",this.Renamingdata).then(res=>{
                            
        if (res.data.data==true)
        {
            this.TopRightCornerContent=this.Renamingdata.space_name;
            axios.post("PC/space/getSpaceByUserIdNoPid/"+this.$cookies.get('selectValue').id).then(res=>{ 
                this.$store.commit("show8",res.data.data.data);
                this.$store.commit("show9",this.Renamingdata.space_name)
                       
                    })
                    ElMessage({
                            type: 'success',
                            message:"重命名成功"
                     })
                     this.dialogFormVisible=false
        }
        else if (res.data.msg=="空间名不能重复")
        {
            ElMessage({
                type: 'info',
                 message: '空间名不能重复',
             })
        }
        else
        {
            ElMessage({
                type: 'info',
                 message: '重命名失败',
             })
        }
       
      })
            }
            
        },
            getServerUrl(){
               return axios.getServerUrl();
        },
        delword(){
            let a={
                    w_id:this.$cookies.get('selectValue').id,
                    p_id:this.k1,
                    space_name:this.$store.state.choicecontext
            }
                axios.post("PC/space/remSpaceProject",a).then(res=>{
                
              if (res.data.data==true)
              {
                this.$message({
                     message: '删除成功',
                    type: 'success'
                     }); 
                   
                    this.dialogVisible=false
                    this.SpaceDto.w_id=this.$cookies.get('selectValue').id;
                    this.SpaceDto.space_name=this.$store.state.choicecontext
                    
                     
                    axios.post("PC/space/getspaceproject",this.SpaceDto).then(res=>{
                
                        
                            this.$store.commit("show",res.data.data.data);
             
                     
                            
                    })
                    // this.mounted()
                    // this.$store.commit("show9","我的世界");
              }
              else
              {
                this.$message({
                    message: '删除失败',
                     type: 'warning'
                  });
              }
                })
            // }
            
          
        },
        shanchuba(id){
            this.dialogVisible=true
            this.k1=id;
        },
         // 重命名
         rename(e){
            this.Renamingdata.space_name=""
            this.dialogFormVisible=true   
        },
        MyContent(e){
            axios.get("PC/project/"+this.$store.state.Themaincontent[e].id).then(res=>{
                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:2}})

                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:2,}})
                }
                
               
            })
        },
        Newproject(){
            this.$store.commit("activecontent",{})
            this.$store.commit("resourcesruleForm",{})
             localStorage.setItem("abcde","")
            router.push({
                name:"activitiess"
            })     
        },   
    },
    mounted(){
    
    },
   
}
</script>

<style scoped lang="scss">
/* pc */
@media  screen and (min-width:1201px){
  .main {
    width: 1366px;
  }
}

/* 平板 */
@media  screen and (max-width:1200px){
    .main {
      width: 980px;
    }
}

/deep/.el-overlay .el-overlay-dialog{
    
    // background-color: pink;
    // background-color: transparent;
}
.create p {
    
    position: absolute;
    top: 24px;
    left: 24px;
}
.create .bbbtn {
    bottom: 24px;
}
.create .inputbox {
    outline: none;
    padding-left: 12px;
    top: 71px;
    width: 372px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    border: 1.5px solid #808080;
}
.create .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
.bo {
    /* vertical-align: bottom; */
}
    #del{
        position: absolute;
        top: 12px;
        right: 12px;
        display: none;
        transition: .3s;
        width: 36px;
        height: 36px;
        cursor: pointer;
    }
    .myli:hover #del
    {
        display: inline;
        transition: .3s;
    }
    .upperrighttwo {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding-left:24px;
        height: 68px;
        line-height: 68px;
        border-radius: 9px;
    }
    .ulcss {
        margin-right: 24px;
        display: flex;
    }
    .ulcss li:hover {
        font-weight: 700;
    }

    
/* 平板 */
@media  screen and (max-width:1200px){
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
        vertical-align: top;

    }
    .myli:nth-child(3n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
        /* vertical-align: top; */
    }
    .myli:nth-child(n+4){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
}
/* pc */
@media  screen and (min-width:1201px){
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
        vertical-align: top;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align: top;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .myli:nth-child(n+5){
        margin-top: 31.5px;
    }

    
}




    .myli span {
        padding-left: 24px;
        padding-right:24px ;
        display: block;
        background-color: #fff;
        z-index: 2;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
    }
    .delworld p {
    text-align: center;
    line-height: 95px;
}
.delworld .bbbtn {
    bottom: 24px;
}
.delworld .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}
</style>