import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import store from "./store"
import imageload from "./components/imgUploader"
import videoload from "./components/videoUploader"
import SliderVerificationCode from './components/index';

// 视频插件
import VideoPlayer from 'vue-video-player/src'
import 'video.js/dist/video-js.css' 
import 'vue-video-player/src/custom-theme.css'






const app=createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
 app.config.warnHandler=()=>null
app.use(ElementPlus).use(store).use(VideoPlayer).use(router).use(VueCookies).use(imageload).use(videoload).use(SliderVerificationCode).mount('#app')

