<!-- 实验室界面 -->
<template>
    
<div  :style="{'min-width':screen+'px'}" >
    <div class="contain">
                        <!-- 左边 -->
            <div>
                <el-menu
                :default-active="this.$store.state.xiu2"
               
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                >
                    
                    <el-sub-menu index="1" id="one">
            
                        <template #title>
                            <div style="width: 100%; height: 100%;"  @click="ziyuanchange()">
                                <img class="yellowone" v-if="(ziyuanindex==true)" src="../../assets/index/yel.png" alt="">
                                <div class="icon-res" :class="[ziyuanindex==true?'icon-res-h':'']"></div>
                                <span class="dingwei" >资源</span>
                            </div>
                        </template> 

                        <el-menu-item  v-for="(p,index) in resourcedata" :key="p.key"       :index="`1-${index}`" @click="Resourcesshow(index)" >
                            <span style="transform: translateX(20px);" >{{p.type}}</span>
                        </el-menu-item>

                    </el-sub-menu>
                <el-sub-menu index="2"  id="two">
                            <template #title>
                            <!-- <el-icon><location /></el-icon> -->
                            <!-- <div class="yellowone" v-if="(huodongindex==true)"></div> -->
                            <div style="width: 100%;height: 100%;"  @click="huodongchange()">
                                <img class="yellowone" v-if="(huodongindex==true)" src="../../assets/index/yel.png" alt="">
                                <div class="icon-act" :class="[huodongindex==true?'icon-act-h':'']"></div>
                                <span class="dingwei">活动</span>
                            </div>
                            </template>
                        <el-menu-item v-for="(p,index) in activity" :key="p.key" :index="`2-${index}`"  @click="Activitiesshow(index)">
                            <span style="transform: translateX(20px);">{{p.type}}</span>
                        </el-menu-item>
                </el-sub-menu>
                
                    </el-menu>
            </div>
            <div class="lowerright">

                                    <!-- 右上 -->
                    <div id="headerlaboratorytwo" >
                        <span class="topmid font20 font25">{{TheRightSideOfTheSubject}}</span>
                    </div> 
                    
                <!-- 右下 -->
                    <div class="lowerright-laboratorytwo">
                            <ul class="mm">
                                <!-- style="width: auto;height: auto;max-height:100%; position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);" -->
                                <li class="myli move" v-for="(p,index) in TopicContent" :key="p.id" @click="MyContent(index)">
                                    <div class="imgtop move" style="position: relative;overflow:hidden;">
                                        <img  class="imgcss" :src="getServerUrl()+'/1image/'+ p.picurl" alt="">
                                    </div>
                                    <span class="t1 move text-ellipsis font16 font00">{{p.title}}</span>
                                    <span class="t2 move text-ellipsis font12 font00" v-if="p.state==0|| p.state==-2"><span class="dian green"></span>空闲中</span>
                                    <span class="t2 move text-ellipsis font12 font00 " v-else-if="p.state==1" ><span class="dian red"></span>使用中</span>
                                    <span class="t2 move text-ellipsis font12 font00" v-else-if="p.state==2"><span class="dian green"></span>我的预约中</span>
                                    <span class="t2 move text-ellipsis font12 font00" v-else-if="p.state==3"><span class="dian red"></span>我在使用</span>
                                    <span class="t2 move text-ellipsis font12 font00" v-else-if="p.state==4"><span class="dian yellow"></span>禁用</span>
                                </li> 
                            </ul>
                    </div>

            </div>
    </div>

</div>

       
</template>
    
<script >
import axios,{getServerUrl} from '@/Api/axios'
export default {
        data(){
                return{
                    resourcedata:[],
                    activity:[],
                    Members:[],
                    TheCurrentContent:[],
                    // 右上角主题信息
                    TheRightSideOfTheSubject:"",
                    // 存储查询需要的id
                    resourcedataid:"",
                    // 主题内容显示
                    TopicContent:[],
                    curr:0,
                    //鼠标选中
                    huodongindex:false,
                    ziyuanindex:true,
                    chengyuanindex:false,
                    flag:false,
                    defalut:1
                }

        },
        // 点击资源下的内容
        methods:{
       
            mouseindexchange(e) {
    
                if(this.mouseindex==e){
                    this.mouseindex=-1;
                } else {
                    this.mouseindex=e;
                }

            },
            huodongchange(){
                this.huodongindex=!this.huodongindex
            },
            ziyuanchange(){
                this.ziyuanindex=!this.ziyuanindex
               
            },
            chengyuanchange(){
                this.chengyuanindex=!this.chengyuanindex
            },
            MyContent(e){
               axios.get("PC/project/"+this.TopicContent[e].id).then(res=>{
                if (res.data.data.parent==0)
                {
                    this.$router.push({path:'/homepage/resourcesfordetails',query:{paramName:res.data.data.id,page:3}})
                    
                }
                else
                {
                    this.$router.push({path:'/homepage/eventdetails',query:{paramName:res.data.data.id,page:3}})
                    
                }
                
               
            })
            },
            Resourcesshow(e){
                // console.log("1-"+e)
                this.$store.commit("xyz","1-"+e);
                this.TheRightSideOfTheSubject=this.resourcedata[e].type;
                this.resourcedataid=this.resourcedata[e].id;
                axios.get("PC/project/"+this.resourcedataid+"/type/"+this.$cookies.get("selectValue").id).then(res=>{
                            let a=res.data.data;
                            if (a!="" && a!=null && a!=[])
                            {
                                for (let i=0;i<a.length;i++)
                                {
                                    if (a[i].picurl=="" || a[i].picurl==null)
                                    {
                                        a[i].picurl='mr.png'
                                    }
                                }
                            }
                    this.TopicContent=a;
                })

                if(e==this.curr){
                    this.curr=-1;
                } else {
                    this.curr=e;
                }

            },
            Activitiesshow(e){
                this.$store.commit("xyz","2-"+e);
                this.resourcedataid=this.activity[e].id;
                this.TheRightSideOfTheSubject=this.activity[e].type;
                axios.get("PC/project/type/"+this.resourcedataid).then(res=>{
                    let a=res.data.data
                    if (a!="" && a!=null && a!=[])
                    {
                        for (let i=0;i<a.length;i++){
                            if (a[i].picurl==null || a[i].picurl=="")
                            {
                                a[i].picurl='mr.png'
                            }
                        }
                    }
                    this.TopicContent=a;
                })
                this.curr=e;
            },
            Membersshow(e){
                this.resourcedataid=this.Members[e].id;
                this.TheRightSideOfTheSubject=this.Members[e].type;
                axios.get("PC/project/type/"+this.resourcedataid).then(res=>{
                    this.TopicContent=res.data.data;
                })
                this.curr=e;
            },
            getServerUrl(){
                return getServerUrl()
            }
        },
        mounted() {
            axios.get("PC/project/lab/getProject").then(res=>{
                 this.resourcedata=res.data.data[0].children; 
                 this.activity=res.data.data[1].children;
                 let aaa=this.$store.state.xiu2.split("-");
                    
                 if (aaa[0]==1)
                 {
                    this.ziyuanindex=true
                       this.huodongindex=false
                    this.TheRightSideOfTheSubject=this.resourcedata[aaa[1]].type;
                    this.resourcedataid=this.resourcedata[aaa[1]].id;
                    axios.get("PC/project/"+this.resourcedataid+"/type/"+this.$cookies.get("selectValue").id).then(res=>{
                        let a=res.data.data
                        for (let i=0;i<a.length;i++)
                        {
                            if (a[i].picurl=="" || a[i].picurl==null){
                                a[i].picurl='mr.png'
                            }
                        }
                    this.TopicContent=a;
                    
             
                })
                 }
                 else if (aaa[0]=='2')
                 {
                    this.ziyuanindex=false
                    this.huodongindex=true
                    this.TheRightSideOfTheSubject=this.activity[aaa[1]].type;
                    this.resourcedataid=this.activity[aaa[1]].id;
                    axios.get("PC/project/type/"+this.resourcedataid).then(res=>{
                        let a=res.data.data
                        for (let i=0;i<a.length;i++)
                        {
                            if (a[i].picurl=="" || a[i].picurl==null){
                                a[i].picurl='mr.png'
                            }
                        }
                    this.TopicContent=a;
                })
                 }
               
                //  console.log(document.getElementsByTagName('img'))
                //  let arr=document.getElementsByTagName('img')
                //  for (let i=5;i<arr.length;i++){

                //  }
            
            })

           
        },
}
</script>

<style scoped lang="scss">
.dian {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;

    margin-right: 12px;
}
.el-menu-vertical-demo {
    background-color: white;
    border-radius: 9px;
    overflow: hidden;

}
#one /deep/ .el-icon {
   display: none;
}
#two /deep/ .el-icon{
    display: none;
}
.el-sub-menu__title:hover {
    background-color: none;
}
.is-opened {
    background-color: none;
}
    
    .el-menu-item.is-active
    {
        background-color: #002580;
        color: #fff;

    }
    .yellowone {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .xiu {
        background-color: #002680;
        color: white!important;
        --el-menu-hover-bg-color:none!important;
        --el-menu-hover-text-color:none;
    }
    .icon-res {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/resources-icon.png);
        background-size: contain;
    }
    .icon-res-h {
        position: absolute;
        left: 15px;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/resources-icon-l.png);
        background-size: contain;
    }
    .icon-act {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/activity-icon.png);
        background-size: contain;
    }
    .icon-act-h {
        position: absolute;
        left: 15px;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/activity-icon-h.png);
        background-size: contain;
    }
    .icon-meb {
        position: absolute;
        left: 15px;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/member.png);
        background-size: contain;
    }
    .icon-meb-h {
        position: absolute;
        left: 15px;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/lab/member-l.png);
        background-size: contain;
    }
    #asidelaboratorytwo
    {
        background: white;
        border-radius:20px ;
        width:200px;
        min-height: 100px;
        position: absolute;
        left: 60px;
        top: 95px;
    }
    #headerlaboratorytwo
    {
        /* float: right; */
        background: white;
        /* background-color: pink; */
        border-radius:9px ;
        width: 100%;
        height: 68px;
        margin-bottom: 24px;

        
    }
    #headerlaboratorytwo span {
        display: inline-block;
        padding-left: 24px;
        font-weight: 700;

    }
    #mainlaboratorytwo
    {
        margin-top: 50px;
        margin-left: 40px;
        border-radius:20px ;
        width: 1050px;
        
    }
    .mainmenutwo
    {
        width: 200px;
        height: 50px;
        line-height: 50px;
        list-style: none;
    }
    


    .firstMenutwo {
    min-height: 150px;
    position: relative;
    left: 10px;
    width: 180px;
    }
.firstMenutwo .menu-onetwo .menutwo {
    list-style: none;
    text-align: center;
    width: 100px;
    
}
.firstMenutwo .menu-onetwo .menutwo .menu-twotwo { /*正常情况下二级菜单不显示*/
    display: none;
}
/*当鼠标悬浮在对应的一级菜单时，显示内容*/
.firstMenutwo .menu-onetwo .menutwo:hover .menu-twotwo {
    display: block;
   
}
.menu-twotwo
{

   list-style: none;
   text-align: left;
   background: #f7f7f7;
   width:200px ;
   position: relative;
   left: -10px;

   
}
.menu-onetwo li
{
   padding-top: 20px;
}
.menu-twotwo li
{
    margin-top: 20px;
    padding-left: 40px;
    width: 80px;
}
.lowerright-laboratorytwo
    {
       width: 100%;

        /* background-color: pink; */
    }
    .mylitwo
    {

        /* background-color: #00b2a2; */
    }
    .mylitwo:nth-child(4n)
    {
        margin-right: 0px;
    }
    .el-icon svg
    {
        display: none;
    }
.dingwei {
    position: absolute;
    top:50% ;
    transform: translateY(-50%);
    left: 45px;
}
.el-sub-menu {
    position: relative;
    
}
.mouseindex {
    background-color: #002580;
}
.el-menu-item {
    width: 100%;
    /* background-color: pink; */
    box-sizing: border-box;
}
.t1 {
    display: block;
    /* background-color: pink; */
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 14px;
    box-sizing: border-box;
    background-color: #fff;
}
.t2 {
    display: inline-block;
    /* background-color: #00b2a2; */
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 14px;
    box-sizing: border-box;
    background-color: #fff;
}

/* pc */
@media (min-width: 1201px) {
    .lowerright {
        padding-left:39.5px ;
        width: 1086.5px;
        /* background-color: green; */
        }
    .el-menu-vertical-demo {
        width: 240px;
    }
    .contain {
        display: flex;
        width: 1366px;
        /* background-color: red; */
      }


      .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        /* height: 187.5px; */

        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
        padding-bottom:14px ;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align: middle;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .myli:nth-child(n+5){
        margin-top: 31.5px;
    }
}
 /* 平板 */
@media (max-width:1200px) {
    .lowerright {
        padding-left:20px ;
        width: 728px;
        
     
        }
    .el-menu-vertical-demo {
        width: 232px;
    }
    .contain {
        display: flex;
        width: 980px;
        /* background-color: red; */
      }

      .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        /* height: 174px; */
        /* background-color: #00b2a2; */
        background-color: #fff;
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
        border-radius: 9px;
        padding-bottom:14px ;
    }
    .myli:nth-child(3n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
    }
    .myli:nth-child(n+4){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
}
.contain {
    padding-top: 24px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
}
</style>