<template>
   <!-- 注册 -->
   <div class="login">
      <h4 style="position: relative; left: -55px;">注册</h4>
      <hr style="width: 280px;position: relative;left: 120px;" size="1px" >
      <el-form :model="queryForm" label-width="120px">
      <el-form-item >
        <el-input style="margin-top:20px;" v-model="queryForm.userName" placeholder="姓名" />
      </el-form-item>
      <el-form-item >
        <el-input v-model="queryForm.passWord" placeholder="设置密码"/>
      </el-form-item>
      <el-form-item >
        <el-input v-model="queryForm.AgainPassWord" placeholder="再次输入密码"/>
      </el-form-item>
    </el-form>
    <el-button type="info" style="width: 70%;margin-left: 120px;" @click="login()" round>登录</el-button>
    <br/>
    <br/>
    <el-button type="success" style="width: 70%;margin-left: 120px;" round>微信扫码登录</el-button>
  </div>
</template>

<script scoped>
  import axios from 'axios'
  import router from '@/router'
  import { ElMessage } from 'element-plus'
export default {
    data(){
    return{
      queryForm:{
        userName:'',
        passWord:'',
        AgainPassWord:'',
        account:'',
        iPhone:''
      },
    }   
    },
    methods:{
       login(){
     
              router.push({
                name:"firstpage"
              });
          
       }  
  } 
}    
</script>

<style scoped>
 .login{
      width: 400px;
      height: 300px;
      padding:20px;
      /* 实现div块水平居中对齐 */
      position: absolute;
      top: 0;
      left:0;
      bottom: 0;
      right: 0;
      margin: auto;
  }
</style>