<!-- 个人信息中右下角个人信息界面 -->
<template>
  <div style="background-color: #fff; border-radius: 9px;overflow: hidden;">
      <div class="demo-basic--circle">
          <div class="block">
            <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl" alt="">
            <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length<=17 " :src="getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt="">
            <!-- <el-avatar  :size="100" :src="circleUrl" /> --><br/>
            <p class="leftmid font16 font00">{{cookie.username}}</p><br/>
            <p class="leftmid font12 font80">{{ cookie.sys}}</p>
          </div>
          <!-- <div v-for="size in sizeList" :key="size" class="block">
            <el-avatar :size="size" :src="circleUrl" />
          </div> -->
      </div>
      <ul   class="subpersonalInformationmenu">
        <li class="leftmid"><span class="font16 font00">性别</span> <input @change="interchange()" type="text" maxlength="1" v-model="cookie.sex" placeholder="请输入性别" class="font14 font80"></li>
        <li class="leftmid"><span class="font16 font00">电话</span> <input @change="interchange()" type="text" maxlength="11" v-model="cookie.phone" placeholder="请输入电话" class="font14 font80"></li>
        <li class="leftmid"><span class="font16 font00">单位</span> <input @change="interchange()" type="text" maxlength="20" v-model="cookie.unit" placeholder="请输入单位" class="font14 font80"></li>
        <li class="leftmid"><span class="font16 font00">微信</span><span class="font14 font80 xyz move"  @click="jiechu()">解除绑定</span></li>
      </ul>
  </div>
<button class="leftmid btnblue" @click="ExitTheLogin()" >退出登录</button>

<!-- 解除绑定dialog -->
<el-dialog
  style="border-radius: 12px;position: relative;height: 196px;"
  v-model="dialogVisible"
  width="420px"
  :show-close="false"
  align-center
  class="dialogcss"
  :before-close="handleClose">
  <p class="font00 font18">解绑后需重新注册，确定解除绑定？</p>
  <div class="bbbtn leftmid2" style="display:flex ;justify-content:center;">
                <button  class="btnblue move" style="margin-right: 48px;" @click="delword()">确认</button>
                <button class="btnwhite move" @click="dialogVisible = false">取消</button>
    </div>
  <!-- <span  style="position: absolute;top: 100px;left: 37%;">
    <el-button type="primary" @click="save()" style="border: 1px solid black; border-radius: 20px;color: black;background-color:white;">确 定</el-button>
    <el-button @click="dialogVisible = false" style="border: 1px solid black; border-radius: 20px;background-color:#002580;color:azure;">取 消</el-button>

  </span> -->
</el-dialog>


</template>
<script >
import router from '@/router'
import axios from "@/Api/axios"
export default {
  data()
  {
    return{
      dialogVisible:false,
        cookie:{}     
    }
  },
  mounted(){
    this.cookie=this.$cookies.get('selectValue');
    if (this.cookie.sex=="0")
    {
        this.cookie.sex="女"
    }else if(this.cookie.sex=="1")
    {
      this.cookie.sex="男"
    }
  },
  methods:{
    interchange(){
          let a={}
          a.id=this.$cookies.get("selectValue").id;
          a.sex=this.cookie.sex=="男"?1:0;
          a.unit=this.cookie.unit
          a.phone=this.cookie.phone
      
          axios.post("PC/wechat_user/update",a).then(res=>{
        
            if(res.data.code==20031)
            {
              this.$cookies.set('selectValue', res.data.data,{ expires: 0 })
           
              if (this.cookie.sex=="0")
    {
        this.cookie.sex="女"
    }else if(this.cookie.sex=="1")
    {
      this.cookie.sex="男"
    }
            }
          })
    },
    getServerUrl(){
          return axios.getServerUrl();
       },
    save(){
      this.dialogVisible=true
        axios.get("wechat_user/jieban/"+this.$cookies.get("selectValue").id).then(res=>{

        if (res.data.code==20031)
        {
          this.$message({
          message: '解除绑定成功',
          type: 'success'
        });
        // this.dialogVisible=false
          this.ExitTheLogin();
          
        }
        else
        {
          this.$message({
          message: '解除绑定失败',
          type: 'warning'
        });
        }
           this.dialogVisible=false
        })
   
    },
    jiechu(){
      this.dialogVisible=true
        // axios.get("wechat_user/jiebang/"+this.$cookies.get("selectValue").id).then(res=>{
        
        //   ExitTheLogin();
        // })
    },
    ExitTheLogin(){
      this.$cookies.set('selectValue', "",0),
      localStorage.setItem("isToken","")
      localStorage.setItem("zidong","")
      this.$store.commit("countunread",0)
      this.$store.commit("zidonga","")
      router.push({
        name:"login"
      })
    }
  }
}
</script>

<style scoped>
.dialogcss p {
    text-align: center;
}
.dialogcss .bbbtn {
    bottom: 24px;
}
.dialogcss .bbbtn button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
}


.block {
  background-color: #fff;
  padding-bottom: 24px;
}
.block img {
    margin: 24px 0;
    width: 72px;
    height: 72px;
    border-radius: 50%;
}
.block p {
  display: inline-block;
}
.block p:nth-child(3) {
  margin-bottom: 6px;
  /* background-color: pink; */
}
.subpersonalInformationmenu li {
  width: 426px;
  height: 64px;
  line-height: 64px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.subpersonalInformationmenu input {
  outline: none;
  width: 364px;
  border: 0;
  text-align: right;
}
button {
  margin-top: 48px;
  width: 136px;
  height: 48px;
  border-radius: 24px;
  border: none;
  color: #fff;
}

.xyz:hover{
  color: #002580;

}

/* .smo {
  color: #808080;
}
.subpersonalInformationmenu
{

  width: 400px;
    list-style: none;
    margin-top: 50px;
   position: relative;
   left: 200px;
  
}
.subpersonalInformationmenu li
{
    margin-top: 30px; ;
}
.subpersonalInformationmenu2
{
    list-style: none; 
    width: 400px;
    position: relative;
    left: -390px;
    top: -200px;
    float: right;
   
}
.subpersonalInformationmenu2 li
{
    height: 20px;
    margin-top: 30px; ;
    text-align: right;
} */
</style>