<!-- 消息页面左边区域 -->

<!-- div class="someleft leftwidth leftwidth">
              <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl" alt="">
              <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length<=17 " :src="getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt=""><br/>
              <p class="leftmid font16 font00">{{Personalinformation.username}}</p><br/>
              <p class="leftmid font16 font80">{{Personalinformation.sys}}</p>
            </div> -->

<template >
    <div id="main" style="display: flex;">
        <div>

                    <!-- 左上区域 -->
            <div class="leftwidth upperleftnewpage upperleft">
                <div class="demo-basic--circle">
                    <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length>17 " :src="this.$cookies.get('selectValue').avatarUrl " alt="" @click="showpersonalinformation()">
                    <img class="leftmid" v-if="this.$cookies.get('selectValue').avatarUrl.length<=17 " :src=" getServerUrl()+'/1image/'+this.$cookies.get('selectValue').avatarUrl" alt="" @click="showpersonalinformation()"> <br/>

                    <p class="leftmid font16 font00">{{MyPersonalInformation.username}}</p><br/>
                    <p class="leftmid font16 font80">{{MyPersonalInformation.sys}}</p>
                </div>
            </div>

                        <!-- 左下区域 -->
            <div class="leftwidth lowerleftnewpage" >
                <div class="mess" @click="mouseindexchange(1)" >
                    <img class="yellowone topmid" v-if="mouseindex==1" src="../../assets/index/yel.png" alt="">
                    <img class="read topmid" v-if="mouseindex==1" src="../../assets/message/read.png" alt="">
                    <img class="read topmid" v-if="mouseindex==2" src="../../assets/message/unread.png" alt="">
                    <router-link to="/homepage/newspage/unreadmessages" style="width: 100%;display: block;" class="font16 font00" >未读消息</router-link>
                    <span class="messcount font14" v-if="this.$store.state.unread!=0">{{this.$store.state.unread}}</span>

                </div>
                <div class="mess"  @click="mouseindexchange(2)">
                    <img class="yellowone topmid" v-if="mouseindex==2" src="../../assets/index/yel.png" alt="">
                    <!-- <div class="yellowone topmid" v-if="mouseindex==2"></div> -->
                    
                    <img v-if="mouseindex==1" class="read topmid" src="../../assets/message/unread.png" alt="">
                    <img  v-if="mouseindex==2" class="read topmid" src="../../assets/message/read.png" alt="">
                    <router-link to="/homepage/newspage/readmessage" style="width: 100%;display: block;" class="font16 font00" >已读消息</router-link>
                </div>

            </div> 

        </div>



                  <!-- 右边区域 -->
        <div class="right padingleft">
            <router-view/>
        </div>      
    </div>
      
     
</template>

<script>
import axios from '@/Api/axios'
import router from '@/router'
export default {
    data(){
        return{
            MyPersonalInformation:[],
            // 未读信息数量
            UnreadCount:"",
            time:null,
            mouseindex:1,
        }
    },
    methods:{ 
         getServerUrl(){
                return axios.getServerUrl();
        },
        showpersonalinformation(){
            router.push({
                name:"subpersonalInformati"
            })
            
        },
        JumpUnread(){
            router.push({
                name:"unreadmessages"
            })
        },
        JumpRead(){
                router.push({
                    name:"readmessage"
                })
        },
        mouseindexchange(e){
            this.mouseindex=e
            if (e==1){
                router.push({
                name:"unreadmessages"
            })
            }else
            {
                router.push({
                    name:"readmessage"
                })
            }
        }
    },
    mounted(){
        axios.get("PC/wechat_user/auto/"+this.$cookies.get('selectValue').id).then(res=>{
                    this.MyPersonalInformation=res.data.data.data;        
            })
        let flag1=false;
    
          
            if (this.$router.currentRoute.value.fullPath=='/homepage/newspage/unreadmessages')
            {
               flag1=true
            }
        
        if (flag1==true)
        {
      
          this.mouseindex=1
        }
        else{
        
          this.mouseindex=2
        }
           
    },
   
}
</script>


<style  scoped>
.mess:hover {
    background-color: rgb(236, 245, 255);
    transition: .3s;
}
router-link {
    display: block;
    width: 100%;
}
    .yellowone {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

    }
.upperleftnewpage {
    background-color: #fff;
    border-radius: 9px;
}
.upperleft {    
    padding-top: 36px;
    padding-bottom: 36px;
}
    
    .upperleft img {

    width: 84px;
    height: 84px;
    border-radius: 50%;
    /* background-color: red; */
    }
    .upperleft p {
    display: inline-block;
    }
    .upperleft :nth-child(3){
    margin-top:24px ;
    /* background-color: red; */
    }
    .upperleft :nth-child(5){
    margin-top:12px ;
    }
.lowerleftnewpage {
    margin-top: 24px;
    border-radius: 9px;
    background-color: #fff;
    overflow: hidden;
}
.lowerleftnewpage .read {
    width: 16px;
    height: 20px;
}
.lowerleftnewpage a{
    text-decoration: none;
}
.mess {
    position: relative;
    padding-left:54px ;
    height: 56px;
    line-height: 56px;
}
.mess .read {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
}
.right {
    flex: 1;
    /* background-color: pink; */
}
.messcount {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    padding: 1px 6px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    background-color: #c70202;
    color: #fff;
    
}
/* 平板 */
@media  screen and (max-width:1200px){
    #main {
            width: 980px;
            margin: 24px auto 0;

          }
          .leftwidth {
            width: 232px;
          }
          .padingleft {
            padding-left: 20px;
            padding-bottom:96px;
          }
}
/* pc */
@media  screen and (min-width:1201px){
    #main {
            width: 1366px;
            margin: 24px auto 0;
            /* background-color: pink; */
          }
          .leftwidth {
            width: 240px;
          }
          .padingleft {
            padding-left: 39.5px;
            padding-bottom:96px;
          }
}
    /* .yellowone {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 20px;
        width: 6px;
        border-radius: 3px ;
        background-color: #fed71a;
    }
      .samenewpage
    {
        background-color: white;
        border-radius: 20px;
    }
    .upperleftnewpage
    {
        position: absolute;
        top: 30px;
        left: 50px;

        width: 250px;
        height: 250px;
    }
    .lowerleftnewpage
    {
        position: absolute;
        left:50px;
        top: 310px;
        
        width:250px;
        height: 120px;
        padding: 10px 0;
        box-sizing: border-box;
    }
    .lowerleftnewpage .mess {
        position: relative;
        font-weight: 700;
    }
    .lowerleftnewpage img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
    }
    .lowerleftnewpage .unread {
        display: block;
        width: 100%;
        height:50px;
        line-height: 50px;
        padding: 0 35px;
        box-sizing: border-box;
        text-align: left;
        text-decoration:none;
        color: black;
        letter-spacing: 1px;
    }
    .lowerleftnewpage .messcount {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        display: block;
        padding:2px 5px;
        color: #fff;
        background-color: #c70202;
    } */
</style>