<template>
   <div style="width: 100%;height: 36px;background-color:rgba(0,0,0,.7);">
        <!-- <ul class="footer-ul">
            <li >关于我们</li>
            <li>联系我们</li>
            <li>加入我们</li>
            <li style="border: none;">用户协议</li>
        </ul> -->
    
        <div class="main" >
            <!-- <img src="../assets/AddinganActivity/whiteweixin.png" alt=""> -->
            <div class="img"></div>
            <div style="display:flex;align-self: center;">
                <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo"> <img src="../assets/kkkk.png" style="width: 14px;height: 14px;" /></a>
                <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo" class="font12 " style="display: block; text-decoration: none; color: #fff;margin-right: 24px;"><p >浙公网安备 33021102001009号</p ></a >
                <span class="font12 move" style="color: #fff;margin-right: 24px;" @click="jump()">浙ICP备2022034699号</span>
                <span  class="font12 " style="color: #fff;"  >Copyright©宁波城创设计咨询有限公司</span>
            
            </div>

            
		 		
		
            <div class="kuang">
                <img class="topmid" src="../assets/AddinganActivity/bbbbbb.png" alt="">
            </div>   
        </div>
        
    </div>
</template>

<script>
export default {
        methods:{
            jump(){
                window.location.replace('https://beian.miit.gov.cn/#/Integrated/index')
            }
        }
}
</script>

<style scoped>
/* 平板 */
    @media screen and (max-width:1200px) {
        .main {
            width: 980px;
        }
    }
    /* pc */
    @media screen and (min-width:1201px) {
        .main {
            width: 1366px;
        }
    }
    .main {
        padding-left: 16px;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto;
        height: 100%;
        /* line-height: 36px;s */
        display: flex;
        align-self: center;
        justify-content: space-between;

    }
    .img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        /* background-color: pink; */
        width: 17px;
        height: 17px;
        background-image: url(../assets/AddinganActivity/whiteweixin.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        /* background-color: pink; */
    }
    .img:hover {
        background-image: url(../assets/AddinganActivity/greenweixin.png);
    }
    .img:hover~div{
        /* display: flex; */
        opacity: 1;
        transition: .3s;
    }
    *
    {
        margin: 0;
        padding: 0;
    }
    .kuang {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 128px;
        height: 136px;
        display: flex;
        justify-content: center;
        align-self: center;
        /* background-color: pink; */
        background-image: url(../assets/AddinganActivity/twoweima.png);
        /* display: none;    */
        opacity: 0;
        transition: .3s;

    }
    .kuang img {
        transform: translateX(-4px) translateY(-47px);
        width: 88px;
        height: 88px;
        
    }
</style>