<!-- 未读消息页面 -->
<template>

    <div class="titbox">
        <span class="font20 font25">未读消息</span>
    </div>
    <div class="messbox bd"  v-for="(p,index) in UnreadMessages" :key="p.id">
           <img src="../../assets/message/read.png" alt="">
           <span  class="EachContent font16 font25" >{{UnreadMessages[index].name}}</span>
           <span  class="time font12 font80" >{{UnreadMessages[index].time}}</span><br/>
      <div class="cont">
           <span>{{begin[index]}}
           <span  v-if="UnreadMessages[index].type==0">{{UnreadMessages[index].p_type}}</span> 
           <span class="font25" style="font-weight: 700;">{{UnreadMessages[index].p_name}}</span>{{end[index]}}</span>
      </div>
      <div style="text-align: right;padding-right: 24px;"> 
          <span class="unread choose font16 font80 move" v-if="UnreadMessages[index].type!=1 && UnreadMessages[index].type!=3" @click="read(index)">
              已读
          </span>
      </div>

           <div v-if="UnreadMessages[index].type==1 ||UnreadMessages[index].type==3">
            <div  style="text-align: right;padding-right: 24px;padding-bottom: 24px;" >
              <span @click="accept(index)" class="choose move font16 font25" style="margin-right: 72px;border-bottom: 2px solid #002580;" :class="{bububu:is}"  @mousemove="dududu" @mouseleave="dididi">接受</span>
              <span @click="RefusedTo(index)" class="choose move font16 font80" :class="{bububu:no}"  @mousemove="pgpgpg" @mouseleave="mmmm">拒绝</span>
            </div>
  
           </div>
    </div>
</template>

<script>
import axios from '@/Api/axios'

export default {
    data() {
        return {
            UnreadMessages: [],
            // 根据类型显示类容
            begin:[],
            end:[],
            is:false,
            no:false,
        };
    },
    methods:{
      dududu(){
        this.is=true;
      },
      dididi(){
        this.is=false;
      },
      pgpgpg(){
        this.no=true;
      },
      mmmm(){
        this.no=false;
      },
      // 已读
      read(e){
          axios.post("PC/member/read/"+this.UnreadMessages[e].id).then(res=>{
            axios.get("PC/message/" + this.$cookies.get("selectValue").id).then(res => {
            this.UnreadMessages = res.data.data;
            this.$store.commit("countunread",this.UnreadMessages.length)
            let n=-1;
            for (let i=0;i<this.UnreadMessages.length;i++)
            {
              n=n+1;
              switch(this.UnreadMessages[i].type)
              {
                case 0:
                  {
                      this.begin[n]="恭喜您的";
                      this.end[n]="被推至首页";
                      break;
                  }
                case 1:
                  {
                    
                    this.begin[n]="邀请您加入";
                      this.end[n]="";
                      break;
                  }
                case 2:
                  {
                  
                    this.begin[n]="恭喜您注册成功";
                      this.end[n]="";
                    break;
                  }
                  case 3:
                  {
                    
                    this.begin[n]="申请加入您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 4:
                  {
                    
                      this.begin[n]="";
                      this.end[n]="您申请使用了资源";
                    break;
                  }
                  case 5:
                  {
                    
                    this.begin[n]="恭喜您发布";
                      this.end[n]="成功";
                    break;
                  }
                  case 6:
                  {
                    
                    this.begin[n]="评论了您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 7:
                  {
                    
                    this.begin[n]="您的";
                      this.end[n]="活动被置顶";
                    break;
                  }
                  case 8:
                  {
                    
                    this.begin[n]="通过了您对";
                      this.end[n]="资源的申请"
                    break;
                  }
                  case 9:
                  {
                    
                    this.begin[n]="驳回了您对";
                      this.end[n]="的申请,并回复了您"+this.ReadTheInformation[i].reply
                    
                    break;
                  }
                  case 13:
                    {
                      this.begin[n]="接受了你";
                      this.end[n]="的邀请"
                      break;
                    }
                    case 14:
                      {
                        this.begin[n]="拒绝了你";
                        this.end[n]="的邀请"
                        break;
                    }
                    case 12:
                      {
                        this.begin[n]="您的";
                        this.end[n]="活动被取消置顶";
                        break;
                      }
                      case 10:
                        {
                          this.begin[n]="通过了您加入";
                          this.end[n]="活动的申请";
                           break;
                        }
                        case 11:
                        {
                          this.begin[n]="拒绝您加入";
                          this.end[n]="活动的申请";
                        break;
                        }
                        case 15:
                          {
                            this.begin[n]="将您从";
                            this.end[n]="移除了";
                            break;
                          }
                          case 16:{
                            this.begin[n]="退出了您的";
                            this.end[n]="活动";
                            break;
                          }
                 
              }
            }
        });
          })
        
      },
      // 接受
      accept(e){
          axios.get("PC/member/"+this.$cookies.get('selectValue').id+"/accpt/"+this.UnreadMessages[e].p_id+"/read/"+this.UnreadMessages[e].id).then(res=>{
           
            axios.get("PC/message/" + this.$cookies.get("selectValue").id).then(res => {
            this.UnreadMessages = res.data.data;
          
            this.$store.commit("countunread",this.UnreadMessages.length);
            let n=-1;

            for (let i=0;i<this.UnreadMessages.length;i++)
            {
              n=n+1;
          
              switch(this.UnreadMessages[i].type)
              {
                
                case 0:
                  {
                      this.begin[n]="恭喜您的";
                      this.end[n]="被推至首页";
                      break;
                  }
                case 1:
                  {
                    
                    this.begin[n]="邀请您加入";
                      this.end[n]="";
                      break;
                  }
                case 2:
                  {
                  
                    this.begin[n]="恭喜您注册成功";
                      this.end[n]="";
                    break;
                  }
                  case 3:
                  {
                    
                    this.begin[n]="申请加入您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 4:
                  {
                    
                      this.begin[n]="";
                      this.end[n]="您申请使用了资源";
                    break;
                  }
                  case 5:
                  {
                    
                    this.begin[n]="恭喜您发布";
                      this.end[n]="成功";
                    break;
                  }
                  case 6:
                  {
                    
                    this.begin[n]="评论了您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 7:
                  {
                    
                      this.begin[n]="您的";
                      this.end[n]="活动被置顶";
                    break;
                  }
                  case 8:
                  {
                    
                    this.begin[n]="通过了您对";
                      this.end[n]="资源的申请"
                    break;
                  }
                  case 9:
                  {
                    
                    this.begin[n]="驳回了您对";
                      this.end[n]="的申请,并回复了您"+this.ReadTheInformation[i].reply
                
                    break;
                  }
                  case 13:
                    {
             
                      this.begin[n]="接受了你";
                      this.end[n]="的邀请"
                      break;
                    }
                    case 14:
                      {
                       
                   
                        this.begin[n]="拒绝了你";
                        this.end[n]="的邀请"
                        break;
                      }
                      case 12:
                      {
                        this.begin[n]="您的";
                        this.end[n]="活动被取消置顶";
                        break;
                      }
                      case 10:
                        {
                          this.begin[n]="通过了您加入";
                          this.end[n]="活动的申请";
                           break;
                        }
                        case 11:
                        {
                          this.begin[n]="拒绝您加入";
                          this.end[n]="活动的申请";
                        break;
                        }
                        case 15:
                          {
                            this.begin[n]="将您从";
                            this.end[n]="移除了";
                            break;
                          }
                          case 16:{
                            this.begin[n]="退出了您的";
                            this.end[n]="活动";
                            break;
                          }
                 
              }
            }
        });
          })
          
      },
      // 拒绝
      RefusedTo(e){
        axios.get("PC/member/"+this.$cookies.get('selectValue').id+"/unaccpt/"+this.UnreadMessages[e].p_id+"/read/"+this.UnreadMessages[e].id).then(res=>{
            axios.get("PC/message/" + this.$cookies.get("selectValue").id).then(res => {
            this.UnreadMessages = res.data.data;
            this.$store.commit("countunread",this.UnreadMessages.length)
            let n=-1;
            for (let i=0;i<this.UnreadMessages.length;i++)
            {
              n=n+1;
              switch(this.UnreadMessages[i].type)
              {
                
                case 0:
                  {
                      this.begin[n]="恭喜您的";
                      this.end[n]="被推至首页";
                      break;
                  }
                case 1:
                  {
                    
                    this.begin[n]="邀请您加入";
                      this.end[n]="";
                      break;
                  }
                case 2:
                  {
                  
                    this.begin[n]="恭喜您注册成功";
                      this.end[n]="";
                    break;
                  }
                  case 3:
                  {
                    
                    this.begin[n]="申请加入您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 4:
                  {
                    
                      this.begin[n]="";
                      this.end[n]="您申请使用了资源";
                    break;
                  }
                  case 5:
                  {
                    
                    this.begin[n]="恭喜您发布";
                      this.end[n]="成功";
                    break;
                  }
                  case 6:
                  {
                    
                    this.begin[n]="评论了您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 7:
                  {
                    
                    this.begin[n]="您的";
                      this.end[n]="活动被置顶";
                    break;
                  }
                  case 8:
                  {
                    
                    this.begin[n]="通过了您对";
                      this.end[n]="资源的申请"
                    break;
                  }
                  case 9:
                  {
                    
                    this.begin[n]="驳回了您对";
                      this.end[n]="的申请,并回复了您"+this.ReadTheInformation[i].reply
                   
                    break;
                  }
                  case 13:
                    {
                      this.begin[n]="接受了你";
                      this.end[n]="的邀请"
                      break;
                    }
                    case 14:
                      {
                        this.begin[n]="拒绝了你";
                        this.end[n]="的邀请"
                        break;
                      }
                      case 12:
                      {
                        this.begin[n]="您的";
                        this.end[n]="活动被取消置顶";
                        break;
                      }
                      case 10:
                        {
                          this.begin[n]="通过了您加入";
                          this.end[n]="活动的申请";
                           break;
                        }
                        case 11:
                        {
                          this.begin[n]="拒绝您加入";
                          this.end[n]="活动的申请";
                        break;
                        }
                        case 15:
                          {
                            this.begin[n]="将您从";
                            this.end[n]="移除了";
                            break;
                          }
                          case 16:{
                            this.begin[n]="退出了您的";
                            this.end[n]="活动";
                            break;
                          }
              }
            }
        });
          })
        
      }
    },
    mounted() {
        axios.get("PC/message/" + this.$cookies.get("selectValue").id).then(res => {
      
            this.UnreadMessages = res.data.data;
            this.$store.commit("countunread",this.UnreadMessages.length)
            let n=-1;
            for (let i=0;i<this.UnreadMessages.length;i++)
            {
              // 时间处理
              if (this.UnreadMessages[i].time!="" && this.UnreadMessages[i].time!=null)
              {
                let t=this.UnreadMessages[i].time;
                
                let a=t.substring(5,10);
                let b=t.substring(11,16);
                this.UnreadMessages[i].time=a+" "+b;
              }
                
                n=n+1;
              switch(this.UnreadMessages[i].type)
              {
                
                case 0:
                  {
                      this.begin[n]="恭喜您的";
                      this.end[n]="被推至首页";
                      break;
                  }
                case 1:
                  {
                    
                    this.begin[n]="邀请您加入";
                      this.end[n]="";
                      break;
                  }
                case 2:
                  {
                  
                    this.begin[n]="恭喜您注册成功";
                      this.end[n]="";
                    break;
                  }
                  case 3:
                  {
                    
                    this.begin[n]="申请加入您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 4:
                  {
                    
                      this.begin[n]="";
                      this.end[n]="您申请使用了资源";
                    break;
                  }
                  case 5:
                  {
                    
                    this.begin[n]="恭喜您发布";
                      this.end[n]="成功";
                    break;
                  }
                  case 6:
                  {
                    
                    this.begin[n]="评论了您的";
                      this.end[n]="活动"
                    break;
                  }
                  case 7:
                  {
                    
                    this.begin[n]="您的";
                      this.end[n]="活动被置顶";
                    break;
                  }
                  case 8:
                  {
                    
                    this.begin[n]="通过了您对";
                      this.end[n]="资源的申请"
                    break;
                  }
                  case 9:
                  {
                    
                    this.begin[n]="驳回了您对";
                      this.end[n]="的申请,并回复了您"+this.ReadTheInformation[i].reply
                  
                    break;
                  }
                  case 13:
                    {
                      this.begin[n]="";
                      this.end[n]="接受了你的邀请"
                      break;
                    }
                    case 14:
                      {
                        this.begin[n]="";
                        this.end[n]="拒绝了你的邀请"
                        break;
                      }
                      case 12:
                      {
                        this.begin[n]="您的";
                        this.end[n]="活动被取消置顶";
                        break;
                      }
                      case 10:
                        {
                          this.begin[n]="通过了您加入";
                          this.end[n]="活动的申请";
                           break;
                        }
                        case 11:
                        {
                          this.begin[n]="拒绝您加入";
                          this.end[n]="活动的申请";
                        break;
                        }
                        case 15:
                          {
                            this.begin[n]="将您从";
                            this.end[n]="移除了";
                            break;
                          }
                          case 16:{
                            this.begin[n]="退出了您的";
                            this.end[n]="活动";
                            break;
                          }
                 
              }
            }
        });
       
       
    },

}
</script>
<style scoped>
.choose {
  text-align: center;
  display: inline-block;
  width: 38px;
  height: 24px;
  /* background-color: pink; */
  border-bottom: 2px solid #808080;
  line-height: 15px;
}
.choose:hover {
  font-weight: 700;
  /* color: #010a22; */
  /* border-bottom: 2px solid #010a22!important; */
}
.unread {
  margin-bottom: 24px;
  
}
.cont {
  margin: 24px 0;
  padding: 0 24px;
  /* background-color: pink; */
}
.el-checkbox-group {
  font-size: 16px;
  line-height: 16px;
}
.titbox {
  display: flex;
  justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-radius: 9px;
    height: 68px;
    line-height: 68px;
    padding: 0 22px 0 24px;
    box-sizing: border-box;

}
.messbox {
  position: relative;
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  margin-top: 24px;
}
.messbox img {
  width: 16px;
  height: 20px;
  vertical-align: bottom;
}
.del-img {
  width: 44px;
  height: 44px;
}
.time {
  margin-left: 24px;
}
.check {
position: absolute;
top: 24px;
right: 38px;
}
.EachContent {
  margin-left: 4px;
}
.bott {
  margin: 64px;
  text-align: center;

}
.bott button {
  width: 128px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  border: none;
}
.leftb {
  background-color: #002580;
  color: #fff;

}
.midb {
  border: 2px solid #002580!important;
  background-color: #fff;
  margin: 0 64px;
}
.rightb {
  border: 2px solid #002580!important;
  background-color: #fff;
}
.ww{
  display: block;
}
.wwww{
    display: none;
}
.w{
  display: none;
}
.www{
    display: block;
}

/* .titbox {
  position: relative;
  width: 100%; 
  height: 70px;
  background-color: white;
  border-radius: 20px;
}
.titbox span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;

  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #0f3187;
}

.EachContent
{
  position: absolute;
  top: 25px;
  left: 50px;
  font-weight: 700;
  color: #0c2f86;
}
.time
{
  position: absolute;
  top: 25px;
  left: 120px;
  font-weight: 700;
  color: #a2a2a2;
}
.bububu
{
  color: #002580!important;
}
.choose {
  border-bottom: 2px solid;
  padding-bottom: 5px;
  color: #a2a2a2;

}
.messcontent {
  position: relative;
  width: 100%; 
  min-height: 120px;
  background-color: #fff;
  margin-top: 30px;
  border-radius: 20px;
  text-align: left;
}
.messcontent img {
  width: 20px;
  height: 20px; 
  position: absolute;
  left: 25px;
  top: 25px;
} */
</style>