<!-- 资源预览页面 -->
<template>
  <div class="body">
          <!-- 紫色区域 -->
      <div class="res-top" id="top">
            <div class="main"  style=" justify-content: center; box-sizing: border-box; padding: 56px 96px 0;margin: 0 auto;display: flex;flex-wrap: wrap;align-items: center;">
                <h1 class="font30 font00 widthh1" style="font-weight: 700;" >{{this.$store.state.resourcesruleForm.name}}   </h1>
                <button class="bt" style="background-color: #8bc34a;" @click="backup()">预览返回</button> 
            </div>
         
          <ul  class="resources-firstul font14 font80" style="list-style: none;">
              <li>{{time}}</li>
              <span>|</span>
              <li>资源 / {{this.$store.state.resourcesruleForm.resource}}</li>
              <span>|</span>
              <li>
                  <img src="../../assets/ResourceDetails/collect.png" alt="">
                  0
                  <img src="../../assets/ResourceDetails/like.png" alt="">
                  0
                  <img src="../../assets/ResourceDetails/comment.png" alt="">
                  0
              </li>
          </ul>  
  
      </div>
       <!-- 白色大块 -->
      <div  class="res-mid main">
      <!-- 资源信息 -->
          <div class="resmessgae">
                  <div class="res-tit">
                      <img src="../../assets/ResourceDetails/message-a.png" alt="">
                      <span class="font28 font25">资源信息</span>
                  </div>
          <el-carousel :interval="5000" arrow="always"  style="width: 844px; margin:0 auto" :height="485+'px'">
              <el-carousel-item v-for="(item,index) in carouseData" :key="index" style="width: 100%;height: 100%;" >

                  <img  :src="item.url" alt="" style="width:100%;height: 100%" />
               
                </el-carousel-item>
          </el-carousel>
                  <ul class="resources-secondul font20 font00" style="list-style: none;">
                    <li>
                        <p></p><div class="lefttit">空间位置:</div><span class="font18 font00" style="font-weight: 400;">{{this.$store.state.resourcesruleForm.spaceOfChoice}}</span>
                    </li>
                      <li>
                        <p></p><div class="lefttit">使用状态:</div><span class="font18 font00" style="font-weight: 400;">空闲</span>
                      </li>
                      <li v-if="this.$store.state.resourcesruleForm.resourceIntroduction!=''">
                        <p></p><div class="lefttit">资源简介:</div><span class="font18 font00" style="font-weight: 400;">{{this.$store.state.resourcesruleForm.resourceIntroduction}}</span>
                      </li>
                      <li  v-for="p in Information" :key="p.id" >
                        <p></p><div class="lefttit">{{p.name}}:</div>
                        <span class="font18 font00" style="font-weight: 400;">{{p.content}}</span>
                      </li>
                  </ul>       
          </div>
          <!-- 视频教程 -->
          <div style="background-color: #fff;margin-bottom: 36px;">
              <div class="res-tit">
                      <img src="../../assets/ResourceDetails/course-a.png" alt="">
                      <span class="font28 font25">视频教程</span>
              </div>
             <div class="vvv" v-if="video!=''">
             
                <video v-for="item in video" :key="item"  controls="true"  :src="item.base64"></video>
                <p></p>
             </div>
          </div>
          <!-- 使用须知 -->
          <div style="padding-bottom: 96px;">
            <p  style="display: flex; padding: 0 40px 0; position: relative;" v-if="this.$store.state.resourcesruleForm.notes!=null && this.$store.state.resourcesruleForm.notes!='' || this.$store.state.resourcesruleForm.filename!=''" >
                <span class="font20 font00">
                    <div class="dian"></div>
                    <div class="lefttit fontweight">使用须知:</div>
                </span>
                <span class="font18 font00" style="flex: 1;padding-left: 24px;font-size: 18px;">{{this.$store.state.resourcesruleForm.notes}}</span>
            </p>
            <a class="font18 font00" style="position: relative ;padding-left: 40px; text-decoration: none;margin-top: 36px;display: block;" v-if="this.$store.state.resourcesruleForm.filename!=null && this.$store.state.resourcesruleForm.filename!=''" :href="this.$store.state.resourcesruleForm.fileurl" target="_blank" :download="this.$store.state.resourcesruleForm.fileurl" type="file">
                <div class="dian"></div>
                <div class="lefttit">
                    <span class="font20 font00 fontweight">附件下载：</span>
                </div>
                <span class="font18 font25 hhh move" style="padding-left: 24px;font-size: 18px;">{{this.$store.state.resourcesruleForm.filename}}</span>
            </a>
          </div>

          
          <!-- 操作 -->
          <div style="margin-top: 96px;padding-bottom:96px; margin: auto;">
              <div class="res-tit">
                      <img src="../../assets/ResourceDetails/operation-a.png" alt="">
                      <span class="font28 font25">操作</span>
              </div>
              <ul class="resources-third" style="list-style: none;">
                  <li style="display: flex;">
                    <div class="dian"></div>
                    <span class="font20 font00 fontweight lefttit">预约信息:</span>
                    <span class="font18 font80" >预览中，无法查看预约信息</span>
                  </li>
               
              </ul>
          </div>
      </div>
      <!-- <footers></footers> -->
  </div>
  </template>
  
  <script >
  
  import footers from "@/components/footer.vue"
  export default {
    computed:{
        Information(){
                return this.list.filter(function(data){
                    return data.name!="";
                })
        }
    },
      
      data(){
       
          return{
              time:"",
              Information:[],
              carouseData:[],
              video:[],
            //   视频封面
              img:[]
          }
      },
      methods:{
        backup(){
                window.history.back();
        },
    base64FileHeaderMapper(fileBase64) {
 
    let typedata=fileBase64.split(",")
    let tempType=typedata[0].split("/")[1]
    let fileType=tempType.substring(0,tempType.indexOf(";"))
    return fileType
    // let fileHeader = new Map();

    // //获取不同文件的文件头前3个字作为判断依据
    // fileHeader.set("/9j", "jpeg")
    // fileHeader.set("iVB", "png")
    // // fileHeader.set("Qk0", "BMP")
    // // fileHeader.set("SUk", "TIFF")
    // fileHeader.set("JVB", "pdf")
    // // fileHeader.set("UEs", "OFD")

    // let res = ""

    // //遍历map中所提及的文件头特征
    // fileHeader.forEach((v, k) => {

    //     if (k == fileBase64.substr(0, 3)) {
    //         res = v
            
    //     }
    // })

    // //如果不在map中返回unknown file
    // if (res == "") {
    //     res = "unknown file"
    // }

    // //否则返回map中的value值
    // return res;
},
base64ToBlobvideo(code, type)
{
    code = code.replace(/[\n\r]/g, '');
    let a=code.split(",");
    
    code=a[1]
    var raw = window.atob(code);

    var rawLength = raw.length;

    var uint8Array = new Uint8Array(rawLength);


    for (let i = 0; i < rawLength; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }

    // if ('pdf' === type) {

    //     return new Blob([uint8Array], {type: 'application/pdf'})

    // } else {
        return new Blob([uint8Array], {type: 'video/'+type})

    // }
},
base64ToBlob(code, type) {
    code = code.replace(/[\n\r]/g, '');
    let a=code.split(",");

    code=a[1]
    var raw = window.atob(code);

    var rawLength = raw.length;

    var uint8Array = new Uint8Array(rawLength);


    for (let i = 0; i < rawLength; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }

    // if ('pdf' === type) {

    //     return new Blob([uint8Array], {type: 'application/pdf'})

    // } else {
        return new Blob([uint8Array], {type: 'image/'+type})

    // }


}
},
        

      components:{
        footers
      },
      created(){
       
      },
      mounted(){
        document.querySelector("#top").scrollIntoView(true);
            let a=new Date();
             let year=a.getFullYear();
            let month=a.getMonth()+1;
            let data=a.getDate();
            this.time=year+"-"+month+"-"+data;
            let x=this.$store.state.resourcesruleForm.img1;
            this.carouseData.length=0;
          
            if (this.$store.state.resourcesruleForm.img2[0].base64.substring(0,4)!="http")
            {
                let type=this.base64FileHeaderMapper( this.$store.state.resourcesruleForm.img2[0].base64);
                let url=this.base64ToBlob( this.$store.state.resourcesruleForm.img2[0].base64,type);
                this.carouseData.push({url:URL.createObjectURL(url),type:0})
            }
            else
            {
                this.carouseData.push({url:this.$store.state.resourcesruleForm.img2[0].base64,type:1})
            }
            
           
            

            for ( let i=0;i<x.length;i++)
            {
                if (x[i].base64.substring(0,4)!="http")
                    {
                        let type=this.base64FileHeaderMapper(x[i].base64);
                        let url=this.base64ToBlob(x[i].base64,type);
              
                        this.carouseData.push({url:URL.createObjectURL(url),type:0});
                    }
                    else
                    {
                        this.carouseData.push({url:x[i].base64,type:1});
                    }
                
            }
            
          
            for (let i=0;i<this.$store.state.resourcesruleForm.information.length;i++)
            {
                if (this.$store.state.resourcesruleForm.information[i].name!="" && this.$store.state.resourcesruleForm.information[i].content!="")
                {
                    this.Information.push(this.$store.state.resourcesruleForm.information[i])
                }
            }
           
            //  let vd=this.$store.state.resourcesruleForm.video;
           
            // for (let i=0;i<vd.length;i++)
            // {
            //     if (vd[i].base64.substring(0,4)!="http")
            //     {
            //         let type=this.base64FileHeaderMapper(vd[i].base64);
            //         let url=this.base64ToBlobvideo(vd[i].base64,type);
            //         this.video.push({url:URL.createObjectURL(url)})
            //     }
            //     else
            //     {
            //         this.video.push({url:vd[i].base64})
            //     }
                
            // }
            this.video=this.$store.state.resourcesruleForm.video
         
      }
  }
  </script>
  
  <style scoped>
  .hhh:hover {
    font-weight: 700;
}
  .rightbox {
    z-index: 10086;
    position:fixed;
    bottom:90px;
    right: 60px;
    /* background-color: pink; */
}
.rightbox img {
    width: 48px;
    height: 48px;
    margin-top: 16px;
}
/* PC */
@media screen and (min-width:1201px) {
    .main {
        width: 1366px;
        /* background-color: #00b2a2; */
    }
    .widthh1 {
        max-width: 1032px;
    }
    .myli {
        position: relative;
        display: inline-block;
        /* display: flex;
        flex-direction: column; */
        width: 248px;
        height: 187.5px;
        background-color: #fff;
        margin-right: 31.5px;
        overflow: hidden;
        border-radius: 9px;
    }
    .imgtop {
        width: 248px;
        height: 139.5px;
        vertical-align:middle;
    }
    .myli:nth-child(5n){
        margin-right: 0;
    }
    .myli:nth-child(n+6){
        margin-top: 31.5px;
    }
}
/* 平板 */
@media screen and (max-width:1200px) {
    .main {
        width: 980px;
        /* background-color: pink; */
    }
    .widthh1 {
        max-width: 646px;
    }
    .myli {
        position: relative;
        display: inline-block;
        width: 224px;
        height: 174px;
        /* background-color: #00b2a2; */
        background-color: #fff;
        margin-right: 28px;
        overflow: hidden;
        border-radius: 9px;
    }
    .myli:nth-child(4n){
        margin-right: 0;
    }
    .imgtop {
        width: 224px;
        height: 126px;
    }
    .myli:nth-child(n+5){
        margin-top: 28px;
    }
    .myli img {
        vertical-align: middle;
    }
    .ccc .myli:nth-child(n+5) {
        display: none;
    }
}
.box {
    padding-top: 48px;
}
.body {
    background-color: #fff;
}
.main {
    margin: 0 auto;
}
.res-top {
    /* height: 194px; */
    background-color: #eef3ff;
}
.res-top .top-find {
    padding-top:24px;
    padding-left: 48px;
    box-sizing: border-box;
}

.res-top .top-find img {
    margin: 0 13px;
}
.res-top h1 {
    /* flex: 1; */
    box-sizing: border-box;
    font-weight: 400;
    text-align: center;
    /* background-color: pink; */
    word-wrap: break-word;
}
.res-top .resources-firstul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 36px 0 48px 0; */
    padding: 36px 0 48px 0;
    /* background-color: red; */
}
.res-top .resources-firstul span {
    margin: 0 16px;
}
.res-top .resources-firstul p {
    display: inline-block;
    margin: 0 6px;
}
.res-top .resources-firstul img {
    vertical-align: top;
    width: 14px;
    height: 13px;
}
.resmessgae {
    padding-top: 48px;
}
.res-tit {
    display: flex;
    margin-bottom: 36px;
    
}
.res-tit span {
    margin-left: 12px;
    line-height: 35px;
}
.res-tit img{
    vertical-align: bottom;
    width: 28px;
    height: 30px;
}
.resources-secondul {
    /* margin-top: 48px;
    margin-bottom: 96px; */
    padding: 48px 0 96px 0;
    /* background-color: red; */
}
.resources-secondul p {
    position: absolute;
    top: 14px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    /* margin-right: 24px; */

}
.resources-secondul li {
    position: relative;
    padding: 0 40px;
    box-sizing:border-box;
    /* background-color: pink; */
    /* background: red; */
    display: flex;
}
.resources-secondul li:nth-child(n+1) {
    margin-top:36px ;
}

.resources-secondul li span {
    /* margin-left: 24px; */
    display: inline-block;
    background-color: #fff;
    padding: 0 0 0 24px;
    box-sizing: border-box;
    flex: 1;
}
.lefttit {
    display: inline-block;
    width: 164px;
    /* background-color: pink; */
    padding-left: 32px;
}
.vvv {
    width: 844px;
    height: 485px;
    margin: 0 auto;
    overflow: hidden;
}
video {
    width: 844px;
    height: 485px;
}
.dian {
    position: absolute;
    top: 10px;
    left: 40px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #002580;
    margin-right: 24px;
}
.resources-third {
    padding: 0 40px;
    position: relative;
}
.resources-third span {
    padding-left: 24px;

    /* width: 196px;
    display: inline-block;
    padding-left: 32px;
    box-sizing: border-box;
    background-color: pink; */
}
.bt {
    width: 118px;
    height: 40px;
    border-radius: 20px;
    /* display: block; */
    color: #fff;
    outline: none;
    border: none;
    font-size: 17px;
    margin-left: 24px;
    cursor: pointer;
}
.resources-third-one li {
    padding-left: 24px;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;

    /* background-color: red; */

}
.resources-third-one li:nth-child(n+3) {
    margin-top: 12px;
}
.res-bottom {
    background-color: #fff;
}
.toppinglun {
    position: relative;
    display: flex;
    padding: 0 36px 0 36px;
    /* background-color: #00b2a2; */
}
.toppinglun textarea {
    /* padding-left: 12px; */
    padding: 12px;
    box-sizing: border-box;
    flex: 1;
    border-radius: 9px;
    outline: none;
    border: 2px solid #d9d9d9;
    background-color: #f2f2f2;
    resize: none;
}
.res-bottom button {
    width: 90px;
    height: 36px;
    border-radius: 18px;
    border: none;
    background-color: #002580;
    color: #fff;
}
.res-bottom img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 28px;
}
.read {
    /* background-color: red; */
    padding: 0 36px;
    box-sizing: border-box;
}
.read li {
    display: flex;
    padding: 24px 0;
    box-sizing: border-box;
    border-bottom: 2px solid #d9d9d9;
    /* background-color: red; */
}
.read ul li:nth-last-child(1){
    border: none!important;
    /* background-color: pink; */
}
.xiangguan {
    margin-top: 96px;
    margin-bottom: 24px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
}
.hyh:hover{
    color: #002580;
    cursor: pointer;

}
.shoucanglogo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue.png); 
    background-size: contain;
}
.shoucanglogohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/collect-blue-l.png); 
    background-size: contain;
}
.chuangzuo {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue.png); 
    background-size: contain;
}
.chuangzuohover {
    width: 48px;
    height: 48px;
    background-image: url(../../assets/ResourceDetails/caozuozhe-blue-l.png); 
    background-size: contain;
}
  
  </style>