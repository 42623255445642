<!-- 添加活动界面右侧区域 -->
<template>
  <!-- region 基本信息 -->
  <div @click="hahaha()">
    <div class="divfirst bd" id="topup">
      <h3 class="font18 font25 fontweight">基本信息</h3>
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <!-- <span class="import">*</span> -->
        <el-form-item class="inputlist paddingside" label="标题" prop="name">
          <span class="import pp">*</span>
          <input
            type="text"
            class="titinput inputquan font14"
            v-model="ruleForm.name"
            placeholder="请输入标题"
          />
        </el-form-item>
        <el-form-item class="inputlist paddingside" label="分类" prop="name">
          <span class="import pp">*</span>
          <div class="inputban" @click.stop="pp()" style="position: relative">
            <input
              readonly
              type="text"
              class="titinput inputban font14 move"
              v-model="ruleForm.resource"
              placeholder="请选择活动类型"
            />
            <div :class="{ lowimg: flag, topimg: !flag }"></div>
          </div>

          <div
            class="fufu fufupo1 inputban height386"
            :class="{ a: flag, aaa: !flag }"
            @click.stop="hahaha1()"
          >
            <input
              v-model="resource"
              type="text"
              placeholder="请输入具体地址"
            />
            <div class="fufu-ridiocontent">
              <el-form-item>
                <el-radio-group class="ridio fen" v-model="resource">
                  <el-radio
                    class="font14"
                    v-for="p in backend.resource"
                    :key="p.id"
                    :value="p.type"
                    :label="`${p.type}`"
                  />
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="bottom">
              <button class="btn3 btnblue" @click.stop.prevent="queren1()">
                确认
              </button>
              <button class="btn3 btnwhite" @click.prevent.stop="quxiao1()">
                取消
              </button>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="inputlist paddingside" label="介绍" prop="name">
          <!-- <input type="text" @input="jiesao()" v-model="ruleForm.introduce" class="titinput inputquan font14" placeholder="请输入200字内的介绍"> -->
          <textarea
            placeholder="请输入200字内的介绍"
            @keyup.stop="xyz1"
            v-model="ruleForm.introduce"
            class="inputquan font14"
            style="white-space: pre-wrap"
            :rows="row3"
            maxlength="200"
          ></textarea>
        </el-form-item>
      </el-form>
    </div>
    <!-- 开始上传图片 -->
    <div class="divfirst bd" style="margin-top: 12px">
      <h3 class="font18 font25 fontweight">
        上传图片<span
          class="import"
          style="font-size: 14px; position: relative; right: -4px"
          >*</span
        >
      </h3>
      <div class="pa">
        <imgUploader
          :iga="ruleForm.img1"
          ref="pic1"
          :width="200"
          :height="200"
          :limit="4"
          @empty="emptyShow"
          @upload="uploadimgone()"
          @change="img1()"
        >
        </imgUploader>
        <p class="font13 font00 fontt">
          (支持jpg/gif/png格式，RGB模式，大小1920*1080。)
        </p>
        <p class="font16 font00 fonttit">设置封面</p>
        <imgUploader
          :iga="ruleForm.img2"
          ref="pic2"
          :width="200"
          :height="200"
          :limit="1"
          @empty="emptyShow"
          @upload="uploadimgtwo()"
          @change="img2()"
        >
        </imgUploader>
        <p class="font13 font00 fontt">
          (支持jpg/gif/png格式，RGB模式，大小1920*1080。)
        </p>
      </div>
    </div>
    <!-- 结束上传图片 -->

    <!-- 上传视频 -->

    <div class="divfirst bd" style="margin-top: 12px">
      <h3 class="font18 font25 fontweight">上传视频</h3>
      <div class="pa">
        <div>
          <videoUploader
            :via="this.ruleForm.video"
            ref="pic3"
            :width="200"
            :height="200"
            :limit="3"
            @empty="emptyShow"
            @upload="uploadVideo()"
            @change="vid1()"
          >
          </videoUploader>
        </div>
        <p class="font13 font00 fontt">
          (最多上传3个视频，单个视频不超过500M。)
        </p>
        <p class="font16 font00 fonttit">上传视频封面</p>
        <imgUploader
          :iga="ruleForm.img3"
          ref="pic4"
          :width="200"
          :height="200"
          :limit="1"
          @empty="emptyShow"
          @upload="uploadimgthree()"
          @change="img3()"
        ></imgUploader>
        <p class="font13 font00 fontt">
          (支持jpg/gif/png格式，RGB模式，大小1920*1080。)
        </p>
      </div>
    </div>
    <!-- 结束上传视频 -->

    <!-- 详细信息 -->
    <div class="divfirst bd" style="margin-top: 12px">
      <h3 class="font18 font25 fontweight">详细信息</h3>
      <div class="paddingside">
        <el-form class="message" :model="ruleForm">
          <el-form-item label="申请加入">
            <el-radio-group
              v-model="ruleForm.applyToJoin"
              style="flex-wrap: nowrap; align-self: center"
            >
              <el-radio label="禁止" />
              <el-radio label="允许" />
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否公开" style="position: relative">
            <span class="import one">*</span>
            <el-radio-group v-model="ruleForm.open" style="flex-wrap: nowrap">
              <el-radio label="是" />
              <el-radio style="margin-left: 14px" label="否" />
            </el-radio-group>
          </el-form-item>
        </el-form>

        <div class="messagelist">
          <span>起始时间</span>
          <el-date-picker
            v-model="ruleForm.startingTime"
            type="datetime"
            placeholder="请选择开始时间"
            :poper-addend-to-body="false"
          />
        </div>
        <div class="messagelist">
          <span>结束时间</span>
          <el-date-picker
            v-model="ruleForm.endOfTime"
            type="datetime"
            placeholder="请选择结束时间"
            :poper-addend-to-body="false"
          />
        </div>
        <div class="messagelist">
          <span class="timebd">活动地点</span>
          <div style="position: relative; display: inline-block">
            <input
              readonly
              type="text"
              @click.stop="space1()"
              class="titinput inputban font14 move"
              v-model="ruleForm.activitySite"
              placeholder="请选择活动地点"
            />
            <div :class="{ lowimg: space, topimg: !space }"></div>
            <div
              class="fufu fufupo2 inputban height386"
              :class="{ a: space, aaa: !space }"
              @click.stop="hahaha2()"
            >
              <div class="seach">
                <img src="../../assets/index/seach.png" alt="" />
                <input
                  @input="huodongdi()"
                  v-model="ActivitySite1"
                  placeholder="请输入关键词搜索"
                  type="text"
                />
              </div>

              <div class="fufu-ridiocontent">
                <el-form-item>
                  <el-radio-group class="ridio" v-model="ActivitySite">
                    <el-radio
                      class="font14 res"
                      @click.stop.prevent="aaa(p.name)"
                      v-for="(p, index) in backend.ActivitySite"
                      :key="index"
                      :label="p.name"
                    />
                  </el-radio-group>
                </el-form-item>

                <div class="diy">
                  <span class="font25 font14">自定义地址</span>
                  <input
                    @input="zidingyididiana()"
                    v-model="zidingyididian"
                    class="diyinput"
                    type="text"
                    placeholder="请输入具体地址"
                  />
                </div>
              </div>

              <div class="bottom">
                <button class="btn3 btnblue" @click.prevent.stop="queren2()">
                  确认
                </button>
                <button class="btn3 btnwhite" @click.prevent.stop="quxiao2()">
                  取消
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="messagelist flexone">
          <span>成果归属</span>
          <div class="long move" style="position: relative">
            <textarea
              @click.stop="guishu1()"
              readonly
              placeholder="请选择成果归属"
              v-model="ruleForm.attributionResults"
              class="move inputquan font14"
              :rows="row1"
            ></textarea>
            <!-- <input readonly type="text" @input="biaoti()" class="titinput font14" v-model="ruleForm.name" placeholder="请选择成果归属人员"> -->
            <div :class="{ lowimg: guishu, topimg: !guishu }"></div>
            <div
              class="fufu fufupo4 inputquan height386"
              :class="{ a: guishu, aaa: !guishu }"
              @click.stop="hahaha3()"
            >
              <div class="seach">
                <img src="../../assets/index/seach.png" alt="" />
                <input
                  @input="guishusearch()"
                  v-model="chengguoguishu"
                  placeholder="请输入关键词搜索"
                  style="margin-bottom: 12px"
                />
              </div>

              <div class="fufu-ridiocontent">
                <el-form-item>
                  <el-checkbox-group class="ridio" v-model="AttributionResults">
                    <el-checkbox
                      class="font14"
                      v-for="(p, index) in backend.AttributionResults"
                      :key="index"
                      :label="p.title"
                      name="type"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>

              <div class="bottom">
                <button class="btn3 btnblue" @click.prevent.stop="queren3()">
                  确认
                </button>
                <button class="btn3 btnwhite" @click.prevent.stop="quxiao3()">
                  取消
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="messagelist flexone">
          <span>邀请加入</span>
          <div class="long move">
            <textarea
              @click.stop="join1()"
              style="white-space: pre-wrap"
              readonly
              placeholder="请选择需邀请的成员"
              :rows="row2"
              class="inputquan font14 move"
              v-model="this.ruleForm.inviteMembers"
            ></textarea>
            <div :class="{ lowimg: join, topimg: !join }"></div>
            <div
              class="fufu fufupo3 inputquan height386"
              :class="{ a: join, aaa: !join }"
              @click.stop="hahaha4()"
            >
              <div class="seach">
                <img src="../../assets/index/seach.png" alt="" />
                <input
                  @input="yaoyingsearch()"
                  v-model="yaoqing"
                  placeholder="请输入关键词搜索"
                  style="margin-bottom: 12px"
                />
              </div>

              <div class="fufu-ridiocontent">
                <el-form-item>
                  <el-checkbox-group class="ridio" v-model="InviteMembers">
                    <el-checkbox
                      class="font14"
                      v-for="(p, index) in backend.InviteMembers"
                      :key="index"
                      :label="p.username + ' ' + p.sys"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>

              <div class="bottom">
                <button class="btn3 btnblue" @click.prevent.stop="queren4()">
                  确认
                </button>
                <button class="btn3 btnwhite" @click.prevent.stop="quxiao4()">
                  取消
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 结束详细信息 -->

    <!--其他信息  -->
    <div class="divfirst bd" style="margin-top: 12px">
      <h3 class="font18 font25 fontweight">其他信息</h3>
      <div class="paddingside other">
        <div
          id="addothers"
          class="otherlist"
          v-for="(p, index) in ruleForm.information"
          :key="index"
        >
          <img
            @click.stop="Reductionother(index)"
            class="jian move"
            src="../../assets/AddinganActivity/jian.png"
            alt=""
          />
          <div class="ol">
            <div class="otherbox">
              <p class="marginright12">信息名称{{ index + 1 }}</p>
              <textarea
                maxlength="18"
                @keyup="xyz3($event, index)"
                :rows="p.leng1"
                style="white-space: pre-wrap"
                class="inputban"
                v-model="p.name"
                placeholder="请填写信息名称"
              ></textarea>
            </div>
            <div class="margintop28 flexone">
              <p class="marginright12">信息内容{{ index + 1 }}</p>
              <div class="long">
                <textarea
                  maxlength="100"
                  :rows="p.leng2"
                  @keyup="xyz4($event, index)"
                  v-model="p.content"
                  placeholder="请填写信息内容"
                  class="inputban"
                  style="white-space: pre-wrap"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="margintop24">
          <img
            @click.stop="addother()"
            class="jia move"
            src="../../assets/AddinganActivity/jia.png"
            alt=""
          />
          <span @click.stop="addother()" class="font25 move"
            >添加更多信息名称/内容</span
          >
        </div>
      </div>
    </div>

    <!-- 活动详情 -->
    <div class="divfirst bd margintop12">
      <h3 class="font18 font25 fontweight">活动详情</h3>
      <div style="padding: 24px 24px 0 24px">
        <wangEditor ref="wangeditor" style="z-index: 1000"></wangEditor>
      </div>
    </div>

    <!-- 结束活动详情 -->
    <div class="btnbottomres margintop64">
      <button
        class="btn2 btnblue move"
        style="border: none; margin-right: 64px"
        @click.stop="add()"
      >
        添加
      </button>
      <button class="btn2 btntrs move" @click.stop="preview()">预览</button>
    </div>
    <img
      class="topbtn move"
      @click.stop="BackToTheTop('#topup')"
      src="../../assets/EventDetails/top.png"
      alt="图片不见啦！"
    />
  </div>
</template>
<script>
import axios from "@/Api/axios";
import wangEditor from "@/components/wangEditor.vue";
import footers from "@/components/footer.vue";
import timechock from "@/components/time.vue";
import calendar from "../../components/vue-calendar-component/calendar.vue";
import { ElMessage } from "element-plus";
import { forEach } from "lodash";
import router from "@/router";
export default {
  components: {
    wangEditor,
    footers,
    calendar,
    timechock,
  },
  //PCgetAll
  async mounted() {
    // 获取成果归属
    await axios.get("project").then((res) => {
      this.backend.AttributionResults = res.data.data;

      this.backend.AttributionResults2 = res.data.data;
    });

    // 接收后端的分类
    await axios.get("PC/type/getActivityType").then((res) => {
      this.backend.resource = res.data.data;
      this.backend.resource2 = res.data.data;
    });
    // 接受活动地点
    await axios.get("place").then((res) => {
      this.backend.ActivitySite = res.data.data;
      this.backend.ActivitySite2 = res.data.data;
    });

    // 接受邀请加入
    await axios
      .get("PC/wechat_user/getAllNotMe/" + this.$cookies.get("selectValue").id)
      .then((res) => {
        this.backend.InviteMembers = res.data.data;
        this.backend.InviteMembers2 = res.data.data;
      });

    let leng = window.location.href.indexOf("homepage") - 2;

    if (JSON.stringify(this.$store.state.activeruleForm) != "{}") {
      this.ruleForm = this.$store.state.activeruleForm;
      if (this.ruleForm.startingTime != "" && this.ruleForm.endOfTime != "") {
        this.showstarttime = this.timechulitwo(this.ruleForm.startingTime);
        this.showendtime = this.timechulitwo(this.ruleForm.endOfTime);
      }
      if (this.ruleForm.attributionResults != "") {
        this.AttributionResults = this.$store.state.cguo;
        this.row1 = Math.floor((this.AttributionResults.length - 1) / 3) + 1;
      }

      if (
        this.ruleForm.inviteMembers != "" &&
        this.ruleForm.inviteMembers != null &&
        this.ruleForm.inviteMembers != []
      ) {
        // this.InviteMembers=this.ruleForm.inviteMembers.split(",");
        this.InviteMembers = this.$store.state.yaoqing;
        this.row2 = Math.floor((this.InviteMembers.length - 1) / 3) + 1;
      }
      this.row3 = this.ruleForm.introduce.split("\n").length;
      // window.location.href.substring(leng + 1, window.location.href.length) !=
      // "/homepage/activitiesresourcesmain/activitiess"
    } else if (window.location.href.indexOf("MyWord") == -1) {
      if (this.$route.query.paramName != null) {
        await axios
          .get("PC/project/" + this.$route.query.paramName)
          .then((res) => {
            let a = res.data.data;
            this.ruleForm.w_id = this.$cookies.get("selectValue").id;
            this.ruleForm.name = a.title;
            this.ruleForm.resource = a.t_type;
            this.resource = a.t_type;
            this.ruleForm.introduce = a.context;
            this.row3 = this.ruleForm.introduce.split("\n").length;
            this.ruleForm.applyToJoin = a.applyuse == 1 ? "允许" : "禁止";
            this.ruleForm.startingTime = a.begintime;
            this.ruleForm.endOfTime = a.endtime;
            if (a.begintime != "") {
              this.showstarttime = this.timechulitwo(a.begintime);
            }
            if (a.endtime != "") {
              this.showendtime = this.timechulitwo(a.endtime);
            }

            this.ruleForm.activitySite = a.space;
            this.ActivitySite = a.space;
            this.ruleForm.inviteMembers = a.p_number;
            this.ruleForm.open = a.open == 1 ? "是" : "否";

            // this.ruleForm.richtext = a.richtext;
            this.ruleForm.richtext = a.richtext;
          });
      }

      await axios.get("PC/image/" + this.$route.query.paramName).then((res) => {
        let a = res.data.data;
        if (a != null) {
          for (let i = 0; i < a.length; i++) {
            if (a[i].type == 2) {
              this.ruleForm.img1.push({
                base64: this.getServerUrl() + "/1image/" + a[i].url,
              });
            } else if (a[i].type == 1) {
              this.ruleForm.img2.push({
                base64: this.getServerUrl() + "/1image/" + a[i].url,
              });
            } else if (a[i].type == 4) {
              this.ruleForm.img3.push({
                base64: this.getServerUrl() + "/1image/" + a[i].url,
              });
            } else if (a[i].type == 5) {
              this.ruleForm.video.push({
                base64: this.getServerUrl() + "/video/" + a[i].url,
              });
            }
          }
        }
      });

      //  其它信息
      await axios.get("PC/other/" + this.$route.query.paramName).then((res) => {
        let a = res.data.data;

        if (a != null) {
          this.ruleForm.information = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let a1 = a[i].title.split("\n").length;
            let a2 = a[i].context.split("\n").length;
            this.ruleForm.information.push({
              id: a[i].id,
              name: a[i].title,
              content: a[i].context,
              leng1: a1,
              leng2: a2,
            });
          }
          if (res.data.data.length == 0) {
            this.ruleForm.information.push({
              id: 1,
              name: "",
              content: "",
              leng1: 1,
              leng2: 1,
            });
          }
        }
      });
      //获取这个项目的成果归属

      await axios
        .get("achievement/" + this.$route.query.paramName)
        .then((res) => {
          this.ruleForm.attributionResults = "";
          this.AttributionResults = [];

          if (res.data.data != null) {
            for (let i = 0; i < res.data.data.length; i++) {
              for (let j = 0; j < this.backend.AttributionResults.length; j++) {
                if (res.data.data[i].title==this.backend.AttributionResults[j].title) {
                  this.shui.push(this.backend.AttributionResults[j].id);

                  this.AttributionResults.push(
                    this.backend.AttributionResults[j].title
                  );
                  if (i < res.data.data.length - 1 && (i + 1) % 3 != 0) {
                    this.ruleForm.attributionResults =
                      this.ruleForm.attributionResults +
                      this.backend.AttributionResults[j].title +
                      ",";
                  } else if (
                    (i + 1) % 3 == 0 &&
                    i + 1 != res.data.data.length
                  ) {
                    this.ruleForm.attributionResults =
                      this.ruleForm.attributionResults +
                      this.backend.AttributionResults[j].title +
                      "\n";
                  } else {
                    this.ruleForm.attributionResults =
                      this.ruleForm.attributionResults +
                      this.backend.AttributionResults[j].title;
                  }
                }
              }
            }
          }
          this.$store.commit("cguo", this.AttributionResults);
          this.row1 = Math.floor((this.AttributionResults.length - 1) / 3) + 1;
        });
      let menber = {
        p_id: this.$route.query.paramName,
      };
      await axios.post("member/SelectMenberByPid", menber).then((res) => {
        this.InviteMembers = [];
        this.ruleForm.inviteMembers = [];
        for (let i = 0; i < res.data.data.length; i++) {
          for (let j = 0; j < this.backend.InviteMembers.length; j++) {
            if (
              res.data.data[i].sys_number == this.backend.InviteMembers[j].sys
            ) {
              this.InviteMembers.push(
                this.backend.InviteMembers[j].username +
                  " " +
                  this.backend.InviteMembers[j].sys
              );
              if (i < res.data.data.length - 1 && (i + 1) % 3 != 0) {
                this.ruleForm.inviteMembers =
                  this.ruleForm.inviteMembers +
                  this.backend.InviteMembers[j].username +
                  " " +
                  this.backend.InviteMembers[j].sys +
                  ",";
              } else if ((i + 1) % 3 == 0 && i + 1 != res.data.data.length) {
                this.ruleForm.inviteMembers =
                  this.ruleForm.inviteMembers +
                  this.backend.InviteMembers[j].username +
                  " " +
                  this.backend.InviteMembers[j].sys +
                  "\n";
              } else {
                this.ruleForm.inviteMembers =
                  this.ruleForm.inviteMembers +
                  this.backend.InviteMembers[j].username +
                  " " +
                  this.backend.InviteMembers[j].sys;
              }
            }
          }
        }

        if (
          this.InviteMembers != "" &&
          this.InviteMembers != null &&
          this.InviteMembers != []
        ) {
          this.row2 = Math.floor((this.InviteMembers.length - 1) / 3) + 1;
        }
      });
    } else {
      this.ruleForm.information.push({
        id: 1,
        name: "",
        content: "",
        leng1: 1,
        leng2: 1,
      });
    }
  },
  data() {
    return {
      dialogImageUrl: "",
      ActivitySite1: "",
      ActivitySite2: "",
      imageUrltwo: "",
      dialogVisible: false,
      disabled: false,
      TheSpecificActivity: "",
      resource: "",
      ActivitySite: "",
      AttributionResults: [],
      zidingyididian: "",
      InviteMembers: [],

      region: [
        { key: "Zone one", value: "shanghai" },
        { key: "Zone two", value: "beijing" },
      ],
      ruleForm: {
        // 作者
        w_id: "",
        // 标题
        name: "",
        // 介绍
        introduce: "",
        // 分类
        resource: "",
        // 上传图片1
        img1: [],
        // 上传图片2
        img2: [],
        // 上传视频
        video: [],
        // 上传图片3
        img3: [],
        // 申请加入
        applyToJoin: "",
        // 开始时间
        startingTime: "",
        // 结束时间
        endOfTime: "",
        // 活动地点
        activitySite: "",
        // 成果归属
        attributionResults: "",
        // 邀请成员
        inviteMembers: "",
        open: "",
        // 其他信息
        information: [],

        // 富文本内容
        richtext: "",
      },
      ruleFormtwo: {
        // 作者
        w_id: "",
        // 标题
        name: "",
        // 介绍
        introduce: "",
        // 分类
        resource: "",
        // 上传图片1
        img1: [],
        // 上传图片2
        img2: [],
        // 上传视频
        video: [],
        // 上传图片3
        img3: [],
        // 申请加入
        applyToJoin: "",
        // 开始时间
        startingTime: "",
        // 结束时间
        endOfTime: "",
        // 活动地点
        activitySite: "",
        // 成果归属
        attributionResults: [],
        // 邀请成员
        inviteMembers: [],
        // 其他信息
        information: [],
        // 富文本内容
        richtext: "",
        open: "",
      },
      // rules: {
      //   name: "asdasd",
      //   region: "",
      // },

      // },

      strattime: false,
      end: false,

      flag: true,
      space: true,
      guishu: true,
      join: true,

      // 起始时间三个参数
      starttimea: { a: "上午", hour: "0", minute: "0" },
      // 结束时间三个参数
      endtimea: { a: "上午", hour: "0", minute: "0" },
      // 起始日期
      startdate: "",
      // 结束日期
      enddate: "",
      // 启始时间在文本框中的显示
      showstarttime: "",
      // 结束时间在文本框中的显示
      showendtime: "",
      // 后端接收
      backend: {
        // 分类
        resource: [],
        resource2: [],
        // 活动地点
        ActivitySite: [],
        ActivitySite2: [],
        // 成果归属
        AttributionResults: [],
        AttributionResults2: [],
        // 邀请成员
        InviteMembers: [],
        InviteMembers2: [],
      },
      // 其它信息的个数
      rowother: 1,
      //  成果归属搜索
      chengguoguishu: "",
      // 邀请加入搜索
      yaoqing: "",
      row1: 1,
      row2: 1,
      row3: 1,

      shui: [],
    };
  },

  methods: {
    // 时间比较
    duibi(a, b) {
      a = a.toString();
      b = b.toString();
      var arr = a.split("-");

      var arr2 = arr[2].split(" ");
      var arr3 = arr2[1].split(":");

      var starttime = new Date(
        arr[0],
        arr[1],
        arr2[0],
        arr3[0],
        arr3[1],
        arr3[2]
      );
      var starttimes = starttime.getTime();

      var arrs = b.split("-");
      var arr2s = arrs[2].split(" ");
      var arr3s = arr2s[1].split(":");

      var lktime = new Date(
        arrs[0],
        arrs[1],
        arr2s[0],
        arr3s[0],
        arr3s[1],
        arr3s[2]
      );
      var lktimes = lktime.getTime();

      if (starttimes >= lktimes) {
        return 1;
      } else {
        return 2;
      }
    },

    xyz3(e, index) {
      if (e.key == "Enter") {
        this.ruleForm.information[index].leng1 =
          this.ruleForm.information[index].leng1 + 1;
      } else if (e.key == "Backspace") {
        if (this.ruleForm.information[index].leng1 > 1) {
          this.ruleForm.information[index].leng1 -= 1;
        }
      }
    },
    xyz4(e, index) {
      if (e.key == "Enter") {
        this.ruleForm.information[index].leng2 += 1;
      } else if (e.key == "Backspace") {
        if (this.ruleForm.information[index].leng2 > 1) {
          this.ruleForm.information[index].leng2 -= 1;
        }
      }
    },
    xyz1(e) {
      if (e.key == "Enter") {
        this.row3 = this.row3 + 1;
      } else if (e.key == "Backspace") {
        if (this.row3 > 1) {
          this.row3 = this.row3 - 1;
        }
      }
    },
    zidingyididiana() {
      this.ActivitySite = "";
    },
    aaa(value) {
      value == this.ActivitySite
        ? (this.ActivitySite = "")
        : (this.ActivitySite = value);
    },
    hahaha4() {
      this.flag = true;
      this.space = true;
      this.guishu = true;
      this.join = false;
    },
    hahaha3() {
      this.flag = true;
      this.space = true;
      this.guishu = false;
      this.join = true;
    },
    hahaha2() {
      this.flag = true;
      this.space = false;
      this.guishu = true;
      this.join = true;
    },
    hahaha1() {
      this.flag = false;
      this.space = true;
      this.guishu = true;
      this.join = true;
    },
    hahaha() {
      this.flag = true;
      this.space = true;
      this.guishu = true;
      this.join = true;
    },
    // 回到顶部
    BackToTheTop(e) {
      document.querySelector(e).scrollIntoView(true);
    },
    huodongdi() {
      this.ActivitySite = this.ActivitySite1;
    },
    zidingyi() {
      this.ActivitySite = "";
      this.ActivitySite1 = "";
    },

    jiesao() {
      if (this.ruleForm.introduce.length >= 200) {
        this.ruleForm.introduce = this.ruleForm.name.substring(0, 200);
      }
    },
    getServerUrl() {
      return axios.getServerUrl();
    },
    // 成果归属搜索
    guishusearch() {
      let a = [];
      axios.get("project").then((res) => {
        a = res.data.data;
        this.backend.AttributionResults.length = 0;
        for (let i = 0; i < a.length; i++) {
          if (a[i].title.indexOf(this.chengguoguishu) != -1) {
            this.backend.AttributionResults.push(a[i]);
          }
        }
      });
    },
    // 邀请加入搜索
    yaoyingsearch() {
      let a = [];
      axios
        .get(
          "PC/wechat_user/getAllNotMe/" + this.$cookies.get("selectValue").id
        )
        .then((res) => {
          a = res.data.data;
          this.backend.InviteMembers.length = 0;
          for (let i = 0; i < a.length; i++) {
            if (
              (a[i].username + "     " + a[i].sys_number).indexOf(
                this.yaoqing
              ) != -1
            ) {
              this.backend.InviteMembers.push(a[i]);
            }
          }
        });
    },
    // 预览
    preview() {
      // let vm=this;

      if (
        (this.ruleForm.startingTime == "" ||
          this.ruleForm.startingTime == null) &&
        this.ruleForm.endOfTime != "" &&
        this.ruleForm.endOfTime != null
      ) {
        ElMessage({
          showClose: true,
          message: "请选择开始时间",
          type: "warning",
        });
        return false;
      }

      this.ruleForm.richtext = this.$refs.wangeditor.valueHtml;
      if (
        this.ruleForm.startingTime != "" &&
        this.ruleForm.startingTime != null
      ) {
        this.ruleForm.startingTime = this.timechulithree(
          this.ruleForm.startingTime
        );
      } else {
        this.ruleForm.startingTime = "";
      }
      if (this.ruleForm.endOfTime != "" && this.ruleForm.endOfTime != null) {
        this.ruleForm.endOfTime = this.timechulithree(this.ruleForm.endOfTime);
      } else {
        this.ruleForm.endOfTime = "";
      }
      if (
        this.ruleForm.startingTime != "" &&
        this.ruleForm.startingTime != null &&
        this.ruleForm.endOfTime != "" &&
        this.ruleForm.endOfTime != null
      ) {
        let a = this.duibi(this.ruleForm.startingTime, this.ruleForm.endOfTime);
        if (a == 1) {
          ElMessage({
            showClose: true,
            message: "您的结束时间大于开始时间",
            type: "warning",
          });
          return false;
        }
      }
      if (this.ruleForm.name == "") {
        ElMessage({
          showClose: true,
          message: "标题不能为空",
          type: "warning",
        });
      } else if (this.ruleForm.resource == "") {
        ElMessage({
          showClose: true,
          message: "请选择分类",
          type: "warning",
        });
      } else if (this.ruleForm.img1 == "" || this.ruleForm.img2 == "") {
        ElMessage({
          showClose: true,
          message: "上传图片和图片封面不能为空",
          type: "warning",
        });
      } else if (this.ruleForm.video.length != 0 && this.ruleForm.img3 == "") {
        ElMessage({
          showClose: true,
          message: "您上传了视频，必须上传视频封面",
          type: "warning",
        });
      } else if (this.ruleForm.video == "" && this.ruleForm.img3 != "") {
        ElMessage({
          showClose: true,
          message: "您上传了视频封面，没有上传视频",
          type: "warning",
        });
      } else {
        axios
          .post("PC/project/savepreview", this.ruleForm.video)
          .then((res) => {
            this.ruleForm.video = [];
            for (let i = 0; i < res.data.data.length; i++) {
              let b = res.data.data[i].base64.indexOf(".");
              let c = res.data.data[i].base64.substring(0, b);
              if (isNaN(c) == true) {
                this.ruleForm.video.push({ base64: res.data.data[i].base64 });
              } else {
                this.ruleForm.video.push({
                  base64:
                    this.getServerUrl() + "/video/" + res.data.data[i].base64,
                });
              }
            }
            this.$store.commit("activecontent", this.ruleForm);
            localStorage.setItem("abcde", this.ruleForm.richtext);
            router.push({
              name: "eventdetailspreview",
            });
          });
      }
    },
    //时间处理函数3
    timechulithree(value) {
      let date = new Date(value);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 1 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 时间处理函数2
    timechulitwo(value) {
      let a = value.replace("-", "/");
      let b = a.replace("-", "/");
      // 日期处理完
      let c = b.replace(" ", "日");

      let d = c.indexOf("日");
      let e = c.indexOf(":");
      let f = c.substring(d + 1, e);

      let g = "上午";
      if (f[0] == "0") {
        g = "上午";
      } else {
        g = "下午";
      }
      let h = c.replace(":", "时");
      let j = h.indexOf("日");

      let i = h.substring(0, j + 1) + g + h.substring(j + 1, h.length) + "分";
      return i;
    },
    // 时间处理工具类
    timechuli(value) {
      let a = value.indexOf("日");
      let b = value.substr(0, a);
      let c = b.replace("/", "-");
      // 这是日期
      let d = c.replace("/", "-");
      let e = value.indexOf("时");
      // 这是时
      let f = value.substring(a + 3, e);
      if (f.length == 1) {
        f = "0" + f;
      }
      let g = value.indexOf("分");
      // 这是分
      let h = value.substring(e + 1, g);
      if (h.length == 1) {
        h = "0" + h;
      }
      return d + " " + f + ":" + h;
    },
    // 成果归属处理
    cguo(value) {
      let a = value;
      this.shui = [];
      for (let i = 0; i < this.backend.AttributionResults2.length; i++) {
        for (let j = 0; j < a.length; j++) {
          if (this.backend.AttributionResults2[i].title == a[j]) {
            this.shui.push(this.backend.AttributionResults2[i].id);
          }
        }
      }
    },
    add() {
      let vm = this;
      //  if (this.ruleForm.startingTime==" :")
      //  {
      //     this.ruleForm.startingTime=""
      //  }
      //  if (this.ruleForm.endOfTime==" :")
      //  {
      //    this.ruleForm.endOfTime="";
      //  }

      if (
        (this.ruleForm.startingTime == "" ||
          this.ruleForm.startingTime == null) &&
        this.ruleForm.endOfTime != "" &&
        this.ruleForm.endOfTime != null
      ) {
        ElMessage({
          showClose: true,
          message: "请选择开始时间",
          type: "warning",
        });
        return false;
      }
      if (
        this.ruleForm.startingTime != "" &&
        this.ruleForm.startingTime != null &&
        (this.ruleForm.endOfTime == "" || this.ruleForm.endOfTime == null)
      ) {
        ElMessage({
          showClose: true,
          message: "请选择结束时间",
          type: "warning",
        });
        return false;
      }
      this.ruleForm.richtext = this.$refs.wangeditor.valueHtml;
      this.ruleForm.w_id = this.$cookies.get("selectValue").id;
      if (
        this.ruleForm.startingTime != "" &&
        this.ruleForm.startingTime != null
      ) {
        this.ruleForm.startingTime = this.timechulithree(
          this.ruleForm.startingTime
        );
      } else {
        this.ruleForm.startingTime = "";
      }
      if (this.ruleForm.endOfTime != "" && this.ruleForm.endOfTime != null) {
        this.ruleForm.endOfTime = this.timechulithree(this.ruleForm.endOfTime);
      } else {
        this.ruleForm.endOfTime = "";
      }
      if (
        this.ruleForm.startingTime != "" &&
        this.ruleForm.startingTime != null &&
        this.ruleForm.endOfTime != "" &&
        this.ruleForm.endOfTime != null
      ) {
        let a = this.duibi(this.ruleForm.startingTime, this.ruleForm.endOfTime);
        if (a == 1) {
          ElMessage({
            showClose: true,
            message: "您的结束时间大于开始时间",
            type: "warning",
          });
          return false;
        }
      }
      if (this.ruleForm.name == "") {
        ElMessage({
          showClose: true,
          message: "标题不能为空",
          type: "warning",
        });
      } else if (this.ruleForm.resource == "") {
        ElMessage({
          showClose: true,
          message: "请选择分类",
          type: "warning",
        });
      } else if (this.ruleForm.img1 == "" || this.ruleForm.img2 == "") {
        ElMessage({
          showClose: true,
          message: "图片和图片封面不能为空",
          type: "warning",
        });
      } else if (this.ruleForm.video.length != 0 && this.ruleForm.img3 == "") {
        ElMessage({
          showClose: true,
          message: "您上传了视频，必须上传视频封面",
          type: "warning",
        });
      } else if (this.ruleForm.video == "" && this.ruleForm.img3 != "") {
        ElMessage({
          showClose: true,
          message: "您上传了视频封面，没有上传视频",
          type: "warning",
        });
      } else if (this.ruleForm.open == "") {
        ElMessage({
          showClose: true,
          message: "请选择是否公开",
          type: "warning",
        });
      } else {
        let aa = JSON.stringify(this.ruleForm);
        let bb = JSON.parse(aa);
        this.ruleFormtwo = bb;
        this.ruleFormtwo.attributionResults = this.shui;
        //     if (this.ruleFormtwo.startingTime==" :")
        //  {
        //     this.ruleFormtwo.startingTime=""
        //  }
        //  if (this.ruleFormtwo.endOfTime==" :")
        //  {
        //    this.ruleFormtwo.endOfTime="";
        //  }
        // 分类处理

        // let a=this.ruleFormtwo.attributionResults.split(",");
        // this.ruleFormtwo.attributionResults=[];
        // for (let i=0;i<this.backend.AttributionResults2.length;i++)
        // {
        //    for (let j=0;j<a.length;j++)
        //    {
        //     if (this.backend.AttributionResults2[i].title==a[j])
        //     {
        //         this.ruleFormtwo.attributionResults.push(this.backend.AttributionResults2[i].id)
        //     }
        //    }
        // }

        if (
          this.ruleFormtwo.inviteMembers != "" &&
          this.ruleFormtwo.inviteMembers != [] &&
          this.ruleFormtwo.inviteMembers != null
        ) {
          let b = this.ruleFormtwo.inviteMembers.split(", ");
          this.ruleFormtwo.inviteMembers = [];
          for (let i = 0; i < this.backend.InviteMembers2.length; i++) {
            for (let j = 0; j < b.length; j++) {
              if (
                this.backend.InviteMembers2[i].username == b[j].split(" ")[0]
              ) {
                this.ruleFormtwo.inviteMembers.push(
                  this.backend.InviteMembers2[i].id
                );
              }
            }
          }
        } else {
          this.ruleFormtwo.inviteMembers = [];
        }

        // 其他信息处理
        if (
          this.ruleFormtwo.information[this.ruleFormtwo.information.length - 1]
            .name == "" ||
          this.ruleFormtwo.information[this.ruleFormtwo.information.length - 1]
            .content == ""
        ) {
          this.ruleFormtwo.information = this.ruleFormtwo.information.slice(
            0,
            -1
          );
        }
        if (this.ruleForm.open == "是") {
          this.ruleFormtwo.open = 1;
        } else {
          this.ruleFormtwo.open = 0;
        }
        let leng = window.location.href.indexOf("homepage") - 2;
        if (
          window.location.href.substring(
            leng + 1,
            window.location.href.length
          ) != "/homepage/activitiesresourcesmain/activities"
        ) {
          this.ruleFormtwo.id = this.$route.query.paramName;
        }
        for (let i = 0; i < this.backend.resource2.length; i++) {
          if (this.backend.resource2[i].type == this.ruleForm.resource) {
            this.ruleFormtwo.resource = this.backend.resource2[i].id;
          }
        }
        // this.ruleFormtwo.spaceid=this.$route.query.MyWord
        axios.post("PC/project/active/accept", this.ruleFormtwo).then((res) => {
          if (res.data.msg == "发布成功") {
            ElMessage({
              showClose: true,
              message: "发布成功",
              type: "success",
            });
            axios
              .post(
                "member/" +
                  this.$cookies.get("selectValue").id +
                  "/add/" +
                  res.data.data,
                this.ruleFormtwo.inviteMembers
              )
              .then((res) => {});
            this.$router.push({
              path: "/homepage/eventdetails",
              query: { paramName: res.data.data, page: 5 },
            });
          } else if (res.data.msg == "活动名不能重复") {
            ElMessage({
              showClose: true,
              message: "活动名不能重复!",
              type: "warning",
            });
          } else if (res.data.msg == "修改成功") {
            ElMessage({
              showClose: true,
              message: "修改成功",
              type: "success",
            });

            axios
              .post(
                "member/" +
                  this.$cookies.get("selectValue").id +
                  "/put/" +
                  res.data.data,
                this.ruleFormtwo.inviteMembers
              )
              .then((res) => {});
            this.$router.push({
              path: "/homepage/eventdetails",
              query: { paramName: res.data.data, page: 5 },
            });
          } else if (res.data.msg == "修改失败") {
            ElMessage({
              showClose: true,
              message: "修改失败",
              type: "warning",
            });
          } else {
            ElMessage({
              showClose: true,
              message: "发布失败!",
              type: "warning",
            });
          }
        });
      }
    },
    img1() {
      let vm = this;
      let b = vm.$refs.pic1.imgUrl;
      this.ruleForm.img1 = b;
    },
    img2() {
      let vm = this;
      let b = vm.$refs.pic2.imgUrl;
      this.ruleForm.img2 = b;
    },
    img3() {
      let vm = this;
      let b = vm.$refs.pic4.imgUrl;
      this.ruleForm.img3 = b;
    },
    vid1() {
      let vm = this;
      let b = vm.$refs.pic3.videoUrl;
      this.ruleForm.video = b;
    },
    // 上传图片1
    uploadimgone() {
      // let vm=this;
      // let b=vm.$refs.pic.imgUrl;
      // console.log("asdad",b)
    },
    // 上传图片2
    uploadimgtwo() {
      // let vm=this;
      //   let b=vm.$refs.pic2.imgUrl;
    },
    // 上传图片3
    uploadimgthree() {
      // let vm=this;
      //   let b=vm.$refs.pic4.imgUrl;
    },
    // 上传视频
    uploadVideo() {
      // let vm=this;
      //   let b=vm.$refs.pic3.videoUrl
    },
    // 起始日期
    SelecttheStartDate() {
      let vm = this;
      let b = vm.$refs.from.historyChose;
      this.startdate = b[b.length - 1];
      this.showstarttime =
        this.startdate +
        "日" +
        this.starttimea.a +
        this.starttimea.hour +
        "时" +
        this.starttimea.minute +
        "分";
    },
    // 结束日期
    SelecttheEndDate() {
      let vm = this;
      let b = vm.$refs.from2.historyChose;
      this.enddate = b[b.length - 1];
      this.showendtime =
        this.enddate +
        "日" +
        this.endtimea.a +
        this.endtimea.hour +
        "时" +
        this.endtimea.minute +
        "分";
    },
    gettime1(value) {
      this.starttimea.a = value;
      this.showstarttime =
        this.startdate +
        "日" +
        this.starttimea.a +
        this.starttimea.hour +
        "时" +
        this.starttimea.minute +
        "分";
    },
    gettime2(value) {
      this.starttimea.hour = value;
      this.showstarttime =
        this.startdate +
        "日" +
        this.starttimea.a +
        this.starttimea.hour +
        "时" +
        this.starttimea.minute +
        "分";
    },
    gettime3(value) {
      this.starttimea.minute = value;
      this.showstarttime =
        this.startdate +
        "日" +
        this.starttimea.a +
        this.starttimea.hour +
        "时" +
        this.starttimea.minute +
        "分";
    },
    gettime4(value) {
      this.endtimea.a = value;
      this.showendtime =
        this.enddate +
        "日" +
        this.endtimea.a +
        this.endtimea.hour +
        "时" +
        this.endtimea.minute +
        "分";
    },
    gettime5(value) {
      this.endtimea.hour = value;
      this.showendtime =
        this.enddate +
        "日" +
        this.endtimea.a +
        this.endtimea.hour +
        "时" +
        this.endtimea.minute +
        "分";
    },
    gettime6(value) {
      this.endtimea.minute = value;
      this.showendtime =
        this.enddate +
        "日" +
        this.endtimea.a +
        this.endtimea.hour +
        "时" +
        this.endtimea.minute +
        "分";
    },
    emptyShow() {},
    uploadVideo(a) {},
    // 减去其它内容
    Reductionother(e) {
      if (e == 0 && this.ruleForm.information.length > 1) {
        this.ruleForm.information.splice(e, 1);
      } else if (this.ruleForm.information.length > 0 && e != 0) {
        this.ruleForm.information.splice(e, 1);
      }
    },
    // 添加其他信息
    addother() {
      let a = this.ruleForm.information.length;
      if (
        this.ruleForm.information[a - 1].name != "" &&
        this.ruleForm.information[a - 1].content != ""
      ) {
        // this.rowother++;
        this.ruleForm.information.push({
          id: a,
          name: "",
          content: "",
          leng1: 1,
          leng2: 1,
        });
      } else {
        ElMessage({
          showClose: true,
          message: "请先填写上面信息",
          type: "warning",
        });
      }
    },
    // 分类中的确认和取消
    queren1() {
      this.ruleForm.resource = this.resource;
      this.flag = true;
    },
    quxiao1() {
      this.ruleForm.resource = "";
      this.resource = "";
      this.flag = !this.flag;
    },
    // 活动地点的确认和取消
    queren2() {
      if (this.ActivitySite == "") {
        this.ruleForm.activitySite = this.zidingyididian;
        this.ActivitySite2 = "";
      } else {
        this.ruleForm.activitySite = this.ActivitySite;
      }
      this.space = true;
    },
    quxiao2() {
      this.space = true;
    },
    // 成果归属的确认和取消
    queren3() {
      this.ruleForm.attributionResults = "";
      this.guishu = true;
      this.row1 = Math.floor((this.AttributionResults.length - 1) / 3) + 1;
      this.cguo(this.AttributionResults);
      this.$store.commit("cguo", this.AttributionResults);
      for (let i = 0; i < this.AttributionResults.length; i++) {
        if (i < this.AttributionResults.length - 1 && (i + 1) % 3 != 0) {
          this.ruleForm.attributionResults += this.AttributionResults[i] + ", ";
        } else if (
          (i + 1) % 3 == 0 &&
          i + 1 != this.AttributionResults.length
        ) {
          this.ruleForm.attributionResults += this.AttributionResults[i] + "\n";
        } else {
          this.ruleForm.attributionResults += this.AttributionResults[i];
        }
      }
    },
    quxiao3() {
      this.guishu = true;
    },
    // 邀请加入确认和取消
    queren4() {
      this.ruleForm.inviteMembers = [];
      this.join = true;
      this.row2 = Math.floor((this.InviteMembers.length - 1) / 3) + 1;
      this.$store.commit("yaoqing", this.InviteMembers);
      for (let i = 0; i < this.InviteMembers.length; i++) {
        if (i < this.InviteMembers.length - 1 && (i + 1) % 3 != 0) {
          this.ruleForm.inviteMembers += this.InviteMembers[i] + ", ";
        } else if ((i + 1) % 3 == 0 && i + 1 != this.InviteMembers.length) {
          this.ruleForm.inviteMembers += this.InviteMembers[i] + "\n";
        } else {
          this.ruleForm.inviteMembers += this.InviteMembers[i];
        }
      }
    },
    quxiao4() {
      this.join = true;
    },
    pp() {
      this.flag = !this.flag;
    },
    start() {
      // this.strattime=false,
      // this.end=false,
      // this.space=false,
      // this.guishu=false,
      // this.join=false
      (this.end = false),
        (this.space = false),
        (this.guishu = false),
        (this.join = false);
      this.strattime = !this.strattime;
      this.showstarttime =
        this.startdate +
        "日" +
        this.starttimea.a +
        this.starttimea.hour +
        "时" +
        this.starttimea.minute +
        "分";
    },
    end1() {
      (this.strattime = false),
        (this.space = false),
        (this.guishu = false),
        (this.join = false);
      this.end = !this.end;
      this.showendtime =
        this.enddate +
        "日" +
        this.endtimea.a +
        this.endtimea.hour +
        "时" +
        this.endtimea.minute +
        "分";
    },
    space1() {
      // this.strattime=false,
      (this.space = !this.space), (this.guishu = true), (this.join = true);
      this.end = true;
    },
    guishu1() {
      (this.space = true), (this.guishu = !this.guishu), (this.join = true);
      this.end = true;
    },
    join1() {
      (this.space = true),
        (this.guishu = true),
        (this.join = !this.join),
        (this.end = true);
    },
  },
};
</script>

<style scoped lang="scss">
.one {
  position: absolute;
  top: -5px;
  left: -47px;
}
/deep/.fufupo3 .el-form-item__content {
  display: inline-block;
}
/deep/ .el-input__suffix {
  margin-right: 0 !important;
  width: 30px;
}
.message .el-radio {
  height: 36px;
}
/deep/ .messagelist .fufupo4 span {
  margin-right: 0 !important;
}
/deep/ .fufupo4 .el-form-item {
  margin-top: 0;
}
.fen .el-radio {
  height: 38px !important;
}
/deep/ .fufupo1 .el-radio {
  margin-right: 0 !important;
}
/deep/ .fufupo1 .el-form-item {
  margin-top: 0 !important;
  height: 100%;
}
/deep/.el-checkbox {
  --el-checkbox-checked-text-color: #000c1c;
  --el-checkbox-checked-input-border-color: #002580;
  --el-checkbox-checked-bg-color: #002580;
  --el-checkbox-input-border-color-hover: #002580;
  margin-right: 0;
  height: 38px;
  // width: 25%;
}
/deep/.el-checkbox__inner {
  // background-color: pink;
  // position: relative;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  // border: 0;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
  // transform: rotate(45deg) scaleY(1) translateX(-50%) translateY(-50%);
}
/deep/ .el-checkbox__inner::after {
  width: 6px;
  top: 3px;
  left: 3.2px;
  height: 6px;
  border-radius: 50%;
  // transform:  rotate(45deg) scaleY(1) translateX(-50%) translateY(-50%);
  background-color: #fff;
  border: 0;
}

/deep/ .el-radio {
  height: 36px;
}
/deep/ .fufupo3 .el-radio__label {
  height: 15px !important;
  line-height: 15px !important;
}
/deep/.fufupo3 span {
  margin-right: 0;
}
/deep/.fufupo3 .el-form-item {
  margin-top: 0 !important;
  width: 100%;
  height: 100%;
}
/deep/.fufupo3 .el-radio {
  width: 50% !important;
  height: 15px;
  margin-right: 0;
}
/deep/.fufupo3 .el-radio:nth-child(n + 3) {
  margin-top: 12px;
}
/deep/ .el-radio-group {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: inline-block;
}
.fufupo2 .fufu-ridiocontent {
  // overflow: inherit;
}
.fufupo2 .el-radio {
  margin-right: 0;
}
.messagelist .fufupo2 span {
  margin-right: 0 !important;
}
/deep/.res .el-radio__input {
  margin-right: 0 !important;
}
/deep/.res .el-radio__inner {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0 !important;
}
/deep/.el-radio-group::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
/deep/.res .el-radio-group {
  overflow: unset;
}
/deep/ .fufupo2 .el-form-item {
  margin-top: 0;
}
.diy {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}
.diy .diyinput {
  border: 1.5px solid #d9d9d9;
  border-radius: 9px;
}
.seach {
  position: relative;
}
.seach input {
  margin-bottom: 24px;
  padding-left: 44px;
}
.fufupo1 {
  position: absolute;
  top: 37px;
}
.fufupo2 {
  position: absolute;
  top: 37px;
}
.fufupo3 {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
.fufupo4 {
  position: absolute;
  // top: 39px;
  bottom: 0;
  transform: translateY(100%);
}
.fufu2 {
  position: relative;
  top: 53px;
  transform: translateX(-100%);
}
/deep/ .fufu .el-radio {
  width: 100%;
  height: 38px !important;
}
.title {
  height: 0;
}

/deep/ .mw-wrapper .mw-uploader .add-btn:hover {
  color: #002580 !important;
}
/deep/ .viewvideo {
  width: 192px !important;
  height: 108px !important;
}
/deep/ .mw-wrapper .mw-uploader .viewvideo {
  margin-bottom: 0 !important;
}
/* pc */
@media (min-width: 1201px) {
  /deep/ .el-date-editor.el-input,
  .el-date-editor.el-input__wrapper {
    width: 324px;
    height: 36px;
    line-height: 36px;
    z-index: 0;
  }
  .main {
    margin: 0 auto;
    width: 1366px;
    padding-top: 24px;
  }
  .rightmenu {
    width: 240px !important;
  }
  .paddingright {
    padding-left: 39.5px;
  }
  .inputban {
    width: 324px;
  }
  .inputquan {
    width: 994px;
  }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 894px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 295px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 894px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  /deep/ .fufupo3 .el-checkbox {
    width: 25%;
  }
  /deep/ .fufupo4 .el-checkbox {
    width: 50%;
  }
}
/* 平板 */
@media (max-width: 1200px) {
  .main {
    margin: 0 auto;
    width: 980px;
    padding-top: 24px;
  }
  .rightmenu {
    width: 232px;
  }
  .paddingright {
    padding-left: 20px;
  }
  /deep/ .el-date-editor.el-input,
  .el-date-editor.el-input__wrapper {
    width: 260px;
    height: 36px;
    line-height: 36px;
  }
  .inputban {
    width: 260px;
  }
  .inputquan {
    width: 636px;
  }
  .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .lowimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 539px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/low.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 232px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .long .topimg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 539px;
    width: 16px;
    height: 9px;
    background-image: url(../../assets/top.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  /deep/.mw-wrapper .mw-uploader .viewImg:nth-child(4) {
    margin-top: 28px;
  }
  /deep/.mw-wrapper .mw-uploader .add-btn:nth-child(4) {
    margin-top: 28px !important;
  }
  /deep/ .fufupo3 .el-checkbox {
    width: 50%;
  }
  /deep/ .fufupo4 .el-checkbox {
    width: 100%;
  }
}
//单选框样式
/deep/ .el-radio {
  height: 16px;
  color: #000c1c !important;
  --el-radio-input-border-color-hover: #002580 !important;
}
/deep/ .el-radio__label {
  padding-left: 12px !important;
}
/deep/ .el-radio__inner::after {
  width: 6px !important;
  height: 6px !important;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: transparent !important;
  background: #002580 !important;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #000c1c !important;
}
.long {
  display: flex;
}

// @media  screen and (min-width:1201px){
//   .inputban {
//     width: 324px;
//   }
//   .inputquan {
//     width: 994px;
//   }
//   .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 295px;
//     width: 16px;
//     height: 9px;
//     background-image: url(../../assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
//   .long .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 900px;
//     width: 16px;
//     height: 9px;
//     background-image: url(../../assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }
// }

// @media  screen and (max-width:1200px){
//   .inputban {
//     width: 260px;
//   }
//   .inputquan {
//     width: 636px;
//   }
//   .lowimg {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 232px;
//     width: 16px;
//     height: 9px;
//     background-image: url(../../assets/low.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//   }

// }
// 日历样式
/deep/ .el-input__prefix {
  display: none !important;
}
/deep/ .el-input {
  --el-input-hover-border: #d9d9d9 !important;
  --el-input-focus-bordertransparent: #d9d9d9 !important ;
  --el-input-focus-border: #d9d9d9 !important;
  --el-input-focus-border-color: #d9d9d9 !important;
  --el-input-border: 2px solid #d9d9d9 !important;
  --el-border-radius-base: 9px !important;
  --el-input-bg-color: #f2f2f2 !important;
  --el-input-border-color: #d9d9d9 !important;
  --el-input-border: 2px !important;
  --el-input-placeholder-color: #808080;
}
/deep/ .el-input__wrapper {
  box-shadow: 0 0 0 0.8px var(--el-input-border-color, var(--el-border-color))
    inset;
}
.ol {
  flex: 1;
}
.otherbo {
  display: inline;
}
.other img {
  width: 48px;
  height: 48px;
}
.otherlist {
  margin-top: 28px;
  display: flex;
  // background-color: pink;
}

.otherlist .otherbox {
  min-height: 36px;
  line-height: 36px;
  display: flex;
}

.otherlist p {
  // display: flex;
  // display: inline-block;
  height: 36px;
  line-height: 36px;
  // background-color: pink;
}
/deep/.el-form {
  --el-form-label-font-size: 16px;
}
/deep/ .el-form-item {
  margin-bottom: 0 !important;
}
/deep/.el-form-item__label {
  display: inline;
  padding: 0;
  margin-right: 24px;
  height: 100%;
  line-height: 36px !important;
  color: #000c1c;
}
//详细信息模块
/deep/ .message .el-form-item__label {
  display: inline;
  padding: 0;
  margin-right: 50px;
  height: 100%;
  line-height: 36px !important;
  color: #000c1c;
}
/deep/.el-form-item {
  margin-top: 28px;
}
/deep/.mw-wrapper {
  margin-top: 28px;
}
/deep/.mw-wrapper .mw-uploader .add-btn {
  margin: 0 !important;
}
/deep/.mw-wrapper .mw-btn {
  display: none !important;
}
/deep/.mw-wrapper .mw-uploader .viewImg {
  // margin: 0!important;
  margin-right: 24px !important;
  margin-bottom: 0 !important;
}
.pp {
  position: absolute;
  top: -5px;
  left: -20px;
}
.paddingside {
  padding: 0 24px;
  box-sizing: border-box;
}
.divfirst {
  padding-bottom: 28px;
  /* background-color: red; */
}
.divfirst h3 {
  padding-left: 24px;
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.inputlist {
  position: relative;
  // margin: 24px 0;
  // background-color: pink;
}
.inputquan {
  width: 100%;
}

/deep/.mw-wrapper {
  padding: 0 !important;
  // background-color: pink;
}
/deep/ .add-btn {
  width: 192px !important;
  height: 108px !important;
  background-color: #f2f2f2;
  border: none !important;
  border-radius: 0 !important;
  content: "" !important;
}
/deep/.mw-wrapper .mw-uploader .add-btn {
  margin: 0;
}
/deep/ .viewImg {
  width: 192px !important;
  height: 108px !important;
  background-color: #f2f2f2;
  border: none !important;
  border-radius: 0 !important;
  content: "" !important;
}
.pa {
  padding: 0 24px;
}
.fonttit {
  margin: 28px 0;
}
.fontt {
  margin-top: 12px;
}
.starttime,
.endtime,
.plece,
.guishu {
  position: relative;
  height: 36px;
  line-height: 36px;
}
.timebd {
  margin-right: 48px;
}
.guishu {
  display: flex;
}
.guishu .long {
  flex: 1;
  border-radius: 9px;
  border: 2px solid #000;
  border: 2px solid #d9d9d9;
  background-color: #f2f2f2;
}
</style>