<template>
  <div class="bigbox">
    <div class="leftbox">
        <div @click="time1(p)" :class="[p==timeindex?'ye':'']" v-for=" p in time" :key="p">{{p}}</div>
    </div>
    <div class="midbox">
        <div @click="mid(p)" :class="[p==midindex?'ye':'']" v-for=" p in hours" :key="p">{{p}}</div>
    </div>
    <div class="rightbox">
            <div @click="right(p)" :class="[p==rightindex?'ye':'']" v-for="p in minutes" :key="p">{{p}}</div>
            <!-- <li v-for="p in minutes" :key="p">{{p}}</li> -->

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      time:["上午","下午"],
      //小时存储
      hours:[],
      // 分钟存储
      minutes:[],
      midindex:-1,
      rightindex:-1,
      timeindex:-1
    }
  },
  mounted(){
      for (let i=0;i<24;i++)
      {
        this.hours.push(i);
      }
      for (let i=0;i<60;i++)
      {
        this.minutes.push(i);
      }
    },
  methods:{
    time1(e){
    
      this.timeindex=e;
      this.$emit("transfer1",this.timeindex);
    },
    mid(e) {
      this.midindex=e;
      this.$emit("transfer2",this.midindex);
    },
    right(e) {
      this.rightindex=e;
      this.$emit("transfer3",this.rightindex);
    }
  }
}
</script>

<style  scoped>
li {
  list-style: none;
}
.bigbox {
  position: relative;
  display: flex;
  width: 300px;
  height:200px;
  text-align: center;
  /* background-color: pink; */
  margin: 0 auto;
}
.leftbox,.midbox,.rightbox {
  flex: 1;
  text-align: center;
  overflow-y: auto;
}
.leftbox::-webkit-scrollbar {
  display: none;
}
.midbox::-webkit-scrollbar {
  display: none;
}
.rightbox::-webkit-scrollbar {
  display: none;
}
.leftbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: blue; */
}
.leftbox div,.midbox div,.rightbox div {
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.ye {
  background-color: yellow;
}
</style>
