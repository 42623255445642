<!-- 我的信息右下角使用记录界面 -->
<template>
    <!-- 记录一览 -->
  <div class="card bd" >
    <span class="font18 font00">记录一览</span>
    <div class="pp margintop12">
        <div class="zz">
                <ul class="font16 font80">
                    <li>{{left[3]}}</li>
                    <li>{{left[2]}}</li>
                    <li>{{left[1]}}</li>
                    <li>{{left[0]}}</li>
                    <li>0</li>
                </ul>
        </div>
        <div class="mid"  >


            <div class="contentdian">
            <!-- 柱形图 -->  
            <!-- :style="{left:70+index*96+'px'} " -->
                <div   v-for="(p,index) in PictureData" :key="index"  class="abc move"  @click="calshow(index)" >
                        <span class="font16 font80 move" :class="{'hei':calshow1[index]==true}">{{p.bf}}</span>
                        <div  class="box-mid margintop12 move" :style="{height: p.bf*(252/left[3])+'px'}"  :class="[calshow1[index]==true?'fufufu':'fu']"></div>
                        <span class="box-num font16 move"  :class="{'fufufufufufufu':calshow1[index]==true}">{{index+1}}</span>
                </div>
            </div>  
            
        </div>
    </div>
</div>
<div class="midlist bd margintop12" >
     <!-- 日历 -->
      <ul class="menu-one bd">
           <li style="min-height:auto;line-height: 64px;"  v-for="(p,index) in PictureData" :key="p.id" :class="{'fufufufufu':calshow1[index]==true}">
                <span @click="calshow(index)" class="font16 list" >
                    <p class="box-num2 font16" :class="{'fufufufufufu':calshow1[index]==true}">{{index+1}}</p>
                    {{p.title}}
                    <div class="img" :class="{'bbb':calshow1[index]==true}">
                    </div>
                </span>
                  <div class="riqi">
                      <calendar :cal="p.use_date" v-if="calshow1[index]==true" ></calendar>
                  </div>
          </li>
      </ul>

  </div>
 
  
</template>

<script >
import axios from '@/Api/axios'
import  calendar from '../../components/vue-calendar-componentabc/calendar.vue'
export default {
  components: { calendar },

    data()
    {
        return{
            PictureData:[],
         
            data:[],
            calshow1:[],
            left:[],
        }
    },
    methods:{
        calshow(e){
            for (let i=0;i<this.PictureData.length ;i++)
            {
                if (i!=e)
                this.calshow1[i]=false;
            }
                 this.calshow1[e]=!this.calshow1[e]
       
        }
               
            

    },
        mounted(){
            axios.get("PC/appointment/usecount/"+this.$cookies.get("selectValue").id).then(res=>{
                
                this.PictureData=res.data.data.list;
               
                for (let i=0;i<this.PictureData.length;i++)
                {
                    this.PictureData[i].bf=parseInt(this.PictureData[i].bf)
                    this.calshow1[i]=false;
                }
                let a=res.data.data.max/4
                this.left[0]=parseInt(a)
                this.left[1]=parseInt(a*2)
                this.left[2]=parseInt(a*3)
                this.left[3]=parseInt(a*4)
                
            })
           
            
        }
}
</script>

<style scoped>
/* pc */
@media (min-width: 1201px) {
    .contentdian {
        width: 1014.5px;
        height: 335px;
    }
    .abc {
        position: relative;
        bottom: 18px;
    }
}
/* 平板 */
@media (max-width: 1200px) {
    .contentdian {
        width: 656px;
        height: 317px;
    }
    .abc {
        position: relative;
        bottom: 0;
    }
}
.menu-one{
    overflow: hidden;
}
.menu-one li:hover {
    background-color: rgb(236, 245, 255);
    transition: .3s;

}
.riqi {
    background-color: rgb(247, 247, 247);
}
.card {
    width: 100%;
    height: 394px;
    padding: 24px;
    box-sizing: border-box;
}
.pp {
    width: 100%;
    display: flex;
    flex-direction:row;
}
.zz {
    padding-top: 5px;
    width: 24px;
    /* background-color: red; */
}
.zz ul {
    width: 24px;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
}
.mid {
    flex: 1;
    position: relative;
    height: 280px;
    background-image: url(../../assets/dian.png);

    /* background-color: pink; */
}
.contentdian {
    /* height: 317px; */
    /* overflow-x:auto ; */
    /* background-color: rgba(0, 0, 0, .5); */
    overflow-x: auto;
    /* overflow: hidden; */
    display: flex;
    align-self: flex-end;
}
.box-num {
    margin-top: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #e6e6e6;
    color: #fff;
    border-radius: 12px;
    z-index: 3;
}
.box-num2 {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #e6e6e6;
    color: white;
    display: inline-block;
    line-height: 24px;
    text-align: center;
    margin-right: 24px;
    margin-left: 24px;
}
.box-mid {
    background-color: #e6e6e6;
    width: 48px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.hei {
    color: #000c1c;
}
.fufufufufufufu{
    background: #002580 !important;
    transition: .3s;
}
.fufufufufufu{
    background-color: white !important;
    color: black !important;
    transition: .3s;
}
.fufufufufu{
    background-color: #002580!important;
    color: white;
    transition: .3s;
    
}
.fu{
    background-color: #e0e0e0;
}
.fufufu{
    background-color: #fed71a;
    transition: .3s;
}
/* .abc{
    position: absolute;
    bottom: -36px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
} */
.abc{
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: flex-end;
    align-items: center;
    /* border-color: pink; */
    margin-left: 48px;

}
li {
    list-style: none;
}
.diandian {
    /* background-color: pink; */
    margin-left: 80px;
    display: flex;

}
.diandian ul {
margin-right: 20px;
}
.diandian li{
    width:6px;
    height:6px;
    border-radius: 3px;
    margin-top: 14px;
    background-color: #e6e6e6;
}
/* --------------------------------- */
.midlist {

}
.midlist li {
    /* background-color: pink; */
}
.list {
    /* position: relative; */
    /* background-color: pink; */
    position: relative;
    display: block;
}
.list .img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 36px;
    width: 20px;
    height: 11px;
    /* background-color: pink; */
    background-image: url(../../assets/low.png);
    background-position: center;
    background-repeat: no-repeat;

}

.bbb {
    background-image: url(../../assets/whitetop.png)!important;
}

.firstMenu .menu-one .menu {
    /* position: relative; */
    height: 100%;
    display: block;
    list-style: none;
    width: 100%;
    text-align:left; 

    color: #808080;
    
}




.yellowone {
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 6px;
        border-radius: 3px ;
        background-color: #fed71a;
        font-weight: 700;
    }
.bgblue {
    background-color: #002580!important;
    color: #fff;
}

.fufu {
    width: 100%;
    display: block !important;
    background-color: #f7f7f7;
    /* background-color:pink; */
}

.tit {
    position: relative;
    margin-left: 40px;
    display: block;
    height: 50px;
    line-height: 50px;
    /* color: #002580; */
    /* background-color: pink; */
}
.titcolor {
    font-weight: 700;
    color: #002580!important;
}
</style>