<template>
  <div class="mw-wrapper">
    <span class="mw-btn" @click="uploadPhoto">
    </span>
    <div class="mw-uploader">
      <div
        class="viewvideo"
        :style="'width:' + width + 'px;height:' + height + 'px'"
        v-for="(item, index) in via"
        :key="index"
      >
      <!--     v-show="item.showvideo"
          :title="item.name" -->
        <video
          controls="controls" 
          :src="item.base64"
          ref="video"
          style="width: 100%"
      
          alt=""
        />
        <div class="z-close" @click="delPhoto(index)"><img src="../../assets/imgdel.png" alt=""></div>
      </div>
      <div
        class="add-btn"
        v-if="via.length < limit"
        :style="'width:' + width + 'px;height:' + height + 'px'"
        @click="addPhoto"
      >
        +
      </div>
      <input
        ref="inputFile"
        type="file"
        class="input-file"
        :accept="accept ? accept : 'video/*'"
        @change="selectPhoto"
      />
      <!-- :accept="accept ? accept : 'image/*'" -->
    </div>
  </div>
</template>

<script>
export default {
  name: "videoUploader",
  components: {},
  props: {
    //图片宽度
    width: {
      type: Number,
      default: () => 200,
    },
    //图片高度
    height: {
      type: Number,
      default: () => 200,
    },
    limit: {
      type: Number,
      default: () => 9999,
    },
    accept: {
      type: String,
      default: () => "",
    },
    via:{
      // type:[],
      default:()=>""
    }
  },
  data() {
    return {
      videoUrl: [],
    };
  },
  methods: {
    uploadPhoto() {
      if (this.videoUrl.length) {
        this.$emit("upload", this.videoUrl);
      } else {
        this.$emit("empty");
      }
    },
    selectPhoto(e) {
      this.videoUrl=this.$props.via;
   
      const obj = e.target.files[0];
      if (obj==undefined)
      return
      const reader = new FileReader();
      reader.onload = (evt) => {
    
        this.videoUrl.push({
          base64: evt.target.result,
          file: obj,
          showvideo: false,
        });

        // 创建image对象
        const sp = document.createElement('video');
        sp.src = evt.target.result;
        //如果宽度小于容器宽度，按照图片本身宽度显示
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.$refs.video[this.$refs.video.length - 1].style.width < this.width) {
              this.$refs.video[this.$refs.video.length - 1].style.width = this.$refs.video[this.$refs.video.length - 1].style.width + "px";
            }
          });
    
         this.videoUrl[this.videoUrl.length - 1].showvideo= true,
         300});
      };
      
       reader.readAsDataURL(obj);
    },
    addPhoto() {
  
      this.$refs.inputFile.click();
    },
    delPhoto(index) {
      this.videoUrl=this.$props.via;
      this.videoUrl.splice(index, 1);

      this.$refs.inputFile.value = "";
    },
  },
};
</script>
<style lang="less" scoped>
.mw-wrapper {
  text-align: left;
  padding: 0 10px;
  .mw-btn {
    display: inline-block;
    margin-bottom: 12px;
  }
  .mw-uploader {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .viewvideo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #f7f0f0;
      box-sizing: border-box;
      margin-right: 12px;
      margin-bottom: 12px;
      transition: all 0.4s;
      border-radius: 6px;
      background-color: #fff;
      overflow: hidden;
      .z-close {
        display: none;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 4;
        font-size: 26px;
        color: #ff0909;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      &:hover {
        .z-close {
          display: flex;
        }
      }
    }
    .add-btn {
      position: relative;
      overflow: hidden;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #d9d9d9;
      margin-right: 12px;
      margin-bottom: 12px;
      &:hover {
        border-color: #409eff;
        color: #409eff;
      }
    }
    .input-file {
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;
    }
  }
}
</style>
